import React, { useState, useEffect } from 'react';
import { getFirestore, doc,getDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage'; // Ensure getDownloadURL is imported here
import { auth } from '../firebaseConfig';
import EditProfileModal from './EditProfileModal';
import './Base.css';

interface ProfileProps {
  userUID: string;
  onClose: () => void;
}

const PublicProfile: React.FC<ProfileProps> = ({ userUID, onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [bio, setBio] = useState(''); // Initialize bio state
  const [filesCount, setFilesCount] = useState(0); // Initialize filesCount state

  // Disable scrolling when the component is mounted
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', userUID);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setFirstName(userData?.firstName || '');
        setLastName(userData?.lastName || '');
        setBio(userData?.bio || 'No bio yet.');
        setUserImage(userData?.imageUrl || '/path-to-user-image.png');
      }
    };

    const fetchFilesCount = async () => {
      const storage = getStorage();
      const listRef = ref(storage, 'uploads/');
      const res = await listAll(listRef);

      const userFiles = res.items.filter((item) => item.name.includes(userUID));
      setFilesCount(userFiles.length);
    };

    fetchUserProfile();
    fetchFilesCount();
  }, [userUID]);

  useEffect(() => {
    const fetchFilesCount = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      try {
        const storage = getStorage();
        const listRef = ref(storage, `uploads/`);
        const res = await listAll(listRef);

        // Filter files that belong to the current user
        const userFiles = res.items.filter((item) =>
          item.name.includes(currentUser.uid)
        );

        setFilesCount(userFiles.length); // Set filesCount to the number of user's files
      } catch (error) {
        console.error('Error fetching files count:', error);
      }
    };

    fetchFilesCount();
  }, []);

  return (
    <div className="profile-container">
      <div className="profile-header">
         <div className="profile-image-container"  >
          <img
            src={userImage}
            alt={`${firstName} ${lastName}`}
            className="profile-image"
          />
        </div>
        <div style={{ color: 'white' }}>
          <h1>{firstName} {lastName}</h1>
          <p >{bio}</p>
        </div>
      </div>
        {/* Stats */}
        <div className="profile-stats">
          <div>
            <strong>{filesCount}</strong> Files in Notes
          </div>

          <div>
            <strong>{filesCount}</strong> Q&A in Library
          </div>

        </div>
      <button className="profile-close-button" onClick={onClose}>Close</button>
    </div>
  );
};

export default PublicProfile;