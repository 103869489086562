import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Library from './components/Library';
import Profile from './components/Profile';
import UploadInfo from './components/UploadInfo';
import Summaries from './components/Summaries';
import Home from './components/Home';
import Support from './components/Support';
import CoffeeBreak from './components/CoffeeBreak';
import Billing from './components/Billing';
import BillingAccount from './components/BillingAccount';
import BillingCancel from './components/BillingCancel';
import Plans from './components/Plans';
import MyPlan from './components/MyPlan';
import Chat from './components/Chat';
import LoginSelect from './components/LoginSelect';
import USHistory from './components/USHistory';
import Settings from './components/Settings';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Auth from './components/Auth';  // Your login component


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/home" element={<Home />} />
        <Route path="/library" element={<Library />} />  
        <Route path="/support" element={<Support />} />   
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />    
        <Route path="/terms" element={<Terms />} />                                
        <Route path="/profile" element={<Profile />} />
        <Route path="/coffee-break" element={<CoffeeBreak />} />    
        <Route path="/billing" element={<Billing />} />    
        <Route path="/billing-account" element={<BillingAccount />} /> 
        <Route path="/billing-cancel" element={<BillingCancel />} />           
        <Route path="/my-plan" element={<MyPlan />} />    
        <Route path="/plans" element={<Plans />} />   
        <Route path="/chat" element={<Chat />} />           
        <Route path="/login-select" element={<LoginSelect />} />      
        <Route path="/us-history" element={<USHistory />} />                            
        <Route path="/settings" element={<Settings />} />                                                           
        <Route path="/uploadInfo" element={<UploadInfo />} />               
        <Route path="/summaries" element={<Summaries />} />                            
      </Routes>
    </Router>
  );
};

export default App;
