import React, { useState, useEffect, useRef, useCallback } from 'react';
import { auth, storage } from '../firebaseConfig';
import { ref, uploadBytes, listAll, getDownloadURL, getMetadata} from 'firebase/storage';
import './Base.css';
import { onAuthStateChanged} from 'firebase/auth';
import ReactPlayer from 'react-player/youtube';
import { gapi } from 'gapi-script';
import { FaPlus} from 'react-icons/fa'; 

const Videos: React.FC = () => {
  const [userUID, setUserUID] = useState<string>('');
  const [isAnonymous, setIsAnonymous] = useState<boolean>(true);
  const isProfileFetched = useRef<boolean>(false); // Using ref instead of state
  const [youtubeUrl, setYoutubeUrl] = useState<string>('');
  const [videoFiles, setVideoFiles] = useState<any[]>([]);
  const [playingVideoUrl, setPlayingVideoUrl] = useState<string | null>(null);
  const playerRef = useRef<ReactPlayer | null>(null);

  // Reference for the chat messages container
  const captionEndRef = useRef<HTMLDivElement | null>(null);

  // Scroll to the bottom of the chat container
  const scrollToBottom = () => {
    if (captionEndRef.current) {
      captionEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Automatically scroll to the bottom when chat updates
    scrollToBottom();
  }, []);

  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);


  const initClient = () => {
    gapi.client.init({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
    });
  };
  
  useEffect(() => {
    gapi.load('client:auth2', initClient);
  }, []);
  
  const fetchVideos = useCallback(async () => {
    if (!userUID) {
      console.warn('No user UID found; unable to fetch videos.');
      return;
    }
  
    try {
      const storageRef = ref(storage, 'videos/');
      const result = await listAll(storageRef);
  
      if (result.items.length === 0) {
        console.log('No video files found in Firebase Storage.');
        setVideoFiles([]);
        return;
      }
  
      const videoFiles = await Promise.all(result.items.map(async (fileRef) => {
        try {
          const firebaseUrl = await getDownloadURL(fileRef);
  
          // Fetch the actual YouTube URL stored in the file
          const response = await fetch(firebaseUrl);
          const youtubeUrl = await response.text();
  
          // Fetch the metadata, including the custom metadata
          const metadata = await getMetadata(fileRef);
          const timeCreated = metadata.timeCreated;
          const youtubeTitle = metadata.customMetadata?.YoutubeTitle || 'Unknown Title';
          const url = await getDownloadURL(fileRef);
          return {
            name: youtubeTitle,     // Use YoutubeTitle from custom metadata
            youtubeUrl,             // Store the actual YouTube URL
            timeCreated,
            url,
            refPath: fileRef.fullPath,
          };
        } catch (error) {
          console.error(`Error fetching URL or metadata for ${fileRef.name}:`, error);
          return null;
        }
      }));
  
      setVideoFiles(videoFiles.filter(file => file !== null));
    } catch (error) {
      console.error('Error fetching video files from Firebase Storage:', error);
    }
  }, [userUID]);
  

  // Fetch user profile and subscription status
  useEffect(() => {
    const fetchUserProfile = async (uid: string) => {
      isProfileFetched.current = true;
    };

    const currentUser = auth.currentUser;
    if (currentUser) {
      setUserUID(currentUser.uid);
      setIsAnonymous(currentUser.isAnonymous);

      if (!currentUser.isAnonymous && !isProfileFetched.current) {
        fetchUserProfile(currentUser.uid);
      } else {
      }

      fetchVideos();
    }
  }, [fetchVideos]);


// Save YouTube URL to Firebase and fetch video title
const handleSaveVideoUrl = async () => {
  if (!youtubeUrl || !ReactPlayer.canPlay(youtubeUrl)) {
    alert('Please enter a valid YouTube URL.');
    return;
  }

  try {
    // Fetch the video title from the custom yt-dlp endpoint
    const response = await fetch(`/api/youtube-title?url=${encodeURIComponent(youtubeUrl)}`);
    const data = await response.json();

    if (data.error) {
      throw new Error(data.error);
    }

    const videoTitle = data.title;
    const videoId = youtubeUrl.split('v=')[1];
    const storageRef = ref(storage, `videos/${userUID}_${videoId}.url`);

    const metadata = {
      customMetadata: {
        ShareWithPublic: 'Yes',
        UserID: userUID,
        DocumentType: 'YouTube Video',
        YoutubeTitle: videoTitle, // Add video title to metadata
      },
    };

    // Set the URL to play immediately
    setPlayingVideoUrl(youtubeUrl);

    await uploadBytes(storageRef, new Blob([youtubeUrl], { type: 'text/plain' }), metadata);
    fetchVideos();
    setYoutubeUrl(''); // Clear the input after saving

    console.log('Video saved successfully with title:', videoTitle);
  } catch (error) {
    console.error('Error fetching YouTube video title or uploading:', error);
    alert('Failed to save video. Please try again.');
  }
};

  //Additional functions to handle reload the page
  const fetchUserProfile = async (uid: string) => {
    isProfileFetched.current = true;
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserUID(currentUser.uid);
        setIsAnonymous(currentUser.isAnonymous);
  
        if (!currentUser.isAnonymous) {
          fetchUserProfile(currentUser.uid);
        } else {
        }
  
        // Fetch recordings only when userUID is set
        if (currentUser.uid) {
          fetchVideos();
        }
      } else {
        console.warn("User is not authenticated.");
        setUserUID("");
        setIsAnonymous(true);
      }
    });
  
    return () => unsubscribe();
  }, [fetchVideos]);


  return (
          <div className="upload-content-inner">
            {/* YouTube Video Player */}
            <div className="video-player-container">
              
            <div className="video-url-input-container">
              <input
                type="text"
                placeholder="Enter YouTube URL"
                value={youtubeUrl}
                onChange={(e) => setYoutubeUrl(e.target.value)}
                className="youtube-url-input"
              />
              <button onClick={handleSaveVideoUrl} className="save-video-button">
                <FaPlus /> Add Video
              </button>
            </div>
                <ReactPlayer
                  ref={playerRef}
                  url={playingVideoUrl || undefined} // Use undefined instead of null
                  playing={Boolean(playingVideoUrl)}
                  controls
                  className="react-player"
                />
               </div>

          </div>          
  );
};

export default Videos;