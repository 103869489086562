import React from 'react';

interface CustomConfirmModalProps {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const CustomConfirmModal: React.FC<CustomConfirmModalProps> = ({
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content qwindow-modal">
        <label className="qwindow-label"  style={{ marginBottom: '20px' }} >
          {message}
        </label>

        <button 
          onClick={onCancel} 
          style={{ marginRight: '20px' }} 
        >
          Keep the File Private
        </button>
        <button onClick={onConfirm}>
          Share with Public
        </button>
      </div>
    </div>
  );
};

export default CustomConfirmModal;
