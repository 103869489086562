import React, { useState, useCallback,useEffect,useRef} from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { getDownloadURL, ref,getMetadata, listAll,uploadString, uploadBytes,updateMetadata} from 'firebase/storage';
import { auth, storage } from '../firebaseConfig';
import { FaCommentDots, FaPaperPlane,FaCopy, FaCheck, FaHandPaper, FaExpand, FaVolumeUp, FaClipboardCheck, FaClipboard,FaBackward,FaPlay,FaForward,FaPause,FaEyeSlash, FaEye,FaEraser} from 'react-icons/fa';
import { getFirestore, doc, getDoc } from 'firebase/firestore'; 
import { EventEmitter } from 'events';
import ReactPlayer from 'react-player/youtube';
import { PDFDocument, rgb} from 'pdf-lib';
import * as fontkit from 'fontkit'; 
import CustomAudioMotionAnalyzer from './AudioMotionAnalyzer'; // Adjust import path if necessary
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import Qna from './Qna'; 
import OpenAI from "openai";


const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
});

interface ChatMessage {
    message: string;
    from: 'user' | 'assistant';
    type?: 'text' | 'image'; 
}

interface Assistant {
    name: string;
    id: string;
    chat: ChatMessage[];
    fileID: string;
    threadID: string; 
    vectorStore:string; 
}


interface FileData {
  name: string;
  userId: string;
  url: string;
  youtubeUrl: string;
  thumbnailUrl:string;
  refPath: string;
  createdAt: Date; 
  viewCount: number;
  documentType: string; 
  shareWithPublic: string; 
  userImageUrl: string; 
  qnaUrl:string;
  summaryUrl:string;   
  transcriptUrl:string;  
}


interface PdfViewerModalProps {
  isOpen: boolean;
  pdfUrl: string | null;
  fileName: string;  
  qnaStatus: Record<string, boolean>; 
  summaryStatus: Record<string, boolean>; 
  setSummaryStatus: React.Dispatch<React.SetStateAction<Record<string, boolean>>>; 
  onClose: () => void;
  onGenerateQuestions: (fileUrl: string) => void; 
  onGenerateSummaries: (fileUrl: string) => void; 
  currentFile: FileData | null;
  setCurrentFile: React.Dispatch<React.SetStateAction<FileData | null>>; 
  transcriptUrl: string;
  setTranscriptUrl: React.Dispatch<React.SetStateAction<string>>; 
  showPublic: boolean;
}

interface Event {
    event: string;
    data: any; // Adjust 'any' to a more specific type if you know the structure of 'data'
  }

  interface ToolCall {
    id: string;
    function: {
      name: string;
    };
  }
  
  interface RequiresActionData {
    required_action: {
      submit_tool_outputs: {
        tool_calls: ToolCall[];
      };
    };
  }

  interface LibraryItemType {
    question: string;
    options?: { [key: string]: string };
    answer: string;
  }
    
class EventHandler extends EventEmitter {
    client: OpenAI;
    constructor(client: OpenAI) {
      super();
      this.client = client;
    }
  
    async onEvent(event: Event) {
      try {
        console.log(event);
        // Retrieve events that are denoted with 'requires_action'
        // since these will have our tool_calls
        if (event.event === "thread.run.requires_action") {
          await this.handleRequiresAction(
            event.data as RequiresActionData,
            event.data.id,
            event.data.thread_id,
          );
        }
      } catch (error) {
        console.error("Error handling event:", error);
      }
    }
  
    async handleRequiresAction(data: RequiresActionData, runId: string, threadId: string) {
      try {
        const toolOutputs = data.required_action.submit_tool_outputs.tool_calls.map((toolCall) => {
          if (toolCall.function.name === "getCurrentTemperature") {
            return {
              tool_call_id: toolCall.id,
              output: "57",
            };
          } else if (toolCall.function.name === "getRainProbability") {
            return {
              tool_call_id: toolCall.id,
              output: "0.06",
            };
          }
          return null; // Add a default return value
        }).filter(Boolean); // Filter out any null values
    
        // Submit all the tool outputs at the same time
        await this.submitToolOutputs(toolOutputs, runId, threadId);
      } catch (error) {
        console.error("Error processing required action:", error);
      }
    }
    
    async submitToolOutputs(toolOutputs: any[], runId: string, threadId: string) {
      try {
        // Use the submitToolOutputsStream helper
        const stream = this.client.beta.threads.runs.submitToolOutputsStream(
          threadId,
          runId,
          { tool_outputs: toolOutputs },
        );
        for await (const event of stream) {
          this.emit("event", event);
        }
      } catch (error) {
        console.error("Error submitting tool outputs:", error);
      }
    }
  }

const PdfViewerModal: React.FC<PdfViewerModalProps> = ({ 
  isOpen, 
  pdfUrl,
  fileName,
  qnaStatus,
  summaryStatus,
  setSummaryStatus,
  onClose,
  onGenerateQuestions,
  onGenerateSummaries,
  currentFile,
  setCurrentFile,
  transcriptUrl,
  setTranscriptUrl,
  showPublic,
  }) => {

  const toolbarPluginInstance = toolbarPlugin();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [leaving, setLeaving] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isQnaOpen, setIsQnaOpen] = useState(false);  
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);    
  const [newMessage, setNewMessage] = useState('');
  const [assistant, setAssistant] = useState<Assistant | null>(null);
  const [isAssistantLoading, setIsAssistantLoading] = useState(false); // Track assistant loading
  const [isSendDisabled, setIsSendDisabled] = useState(true); // Disable send button until assistant is ready
  const [selectedText, setSelectedText] = useState<string | null>(null);
  const [selectionPosition, setSelectionPosition] = useState<{ top: number, left: number } | null>(null);
  const [copiedKey, setCopiedKey] = useState<number | null>(null); // Track which code snippet is copied
  const [showPreloadedMessage, setShowPreloadedMessage] = useState(true);  
  const [selectionWithinPDF, setSelectionWithinPDF] = useState(false); 
  const [plan, setPlan] = useState<string | null>(null); // State to track user plan
  const user = auth.currentUser;
  const [isPanning, setIsPanning] = useState(false); // Track panning mode
  const [currentZoom, setCurrentZoom] = useState(1.0); // Track current zoom level
  const mainPdfViewerRef = useRef<HTMLDivElement | null>(null);
  const summaryPdfViewerRef = useRef<HTMLDivElement | null>(null);
  const dummyDivRef = useRef<HTMLDivElement | null>(null); // Reference for the dummy div
  const [isPlaying, setIsPlaying] = useState(false); // Track if audio is playing
  const [firstName, setFirstName] = useState<string | null>(null); 
  const [copiedResponseIndex, setCopiedResponseIndex] = useState<number | null>(null); // Track copied response index
  const [isAssistantThinking, setIsAssistantThinking] = useState(false); 
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // Track hover state
  const [audioInstance, setAudioInstance] = useState<SpeechSynthesisUtterance | null>(null); 
  const [pdfViewerWidth, setPdfViewerWidth] = useState(60); // Initial width in percentage
  const resizingRef = useRef(false);
  const [activeViewer, setActiveViewer] = useState<'main' | 'summary'>('main');
  const mainZoomPlugin = zoomPlugin();
  const summaryZoomPlugin = zoomPlugin();
  const { ZoomInButton: MainZoomInButton, ZoomOutButton: MainZoomOutButton} = mainZoomPlugin;
  const { ZoomInButton: SummaryZoomInButton, ZoomOutButton: SummaryZoomOutButton } = summaryZoomPlugin;
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1.0);
  const [metadataDuration, setMetadataDuration] = useState<number | null>(null); 
  const [playingAudio, setPlayingAudio] = useState<string | null>(null);
  const [audioProgress, setAudioProgress] = useState<number>(0); // Audio progress state
  const playerRef = useRef<ReactPlayer | null>(null);
  const [libraryItems, setLibraryItems] = useState<LibraryItemType[]>([]);
  const [showAnswerIndexes, setShowAnswerIndexes] = useState<number[]>([]);
  const [selectedSubject] = useState<string | null>(null);
  const [textareaContent, setTextareaContent] = useState<string>('');
  const [isConverting, setIsConverting] = useState<boolean>(false); 
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [userUID, setUserUID] = useState<string>('');
  const [showTranscript, setShowTranscript] = useState<boolean>(false);
  const [showTextArea, setShowTextArea] = useState<boolean>(true);
  const audioMotionAnalyzerRef = useRef<AudioMotionAnalyzer | null>(null);
  const [showSimpleAudioPlayer, setShowSimpleAudioPlayer] = useState<boolean>(true);
  const [showMotionAudioPlayer, setShowMotionAudioPlayer] = useState<boolean>(false);
  const hasProcessedFileRef = useRef<string | null>(null);
  const isTranscribingRef = useRef(false); 

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const uid = currentUser.uid;
      setUserUID(uid);
      setIsAnonymous(currentUser.isAnonymous);
      console.log('isanonymous',isAnonymous)
    }
  }, [isAnonymous]);
  

  useEffect(() => {
    // Scroll the dummy div into view when chat content changes
    if (dummyDivRef.current) {
      dummyDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [assistant?.chat]); 


  const handleCopy = (key: number) => {
    setCopiedKey(key);
    setTimeout(() => {
      setCopiedKey(null); // Reset after 2 seconds
    }, 2000);
  };
  
// Set the initial scale after the document has been loaded
const handleDocumentLoad = () => {
  // Check if the main or summary viewer is active and set the zoom accordingly
  if (activeViewer === 'main') {
    mainZoomPlugin.zoomTo(1.0); // Set zoom for main viewer
  } else if (activeViewer === 'summary') {
    summaryZoomPlugin.zoomTo(1.0); // Set zoom for summary viewer
  }
};

  const checkTextSelection = useCallback(() => {
    const selection = window.getSelection();

    if (selection && selection.toString().trim() !== '') {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      // Get the element where the text was selected
      const parentElement = range.commonAncestorContainer as HTMLElement;
      const pdfViewerElement = document.querySelector('.pdf-viewer-container');

      // Check if the selection is within the PDF viewer container
      if (pdfViewerElement && pdfViewerElement.contains(parentElement)) {
        setSelectedText(selection.toString());
        setSelectionPosition({
          top: rect.top + window.scrollY - 40, // Adjust for display action buttons
          left: rect.left + window.scrollX,
        });
        setSelectionWithinPDF(true); // Selection is within the PDF viewer
      } else {
        // If selection is outside the PDF viewer, reset selection states
        setSelectedText(null);
        setSelectionPosition(null);
        setSelectionWithinPDF(false);
      }
    } else {
      // Reset selection states if no text is selected
      setSelectedText(null);
      setSelectionPosition(null);
      setSelectionWithinPDF(false);
    }
  }, []);


  useEffect(() => {
    const fetchUserPlan = async () => {
      if (user && !user.isAnonymous) {
        const userPlan = await getUserPlan();
        setPlan(userPlan);
      }
    };

    fetchUserPlan();
  }, [user]);

  const getUserPlan = async (): Promise<string> => {
    const currentUser = auth.currentUser;
    if (!currentUser) return 'Starter'; 
  
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);
    const userDoc = await getDoc(userDocRef);
  
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const subscriptionStatus = userData?.subscriptionStatus;
      const chatServiceOrdered = userData?.chatServiceOrdered;
      setFirstName(userData?.firstName || null);

      if (subscriptionStatus === true && chatServiceOrdered !== true) {
        return 'Premium';
      }
      if (subscriptionStatus !== true && chatServiceOrdered === true) {
        return 'Chat';
      }
      if (subscriptionStatus === true && chatServiceOrdered === true) {
        return 'PremiumChat';
      }
    }
    return 'Starter';
  };

  useEffect(() => {
    const interval = setInterval(checkTextSelection, 1000); // Check selection every second
    return () => clearInterval(interval);
  }, [checkTextSelection]);

  const handlePanClick = () => {
    setIsPanning((prev) => !prev);
  };

  const getScrollablePdfContainer = (container: HTMLElement): HTMLElement | null => {
    // Traverse child nodes to find a scrollable container
    for (const child of Array.from(container.children)) {
        const element = child as HTMLElement;
        const style = window.getComputedStyle(element);
        
        // Check for overflow properties indicating scrollability
        if (style.overflow === 'auto' || style.overflow === 'scroll') {
            return element;
        }
        
        // Recursively check deeper levels for a scrollable element
        const scrollableChild = getScrollablePdfContainer(element);
        if (scrollableChild) return scrollableChild;
    }
    return null;
};

const handleMouseDown = (e: React.MouseEvent) => {
  if (isPanning && e.button === 0) {
      const pdfViewerContainer = activeViewer === 'main' ? mainPdfViewerRef.current : summaryPdfViewerRef.current;

      if (!pdfViewerContainer) {
          console.error('PDF viewer container not found.');
          return;
      }

      // Using helper function to find scrollable content inside the viewer container
      const scrollableContent = getScrollablePdfContainer(pdfViewerContainer);
      
      if (!scrollableContent) {
          console.error("Scrollable PDF content not found.");
          return;
      }

      // Add the no-text-select class to disable text selection while panning
      pdfViewerContainer.classList.add('no-text-select');

      const startX = e.clientX;
      const startY = e.clientY;
      const initialScrollLeft = scrollableContent.scrollLeft;
      const initialScrollTop = scrollableContent.scrollTop;

      const handleMouseMove = (moveEvent: MouseEvent) => {
          const deltaX = moveEvent.clientX - startX;
          const deltaY = moveEvent.clientY - startY;
          scrollableContent.scrollLeft = initialScrollLeft - deltaX;
          scrollableContent.scrollTop = initialScrollTop - deltaY;
      };

      const handleMouseUp = () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);

          // Remove the no-text-select class after panning ends
          pdfViewerContainer.classList.remove('no-text-select');
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
  }
};

  useEffect(() => {
    const handleWheelZoom = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        const scaleFactor = e.deltaY < 0 ? 0.1 : -0.1;
        const newZoom = Math.max(0.5, Math.min(2.0, currentZoom + scaleFactor));
  
        if (activeViewer === 'main') {
          mainZoomPlugin.zoomTo(newZoom);
        } else if (activeViewer === 'summary') {
          summaryZoomPlugin.zoomTo(newZoom);
        }
  
        setCurrentZoom(newZoom);
      }
    };
  
    window.addEventListener('wheel', handleWheelZoom, { passive: false });
    return () => window.removeEventListener('wheel', handleWheelZoom);
  }, [mainZoomPlugin, summaryZoomPlugin, currentZoom, activeViewer]);
  
const handleFullPageClick = () => {
  if (activeViewer === 'main') {
    mainZoomPlugin.zoomTo(1.0); // Set zoom for main viewer
  } else if (activeViewer === 'summary') {
    summaryZoomPlugin.zoomTo(1.0); // Set zoom for summary viewer
  }
};

const handleAction = (action: 'question' | 'explain' | 'summary') => {
    if (!selectedText) return;

    let message: string;
    switch (action) {
      case 'question':
        // Set the text in the prompt input container for user to modify
        setNewMessage(`"${selectedText}" For the text above, `);
        setShowPreloadedMessage(false); 
        break;
      case 'explain':
        message = `Can you explain the following text: "${selectedText}"?`;
        sendMessage(message); // Send the message directly to chat
        break;
      case 'summary':
        message = `Can you summarize the following text: "${selectedText}"?`;
        sendMessage(message); // Send the message directly to chat
        break;
      default:
        return;
    }
  };

  const sendMessage = async (message: string) => {
    if (!assistant || !assistant.threadID) {
      console.error('Cannot send message: Assistant or threadID is not available.');
      return;
    }

    const updatedChat: ChatMessage[] = assistant ? [...assistant.chat, { message, from: 'user', type: 'text' }] : [];

    setAssistant((prev) => (prev ? { ...prev, chat: updatedChat } : null));
    setLoading(true);
    setShowPreloadedMessage(false); 
    try {
      const content = [{ type: 'text' as const, text: message }];
      await openai.beta.threads.messages.create(assistant!.threadID, { role: 'user', content });
      await processAssistantResponse(updatedChat);
    } catch (error) {
      console.error('Error sending message:', error);
    }

    setNewMessage(''); // Clear the input field after sending
  };

  const handleQnaButtonClick = async () => {
    if (!pdfUrl) {
      console.error("No PDF URL available.");
      return;
    }

    //console.log('currentFile qna url',currentFile?.qnaUrl)
    //console.log('currentFile url',pdfUrl)

    setShowPreloadedMessage(true); 
    setIsQnaOpen((prev) => !prev);
  
  };
  

  const handleSummaryButtonClick = async () => {
    if (!pdfUrl) {
      console.error("No PDF URL available.");
      return;
    }
    setShowPreloadedMessage(true); 
    setIsSummaryOpen((prev) => !prev);
  };
  
  const handleChatButtonClick = async () => {
    if (!pdfUrl) {
      console.error("No PDF URL available.");
      return;
    }
    setShowPreloadedMessage(true); 
    setIsChatOpen((prev) => !prev);
  
    if (!assistant) {
      if(currentFile?.documentType === 'Note'){
        await createTemporaryAssistant(pdfUrl);  
      } else{
        await createTemporaryAssistant(transcriptUrl);  
      }
    }
  };
  
// Function to create a temporary assistant
const createTemporaryAssistant = useCallback(async (pdfUrl: string) => {
    const assistantInstructions = 'You are an assistant to help people unserstands uploaded documents. You should respond to questions based on the document you have.';
    setIsAssistantLoading(true);  // Start assistant loading
    setIsSendDisabled(true);  // Disable send button while loading assistant
    try {
      const assistantResponse = await openai.beta.assistants.create({
        name: 'PDF Assistant',
        instructions: assistantInstructions,
        model: "gpt-4o-mini",
        tools: [{ type: "file_search" }],
        metadata: { username: 'your-username' },
      });
  
      const assistantID = assistantResponse.id;  
      console.log('assistant ID', assistantID);
  
      const threadResponse = await openai.beta.threads.create();
      const threadID = threadResponse.id;
      console.log('thread ID', threadID);
  
      // Upload PDF using pdfUrl
      console.log('pdfURL',pdfUrl)
      const fileResponse = await fetch(pdfUrl);
      const fileBlob = await fileResponse.blob();

      const fileUploadResponse = await openai.files.create({
        file: new File([fileBlob], "document.pdf", { type: fileBlob.type }),  // Create file from the URL
        purpose: "assistants",
      });

      console.log('fileUploadResponse', fileUploadResponse);
  
      const fileID = fileUploadResponse.id;
  
      const vectorStoreResponse = await openai.beta.vectorStores.create({
        name: "Temporary Vector Store",
      });
  
      const vectorStoreID = vectorStoreResponse.id;
  
      console.log('vectorstore ID', vectorStoreID);
      const myVectorStoreFile = await openai.beta.vectorStores.files.create(vectorStoreID, {
        file_id: fileID,
      });
      console.log('my vector store file', myVectorStoreFile);

  
  
      await openai.beta.assistants.update(assistantID, {
        tool_resources: { file_search: { vector_store_ids: [vectorStoreID] } },
      });
  
      setAssistant({
        name: 'Temporary Assistant',
        id: assistantID,  // Store the real assistant ID
        chat: [],
        fileID: fileID,
        threadID: threadID,
        vectorStore: vectorStoreID,
      });
  
      setIsSendDisabled(false);  // Enable send button when assistant is ready
    } catch (error) {
      console.error('Error creating assistant:', error);
    } finally {
      setIsAssistantLoading(false);  // Stop assistant loading
    }
  }, []);

  const removeTemporaryAssistant = async () => {
    if (assistant) {
      try {
        await openai.files.del(assistant.fileID);
        console.log('file ID removed:', assistant.fileID);
  
        await openai.beta.threads.del(assistant.threadID);
        console.log('thread ID removed:', assistant.threadID);
  
        await openai.beta.vectorStores.del(assistant.vectorStore);
        console.log('vector store ID removed:', assistant.vectorStore);
  
        await openai.beta.assistants.del(assistant.id);
        console.log('assistant ID removed:', assistant.id);
  
        // Clear the assistant state by setting it to null
        setAssistant(null);
  
        setIsSendDisabled(true); // Disable send button after removing assistant
  
        // Log the assistant state after it's cleared
        setTimeout(() => {
          console.log('assistant removed:', assistant); // This might still log the old value due to async nature of setState
        }, 0);
      } catch (error) {
        console.error('Error removing assistant:', error);
      }
    }
  };
  
  const retryRequest = async (func: () => Promise<any>, retries = 3, defaultDelay = 1000): Promise<any> => {
    try {
      return await func();
    } catch (error: any) {
      if (error?.status === 429 && retries > 0) {
        const retryAfterMatch = error?.response?.data?.error?.message?.match(/after ([\d.]+) seconds/i);
        const retryAfter = retryAfterMatch ? parseFloat(retryAfterMatch[1]) * 1000 : defaultDelay;
        console.log(`Rate limit exceeded, retrying after ${retryAfter / 1000} seconds...`);
        await new Promise((resolve) => setTimeout(resolve, retryAfter));
        return retryRequest(func, retries - 1, defaultDelay);
      } else {
        throw error;
      }
    }
  };

  const uploadFileToOpenAI = async (file: File) => {
    return await retryRequest(async () => {
      const response = await openai.files.create({
        file,
        purpose: 'assistants',
      });
      return response.id;
    });
  };

  const addAssistantImageMessage = async (message: string, fileIds: string[]) => {
    if (!assistant) return;

    const content: OpenAI.Beta.Threads.Messages.MessageContentPartParam[] = [
      { type: "text", text: message },
      ...fileIds.map(fileId => ({
        type: "image_file" as const,
        image_file: { file_id: fileId },
      })),
    ];

    await retryRequest(async () => {
      await openai.beta.threads.messages.create(
        assistant.threadID,
        {
          role: "user",
          content: content,
        }
      );
    });
  };
  

  const processAssistantResponse = async (updatedChat: ChatMessage[]) => {
    if (!assistant) return;
    try {
      if (!assistant.threadID) {
        console.error('No thread ID for this assistant.');
        return;
      }
  
      const client = new OpenAI({ 
        apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
        dangerouslyAllowBrowser: true,
      });
      const threadId = assistant.threadID;
      const eventHandler = new EventHandler(client);
      eventHandler.on("event", eventHandler.onEvent.bind(eventHandler));

        try {
          // Start the stream
          const stream = await openai.beta.threads.runs.stream(threadId, {
            assistant_id: assistant.id,
          });
  
          let partialMessage = ""; // To build the message incrementally
          const newUpdatedChat = updatedChat;

          let lastMessageIndex = -1; // Keep track of the last message index
          
          // Listen for events from the stream
          for await (const event of stream) {
            eventHandler.emit("event", event); // Emit each event to your EventHandler instance
  
            // Type assertion to access the 'delta' property
            if ((event as any).data.delta?.content?.length > 0) {
              const chunk = (event as any).data.delta.content[0].text.value;
              partialMessage += chunk; // Add the chunk to the partial message
  
              // Find the last assistant message if not already located
              if (lastMessageIndex === -1) {
                lastMessageIndex = newUpdatedChat.findIndex(
                  (msg) => msg.from === 'assistant' && !msg.message.trim()
                );
              }
  
              // Update the assistant's response incrementally
              console.log('lastMessageIndex',lastMessageIndex)

              if (lastMessageIndex !== -1) {
                newUpdatedChat[lastMessageIndex].message = partialMessage;
                console.log('updated chat1',newUpdatedChat)

              } else {
                newUpdatedChat.push({ message: partialMessage, from: 'assistant', type: 'text' });
                lastMessageIndex = newUpdatedChat.length - 1; // Set the new index
              }
  
              setAssistant((prev) => prev ? { ...prev, chat: newUpdatedChat } : null);
              setIsAssistantThinking(false); 
            }
          }
  
          setLoading(false); // Set loading to false after completion
        } catch (error) {
          console.error("Error handling stream:", error);
        }
      
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
      setLoading(false); // Ensure loading is reset in case of error
    }
  };
  

  const handleSendMessage = async () => {

    setIsAssistantThinking(true);
    if (isAssistantLoading) {
      console.log("Assistant is still loading. Please wait.");
      return;
    }

    if (!assistant || !assistant.threadID) {
      console.error("Cannot send message: Assistant or threadID is not available.");
      return;
    }

    if (newMessage.trim() === '' && uploadedFiles.length === 0) return;

    let updatedChat: ChatMessage[] = assistant ? [...assistant.chat] : [];
    let fileQuestion = newMessage;

    const filePromises = uploadedFiles.map(async (file) => {
      const fileUrl = URL.createObjectURL(file);
      fileQuestion = `<img src="${fileUrl}" alt="Uploaded file" style="max-width:100%; height: auto;">\n\n` + fileQuestion;

      const uploadedFileId = await uploadFileToOpenAI(file);
      return { fileUrl, fileId: uploadedFileId };
    });

    if (uploadedFiles.length > 0) {
      try {
        const fileData = await Promise.all(filePromises);
        const fileIds = fileData.map(file => file.fileId);

        if (fileQuestion.trim() !== '') {
          updatedChat.push({ message: fileQuestion, from: 'user', type: 'text' });
        }

        setAssistant((prev) => prev ? { ...prev, chat: updatedChat } : null);

        setLoading(true);
        await addAssistantImageMessage(fileQuestion, fileIds);
        await processAssistantResponse(updatedChat);
        setIsAssistantThinking(false);

      } catch (error) {
        console.error('Error uploading files:', error);
        return;
      }
    } else {
      if (newMessage.trim() !== '') {
        updatedChat.push({ message: newMessage, from: 'user', type: 'text' });
      }

      setAssistant((prev) => prev ? { ...prev, chat: updatedChat } : null);
      setLoading(true);

      try {
        const content = [{ type: 'text' as const, text: newMessage }];
        await retryRequest(async () => {
          await openai.beta.threads.messages.create(assistant!.threadID, { role: 'user', content });
        });
        await processAssistantResponse(updatedChat);
        setIsAssistantThinking(false);

      } catch (error) {
        console.error('Error sending message:', error);
        setLoading(false); // Ensure loading is reset in case of error
        setIsAssistantThinking(false); 
      }
    }

    setNewMessage('');
    setShowPreloadedMessage(false); 
    setUploadedFiles([]);
  };

  
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const filesArray = Array.from(e.target.files);
      setUploadedFiles((prevFiles) => [...prevFiles, ...filesArray]);
    }
  };

  const handleClose = async (isCancel = false) => {
    setTextareaContent('');
    setLeaving(true);
    setIsPanning(false);
    if (assistant) {
      await removeTemporaryAssistant();
    }
  
    setLeaving(false);
  
    if (isCancel) {
      setIsChatOpen(false);
    } else {
      setIsChatOpen(false);        
      onClose();
    }
  };

  const handleQnaClose = () => {
    setIsQnaOpen(false);
  };

  const handleSummaryClose = () => {
    setIsSummaryOpen(false);
    if (activeViewer === 'summary') setActiveViewer('main');
    setPdfViewerWidth(100); // Adjust as desired when summary viewer closes
  };

// Codes for formatting outputs
const splitIntoSegments = (text: string): string[] => {
    return text.split(/\n\n/).map(segment => segment.trim()).filter(segment => segment);
  };
  
  const formatCodeSnippet = (
    segment: string, 
    key: number, 
    copiedKey: number | null, 
    handleCopy: (key: number) => void
  ): JSX.Element => {
    const [, lang = '', code = ''] = segment.match(/^```(.*?)\n([\s\S]*?)```$/) || [];
    
    const header = (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#001f3f', padding: '5px 10px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
        <span>{lang.trim()}</span>
        <button 
          onClick={() => handleCopy(key)} 
          style={{ backgroundColor: 'transparent', color: 'white', border: 'none', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          {copiedKey === key ? <FaCheck /> : <FaCopy />}
          <span style={{ marginLeft: '5px' }}>
            {copiedKey === key ? 'Copied' : 'Copy'}
          </span>
        </button>
      </div>
    );
  
    return (
      <div key={`code-container-${key}`} style={{ backgroundColor: '#002366', padding: '10px', borderRadius: '5px', width: '90%', margin: '10px auto', color: 'white', wordBreak: 'break-word', overflowX: 'auto' }}>
        {header}
        <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{code.trim()}</pre>
      </div>
    );
  };
  
  
  const isCodeSnippet = (segment: string): boolean => {
    return segment.startsWith('```') || segment.endsWith('```');
  };
  
  const isImage = (segment: string): boolean => {
    return segment.startsWith('<img src="') && segment.endsWith('">');
  };
  
  const isAudio = (segment: string): boolean => {
    return segment.startsWith('<audio') && segment.endsWith('</audio>');
  };
  
  const extractListNumber = (segment: string | undefined): number | undefined => {
    if (!segment) {
      return undefined;
    }
  
    // Extracts the starting number from a segment that starts with a number followed by a period (e.g., "1. ", "2. ")
    const match = segment.match(/^(\d+)\.\s+/);
    return match ? parseInt(match[1], 10) : undefined;
  };
  
  const isListInSegment = (segment: string | undefined): boolean => {
    if (!segment) {
      return false;
    }
  
    // Match segments that contain multiple numbered list items (e.g., "1. First\n2. Second")
    const matches = segment.match(/(\d+\.\s+)/g);
    return matches !== null && matches.length > 1;
  };
  
  const formatList = (segments: string[], key: number): JSX.Element => {
    // Format the collected list segments as a list
    const listItems = segments.map((segment, idx) => {
      const formattedText = segment;
      // Split the segment by "-" to create sub-list items
      const subListItems = formattedText.split(/(?=-\s)/).map((subItem, subIdx) => {
        // Split the sub-item by ** and alternate between text and emphasized content
        const parts = subItem.split(/\*\*(.*?)\*\*/g);
  
        return (
          <li
            key={`sub-list-item-${key}-${idx}-${subIdx}`}
            style={{
              listStyleType: 'none',
              marginLeft: '20px',
              textIndent: '-20px', // Outdent the first line
              paddingLeft: '40px', // Indent the rest of the lines
              fontSize: '14px',
            }}
          >
            {/* Map through the parts, wrapping emphasized content in <strong> */}
            {parts.map((part, i) =>
              i % 2 === 1 ? (
                <strong key={`strong-${key}-${idx}-${i}`} style={{ display: 'inline' }}>
                  {part}
                </strong>
              ) : (
                <span key={`span-${key}-${idx}-${i}`} style={{ display: 'inline' }}>
                  {part}
                </span>
              )
            )}
          </li>
        );
      });
  
      // If there are multiple sub-items, wrap them in a nested <ul>
      return subListItems.length > 1 ? (
        <li
          key={`list-item-${key}-${idx}`}
          style={{
            listStyleType: 'none',
            marginLeft: '20px',
            textIndent: '-20px', // Outdent the first line
            paddingLeft: '40px', // Indent the rest of the lines
          }}
        >
          <ul style={{ paddingLeft: '0' }}>{subListItems}</ul>
        </li>
      ) : (
        subListItems[0] // If only one item, return it as is
      );
    });
  
    return <ul key={`list-${key}`} style={{ paddingLeft: '0' }}>{listItems}</ul>;
  };
  
  
  const extractListItems = (segment: string): string[] => {
    // Extracts list items from a segment with sequential numbers (e.g., "1. First\n2. Second")
    return segment.split(/(?=\d+\.\s+)/);
  };

  const formatContent = (text: string): React.ReactNode => {
    const segments = splitIntoSegments(text);
    let inCodeBlock = false;
    let codeBlockContent = '';
    let listSegments: string[] = [];
    let expectedListNumber = 1;
    let result: React.ReactNode[] = [];
  
    // Helper function to push formatted content into the result array
    const pushFormattedContent = (formattedSegment: string, index: number) => {
      // Check if the segment contains list items starting with "- "
      if (formattedSegment.includes('- ')) {
        const listItems = formattedSegment
          .split(/\n+/) // Split the segment by newlines to handle multi-line lists
          .filter(item => item.trim().startsWith('- ')) // Filter out non-list lines
          .map((item, i) => {
            // Remove the leading "- " from the list item
            const content = item.replace(/^- /, '');
    
            // Split the content by ** and alternate between text and emphasized content
            const parts = content.split(/\*\*(.*?)\*\*/g);
    
            return (
              <li key={`list-item-${index}-${i}`}>
                {parts.map((part, j) =>
                  j % 2 === 1 ? (
                    <strong key={`strong-${index}-${j}`} style={{ display: 'inline' }}>
                      {part}
                    </strong>
                  ) : (
                    <span key={`span-${index}-${j}`} style={{ display: 'inline' }}>
                      {part}
                    </span>
                  )
                )}
              </li>
            );
          });
  
    
        // Render the list of items inside a <ul>
        result.push(
          <ul key={`list-${index}`} style={{ listStyleType: 'none', paddingLeft: '20px' }}>
            {listItems}
          </ul>
        );
      } else {

        // If it's not a list, replace ** for emphasis and push as a regular div
        const parts = formattedSegment.split(/\*\*(.*?)\*\*/g);
    
        result.push(
          <div key={`segment-${index}`}>
            {parts.map((part, j) =>
              j % 2 === 1 ? (
                <strong key={`strong-${index}-${j}`} style={{ display: 'inline' }}>
                  {part}
                </strong>
              ) : (
                <span key={`span-${index}-${j}`} style={{ display: 'inline' }}>
                  {part}
                </span>
              )
            )}
          </div>
        );
      }
    };
    
  
    segments.forEach((segment, index) => {
      if (!segment) return;
      
      // Handle code blocks
      if (inCodeBlock) {
        codeBlockContent += '\n' + segment;
        if (segment.endsWith('```')) {
          inCodeBlock = false;
          const formattedCode = formatCodeSnippet(codeBlockContent, index, copiedKey, handleCopy);
          codeBlockContent = '';
          result.push(formattedCode);
        }
        return;
      }
  
      if (isCodeSnippet(segment)) {
        if (segment.startsWith('```') && segment.endsWith('```')) {
          result.push(formatCodeSnippet(segment, index, copiedKey, handleCopy));
          return;
        } else if (segment.startsWith('```')) {
          inCodeBlock = true;
          codeBlockContent = segment;
          return;
        }
      }
  
      // Handle headings (###), converting only the part before the first newline into a heading
      let formattedSegment = segment;
      if (formattedSegment.startsWith('###')) {
        const firstNewlineIndex = formattedSegment.indexOf('\n');
        let headingText = formattedSegment;
        let remainingText = '';
  
        if (firstNewlineIndex !== -1) {
          headingText = formattedSegment.substring(0, firstNewlineIndex); // Capture everything before the first newline
          remainingText = formattedSegment.substring(firstNewlineIndex + 1); // Capture everything after the first newline
        }

        // Handle "**" emphasis for the heading content
        let headingContent = headingText.replace(/^###\s*/, ''); // Remove "###" and any following space
        if (headingContent.includes('**')) {
          headingContent = headingContent.replace(/\*\*(.*?)\*\*/g, "$1");
        }

        // Add heading to the result
        result.push(<h3 key={`heading-${index}`} dangerouslySetInnerHTML={{ __html: headingContent }} />);
        // If there's remaining text, continue formatting it as well
        if (remainingText) {
          formattedSegment = remainingText; // Continue with the remaining text in the segment
        } else {
          formattedSegment = ''; // Clear the segment if no remaining text
        }
      }

      // Handle lists or paragraphs
      const currentListNumber = extractListNumber(formattedSegment);
      
      if (currentListNumber === expectedListNumber || isListInSegment(formattedSegment)) {
        const extractedListSegments = extractListItems(formattedSegment);
        listSegments = listSegments.concat(extractedListSegments);
  
        // Update the expected list number
        const lastListSegment = extractListNumber(listSegments[listSegments.length - 1]);
        expectedListNumber = lastListSegment !== undefined ? lastListSegment + 1 : 1;
  
        // Check if this is the last list segment or if the next segment continues the list
        const nextSegment = segments[index + 1];
        const nextListNumber = nextSegment ? extractListNumber(nextSegment) : undefined;
  
        // If no more list items or list ends, format the entire list
        if (!nextSegment || nextListNumber !== expectedListNumber) {
          const formattedList = formatList(listSegments, index);
          listSegments = [];
          expectedListNumber = 1;
          result.push(formattedList);
        }
        return; // Skip further processing of this segment as it's part of a list
      }
  
      // Handle images and audio (optional)
      if (isImage(formattedSegment)) {
        result.push(<div key={`image-${index}`} dangerouslySetInnerHTML={{ __html: formattedSegment }} />);
        return;
      } else if (isAudio(formattedSegment)) {
        result.push(<div key={`audio-${index}`} dangerouslySetInnerHTML={{ __html: formattedSegment }} />);
        return;
      }
  
      // If no special formatting applies, treat the rest as a regular paragraph or other format
      pushFormattedContent(formattedSegment, index);
    });
    return result.length ? result : undefined;
  };
  
   
  // Function to read aloud the assistant's response
  const readAloud = (text: string) => {
    if (isPlaying && audioInstance) {
      // Stop playing if already playing
      speechSynthesis.cancel(); // Stop any ongoing speech
      setIsPlaying(false); // Reset playing state
      setAudioInstance(null); // Clear audio instance
      return; // Exit if we are stopping
    }

    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = 'en-US'; // Set the language as needed
    speech.onstart = () => {
      setIsPlaying(true); // Set playing state
      setAudioInstance(speech); // Store the audio instance
    }; 
    speech.onend = () => {
      setIsPlaying(false); // Reset playing state when done
      setAudioInstance(null); // Clear audio instance
    };

    speechSynthesis.speak(speech);
  };

  const handleCopyResponse = (index: number, response: string) => {
    navigator.clipboard.writeText(response).then(() => {
      setCopiedResponseIndex(index);
      setTimeout(() => setCopiedResponseIndex(null), 2000); // Reset after 2 seconds
    }).catch(err => console.error('Failed to copy:', err));
  };

  const handleMouseDownOnResizeBar = (e: React.MouseEvent) => {
    e.preventDefault();
    resizingRef.current = true;

    const handleMouseMove = (event: MouseEvent) => {
      if (resizingRef.current && mainPdfViewerRef.current) {
        const newWidth = (event.clientX / window.innerWidth) * 100;
        setPdfViewerWidth(Math.min(95, Math.max(5, newWidth))); // Restrict width between 5% and 95%
      }
    };

    const handleMouseUp = () => {
      resizingRef.current = false;
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };


  // Functions for simple audio player
  const handlePlayAudio = async (url: string,fileRefPath: string) => {
    if (audioRef.current) {
      try {
        if (playingAudio === url && isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
        } else {
          if (playingAudio === url) {
            audioRef.current.play();
            setIsPlaying(true);
          } else {
            audioRef.current.src = url;
            audioRef.current.currentTime = 0;
            audioRef.current.play();
            setPlayingAudio(url);
            setIsPlaying(true);
          }
        }
      } catch (error) {
        console.error('Error playing audio:', error);
        alert('Failed to play the audio. Please try again.');
      }
    }
  };

  // Function to update progress of audio playback
  const handleAudioProgress = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
  
      // Use metadataDuration if available, otherwise fallback to audioRef.current.duration
      const duration = metadataDuration || audioRef.current.duration;
  
      if (isFinite(currentTime) && isFinite(duration) && duration > 0) {
        const progress = (currentTime / duration) * 100;
        setAudioProgress(progress);
      }
    }
  };

  const handleFastForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 10; // Fast forward 10 seconds
    }
  };

  const handleRewind = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 10; // Rewind 10 seconds
    }
  };

  const handleSpeedChange = () => {
    if (audioRef.current) {
      const newSpeed = playbackSpeed === 1.0 ? 1.5 : playbackSpeed === 1.5 ? 2.0 : 1.0;
      audioRef.current.playbackRate = newSpeed; // Set playback speed
      setPlaybackSpeed(newSpeed);
    }
  };

  // Function to handle seeking when dragging the progress bar
const handleAudioSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (audioRef.current && isFinite(audioRef.current.duration)) {
    const seekTime = (parseFloat(event.target.value) / 100) * audioRef.current.duration;
    if (isFinite(seekTime)) {
      audioRef.current.currentTime = seekTime;
    }
  }
};

  // Function to handle metadata load (duration availability)
  const handleMetadataLoaded = () => {
    if (audioRef.current && isFinite(audioRef.current.duration)) {
      setAudioProgress(0); // Reset progress when metadata is loaded
    }
  };

const handleToggleAnswer = (index: number) => {
  if (showAnswerIndexes.includes(index)) {
    setShowAnswerIndexes(showAnswerIndexes.filter(i => i !== index));
  } else {
    setShowAnswerIndexes([...showAnswerIndexes, index]);
  }
};


const handleDeleteItem = async (index: number) => {
  const currentUser = auth.currentUser;
  if (!currentUser || !selectedSubject) return;

  const confirmDeletion = window.confirm('Are you sure you want to delete this question?');
  if (!confirmDeletion) return;

  const updatedLibraryItems = libraryItems.filter((_, i) => i !== index);
  setLibraryItems(updatedLibraryItems);

  const subjectFileName = `library/${currentUser.uid}_${selectedSubject}_qna.json`;
  const subjectRef = ref(storage, subjectFileName);
  await uploadString(subjectRef, JSON.stringify(updatedLibraryItems));
};



const filterUnsupportedCharacters = useCallback((text: string): string => {
  // Replace specific problematic characters with placeholders or remove them
  const filteredText = text
    .replace(/[\u2190-\u21FF]/g, '[ARROW]') // Arrows
    .replace(/[\u2200-\u22FF]/g, '[MATH]') // Mathematical operators
    .replace(/[\u2300-\u23FF]/g, '[TECH]') // Miscellaneous technical symbols
    .replace(/[\u2500-\u257F]/g, '[BOX]') // Box drawing
    .replace(/[\u2580-\u259F]/g, '[BLOCK]') // Block elements
    .replace(/[\u25A0-\u25FF]/g, '[GEOMETRIC]') // Geometric shapes
    .replace(/[\u2600-\u26FF]/g, '[SYMBOL]') // Miscellaneous symbols
    .replace(/[\u2700-\u27BF]/g, '[DINGBAT]') // Dingbats
    .replace(/[\u2B00-\u2BFF]/g, '[SYMBOL]') // Miscellaneous symbols and arrows
    .replace(/[\u2C60-\u2C7F]/g, '[LATIN]') // Latin Extended-C
    .replace(/[\u2E80-\u2EFF]/g, '[CJK]') // CJK Radicals Supplement
    .replace(/[\u2F00-\u2FDF]/g, '[KANGXI]') // Kangxi Radicals
    .replace(/[\u2FF0-\u2FFF]/g, '[IDEOGRAPH]') // Ideographic Description Characters
    .replace(/[\u3000-\u303F]/g, '[CJK SYMBOL]') // CJK Symbols and Punctuation
    .replace(/[\u3040-\u309F]/g, '[HIRAGANA]') // Hiragana
    .replace(/[\u30A0-\u30FF]/g, '[KATAKANA]') // Katakana
    .replace(/[\u3100-\u312F]/g, '[BOPOMOFO]') // Bopomofo
    .replace(/[\u3130-\u318F]/g, '[HANGUL]') // Hangul Compatibility Jamo
    .replace(/[\u3190-\u319F]/g, '[KANBUN]') // Kanbun
    .replace(/[\u31A0-\u31BF]/g, '[BOPOMOFO]') // Bopomofo Extended
    .replace(/[\u31F0-\u31FF]/g, '[KATAKANA]') // Katakana Phonetic Extensions
    .replace(/[\u3200-\u32FF]/g, '[ENCLOSED CJK]') // Enclosed CJK Letters and Months
    .replace(/[\u3300-\u33FF]/g, '[CJK COMPAT]') // CJK Compatibility
    .replace(/[\u3400-\u4DBF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs Extension A
    .replace(/[\u4E00-\u9FFF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs
    .replace(/[\uA000-\uA48F]/g, '[YI]') // Yi Syllables
    .replace(/[\uA490-\uA4CF]/g, '[YI]') // Yi Radicals
    .replace(/[\uA700-\uA71F]/g, '[MODIFIER]') // Modifier Tone Letters
    .replace(/[\uA720-\uA7FF]/g, '[LATIN]') // Latin Extended-D
    .replace(/[\uA800-\uA82F]/g, '[SYLOTI NAGRI]') // Syloti Nagri
    .replace(/[\uA830-\uA83F]/g, '[NUMERIC]') // Common Indic Number Forms
    .replace(/[\uA840-\uA87F]/g, '[PHAGS-PA]') // Phags-pa
    .replace(/[\uA880-\uA8DF]/g, '[SAURASHTRA]') // Saurashtra
    .replace(/[\uA900-\uA92F]/g, '[KAYAH]') // Kayah Li
    .replace(/[\uA930-\uA95F]/g, '[REJANG]') // Rejang
    .replace(/[\uA960-\uA97F]/g, '[HANGUL]') // Hangul Jamo Extended-A
    .replace(/[\uAC00-\uD7AF]/g, '[HANGUL]') // Hangul Syllables
    .replace(/[\uD800-\uDB7F]/g, '[SURROGATE]') // High Surrogates
    .replace(/[\uDB80-\uDBFF]/g, '[SURROGATE]') // High Private Use Surrogates
    .replace(/[\uDC00-\uDFFF]/g, '[SURROGATE]') // Low Surrogates
    .replace(/[\uE000-\uF8FF]/g, '[PRIVATE]') // Private Use Area
    .replace(/[\uF900-\uFAFF]/g, '[CJK COMPAT]') // CJK Compatibility Ideographs
    .replace(/[\uFB00-\uFB4F]/g, '[ALPHABETIC]') // Alphabetic Presentation Forms
    .replace(/[\uFB50-\uFDFF]/g, '[ARABIC]') // Arabic Presentation Forms-A
    .replace(/[\uFE00-\uFE0F]/g, '[VARIATION]') // Variation Selectors
    .replace(/[\uFE10-\uFE1F]/g, '[VERTICAL]') // Vertical Forms
    .replace(/[\uFE20-\uFE2F]/g, '[COMBINING]') // Combining Half Marks
    .replace(/[\uFE30-\uFE4F]/g, '[CJK COMPAT]') // CJK Compatibility Forms
    .replace(/[\uFE50-\uFE6F]/g, '[SMALL FORM]') // Small Form Variants
    .replace(/[\uFE70-\uFEFF]/g, '[ARABIC]') // Arabic Presentation Forms-B
    .replace(/[\uFF00-\uFFEF]/g, '[HALFWIDTH]') // Halfwidth and Fullwidth Forms
    .replace(/[\uFFF0-\uFFFF]/g, '[SPECIAL]'); // Specials    
  return filteredText;
}, []);


const generateUniqueFileName = useCallback(
async (originalName: string): Promise<string> => {

  let folderName = '';

  if (currentFile) {
      if (currentFile.documentType === "YouTube Video") {
        folderName= `videosTranscript/`;
      } else if (currentFile.documentType === "Recording") {
        folderName= `recordingTranscript/`;
      }
  }
  
  const storageRef = ref(storage, folderName);
  const fileList = await listAll(storageRef);

  let newFileName = originalName;
  let counter = 1;
  
  const existingFiles = fileList.items.map((file) => file.name);

  while (existingFiles.includes(newFileName + '.pdf')) {
    const fileNameWithoutExt = originalName;
    newFileName = `${fileNameWithoutExt}(${counter})`;
    counter++;
  }
  
  return `${newFileName}.pdf`;
},
[currentFile]
);


const getOriginalFileName = useCallback((url: string): string => {
  // Decode the URL to get the file path with normal slashes
  const decodedUrl = decodeURIComponent(url);
  
  // Extract the file name after removing the "recordings/" prefix
  const fileName = decodedUrl.substring(decodedUrl.lastIndexOf('recordings/') + 'recordings/'.length);
  
  // Remove the file extension
  return fileName.split('.').slice(0, -1).join('.');
}, []);


const uploadTextAsPDF = useCallback(async (text: string) => {
  const pdfDoc = await PDFDocument.create();

  // Register fontkit
  pdfDoc.registerFontkit(fontkit as any);

  // Load a custom Unicode font from a TTF file
  const fontUrl = process.env.PUBLIC_URL + '/assets/fonts/Roboto-Regular.ttf';
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const customFont = await pdfDoc.embedFont(fontBytes); // Embed custom font

  // Set up an 8x11 inch page in portrait format (8.5 inches wide, 11 inches tall)
  const pageWidth = 8.5 * 72;
  const pageHeight = 11 * 72;

  let page = pdfDoc.addPage([pageWidth, pageHeight]);
  const { width, height } = page.getSize();
  const fontSize = 12;
  const lineHeight = fontSize + 4;
  const margin = 50;
  const maxLineWidth = width - margin * 2;

  const sanitizedText = filterUnsupportedCharacters(text);
  const paragraphs = sanitizedText.split('\n');

  let y = height - margin;

  for (const paragraph of paragraphs) {
    const words = paragraph.split(' ');
    let currentLine = '';

    for (const word of words) {
      const testLine = currentLine + word + ' ';
      const testLineWidth = customFont.widthOfTextAtSize(testLine, fontSize);

      if (testLineWidth > maxLineWidth) {
        if (y - lineHeight < margin) {
          page = pdfDoc.addPage([pageWidth, pageHeight]);
          y = height - margin;
        }
        page.drawText(currentLine, {
          x: margin,
          y: y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        currentLine = word + ' ';
        y -= lineHeight;
      } else {
        currentLine = testLine;
      }
    }

    if (currentLine.trim()) {
      if (y - lineHeight < margin) {
        page = pdfDoc.addPage([pageWidth, pageHeight]);
        y = height - margin;
      }
      page.drawText(currentLine.trim(), {
        x: margin,
        y: y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      y -= lineHeight;
    }

    // After processing each paragraph, add a line break
    y -= lineHeight;
  }

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  
  const extractVideoId = (url: string): string => {
    // Extract the part of the URL after the last '/'
    const fileName = url.split('/').pop()?.split('?')[0]; 
    if (!fileName) return '';
  
    // Extract the video ID from the file name based on the assumed naming convention
    const parts = fileName.split('_');
    const videoId = parts.length > 1 ? parts[1] : ''; 
  
    // Remove the ".url" suffix if it exists
    return videoId.replace('.url', '');
  };
  
 // console.log('seleced audio url',pdfUrl)

  let originalName = '';
  if (currentFile) {
      if (currentFile.documentType === "YouTube Video") {
          console.log('selectedVideoUrl',pdfUrl)
          const originalName0 = pdfUrl ? extractVideoId(pdfUrl || '') : '';
          console.log('originalName0',originalName0)
          originalName = userUID +'_' + originalName0 + '_transcript';

      } else if (currentFile.documentType === "Recording") {
          const originalName0 = getOriginalFileName(pdfUrl || '');
          originalName = originalName0 + '_transcript';
      }
  }

  console.log('originalName',originalName)
  
  const uniqueFileName = await generateUniqueFileName(originalName);
  let folderName = '';
  if (currentFile) {
      if (currentFile.documentType === "YouTube Video") {
        folderName= `videosTranscript/`;
      } else if (currentFile.documentType === "Recording") {
        folderName= `recordingTranscript/`;
      }
  }

  console.log('uniqueFileName',uniqueFileName )
  const storageRef = ref(storage, `${folderName}${uniqueFileName}`);

  try {
    const userFilesRef = ref(storage, folderName);
    const fileList = await listAll(userFilesRef);

    const currentUser = auth.currentUser;
    if (currentUser) {
      const uid = currentUser.uid;
      setUserUID(uid);
      setIsAnonymous(currentUser.isAnonymous);
    
    if (isAnonymous) {
     // console.log('isAnonymouse',isAnonymous)
      // Allow anonymous users to upload a maximum of 2 files
      const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
      if (existingFiles.length >= 2) {
        alert('Anonymous users can only upload up to two files.');
        return;
      }
    } else {
      const currentUser = auth.currentUser;
      if (currentUser) {
            const uid = currentUser.uid;
            setUserUID(uid);
            // For non-anonymous users, check Firestore for subscriptionStatus
            const firestore = getFirestore();
            const userDocRef = doc(firestore, 'users', userUID);
            const userDoc = await getDoc(userDocRef);
            const userData = userDoc.data();

            const isStarterUser = userData?.subscriptionStatus !== true; // Starter user if subscriptionStatus is not true

            if (isStarterUser) {
              // Check if the starter user has already uploaded 2 files
              const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
              if (existingFiles.length >= 2) {
                alert('Starter users can only upload up to two files.');
                return;
              }
            }

            }
          }

          // Set custom metadata based on the user's selection
          const customMetadata = {
            customMetadata: {
              ShareWithPublic: 'No',
              UserID:userUID,
              DocumentType:'Trancript',
            },
          };

          await uploadBytes(storageRef, blob,customMetadata);
          const transcript_Url = await getDownloadURL(storageRef);
          // Update currentFile's custom metadata with the transcript_Url
          if (currentFile) {
            const fileRef = ref(storage, currentFile.refPath);
            const currentMetadata = await getMetadata(fileRef);

            const updatedMetadata = {
                customMetadata: {
                    ...currentMetadata.customMetadata, // Preserve existing metadata
                    TranscriptUrl: transcript_Url, // Add the transcriptUrl
                },
            };

            await updateMetadata(fileRef, updatedMetadata);

            setCurrentFile({
              ...currentFile, 
              transcriptUrl: transcript_Url, 
            });

           // console.log("Updated currentFile metadata with TranscriptUrl:", transcript_Url);
        }

          setIsConverting(false);
          console.log('PDF has been succesfully generated')

  }


  } catch (error) {
    console.error('Upload error:', error);
  }
}, [
  currentFile,
  setCurrentFile,
  filterUnsupportedCharacters,
  generateUniqueFileName,
  getOriginalFileName,
  userUID,
  isAnonymous,
  setIsConverting,
  pdfUrl,
]);



const handleSubmitScript = useCallback(async (content?: string) => {
  const textToSubmit = content ?? textareaContent.trim(); // Use argument or state value
  //console.log('starting to transcribe');

  if (textToSubmit) {
    await uploadTextAsPDF(textToSubmit);
  } else {
    alert('Please paste some information before submitting.');
  }
}, [textareaContent, uploadTextAsPDF]); // Add dependencies


const handleTranscribeAudio = useCallback(async (url: string) => {
 // console.log('Transcribing audio for', url);
  try {
    setTextareaContent('Processing the audio, please wait...');
    setIsConverting(true);

    // Step 1: Fetch the audio file from the provided URL
    const audioBlob = await fetch(url).then((res) => res.blob());

    // Step 2: Convert the audio Blob to base64 format (if necessary, Whisper API can accept Blob directly)
    const audioFile = new File([audioBlob], "audio.mp4", { type: audioBlob.type });
   // console.log("audio file type:", audioBlob.type)
    // Step 3: Call OpenAI's Whisper API for transcription
    const transcriptionText = await transcribeAudioWithOpenAI(audioFile);   
    setTextareaContent(transcriptionText); // Update the textarea with the transcription
    await handleSubmitScript(transcriptionText); // Save the full transcript

  } catch (error) {
    setTextareaContent('An error occurred during audio transcription. Please try again.');
    console.error('Error during transcription:', error);
  } finally {
    setIsConverting(false);
  }
}, [handleSubmitScript]);

const transcribeAudioWithOpenAI = async (audioFile: File): Promise<string> => {
  try {
    // Call OpenAI's Whisper API for transcription
    const transcription = await openai.audio.transcriptions.create({
      file: audioFile,
      model: "whisper-1",
      response_format: "text",
    });

   // console.log('transcription', transcription);

    // Convert the transcription to a string explicitly
    if (transcription && typeof transcription === 'string') {
      return transcription; // Return the transcription as a string
    } else if (transcription && transcription.text) {
      return String(transcription.text); // Safely convert the text to a string
    } else {
      throw new Error("No transcription text found in the response.");
    }
  } catch (error) {
    console.error("Error during transcription:", error);
    return "Error during transcription.";
  }
};



const handleTranscribeVideo = useCallback(async (youtubeUrl: string, url: string) => {
  setTextareaContent('Processing the video, please wait...');
  setIsConverting(true);
  console.log('yourtubeurl',youtubeUrl)

  try {
    const response = await fetch(`/api/download-captions?url=${encodeURIComponent(youtubeUrl)}`);

    if (response.headers.get('Content-Type') === 'text/plain; charset=utf-8') {
      // Process captions as plain text
      const captionContent = await response.text();
      setTextareaContent(captionContent);
      console.log('Caption content fetched:', captionContent);
      await handleSubmitScript(captionContent); // Save the transcript dynamically
    } else if (response.headers.get('Content-Type') === 'audio/mpeg') {
      // Process captions as audio stream
      const reader = response.body?.getReader();

      if (reader) {
        let dynamicTranscript = ''; // Initialize an empty transcript
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          // Create a Blob from the chunk of audio data
          const audioBlob = new Blob([value], { type: 'audio/mpeg' });
          const audioFile = new File([audioBlob], 'audio_chunk.mp3', { type: 'audio/mpeg' });

          // Transcribe the chunk using Whisper
          const chunkTranscript = await transcribeAudioChunkWithWhisper(audioFile, openai);

          // Append the chunk to the dynamic transcript
          dynamicTranscript += chunkTranscript;

          // Update the state to dynamically show the transcript
          setTextareaContent((prev) => prev + chunkTranscript);
        }

        //console.log('Full transcript:', dynamicTranscript);
        await handleSubmitScript(dynamicTranscript); // Save the full transcript
      }
    }
  } catch (error) {
    console.error('Error fetching captions or audio:', error);
  } finally {
    setIsConverting(false);
  }
}, [ handleSubmitScript]);


// Helper function to transcribe each chunk using OpenAI Whisper
const transcribeAudioChunkWithWhisper = async (audioFile: File, openai: OpenAI): Promise<string> => {
  try {
    const transcription = await openai.audio.transcriptions.create({
      file: audioFile,
      model: "whisper-1",
      response_format: "text",
    });
    return String(transcription);
  } catch (error) {
    console.error('Error during transcription:', error);
    return ''; // Return empty string if error occurs
  }
};

const getVideoTranscriptRefPath = (fileName: string): string => {
  return `videosTranscript/${fileName}`;
};

useEffect(() => {
  const processVideoAndSubmitScript = async () => {
    if (!currentFile) {
      console.warn("No current file selected. Aborting transcription process.");
      return;
    }

    if (!currentFile.url) {
      console.warn("Current file does not have a valid URL. Aborting transcription process.");
      return;
    }

    if (!currentFile.youtubeUrl) {
      console.warn("Current file does not have a valid URL. Aborting transcription process.");
      return;
    }

    if (currentFile.documentType !== 'YouTube Video') {
      console.log("Current file is not a recording. Skipping transcription.");
      return;
    }

    if (hasProcessedFileRef.current === currentFile.url) {
      console.log("This file has already been processed. Skipping transcription...");
      return;
    }

    if (isTranscribingRef.current) {
      console.log("Transcription already in progress. Skipping...");
      return;
    }

    try {
      console.log("Checking transcript URL:", currentFile.transcriptUrl || "No transcript URL");
      // Check if the transcript URL exists
      if (currentFile.transcriptUrl) {
        try {
          const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract the file name without folder
          if (!fileName) throw new Error("Invalid file name extracted from URL.");

          const cleanFileName = fileName.split('%2F').pop(); // Remove any folder paths encoded in the name
          const transcriptPath = getVideoTranscriptRefPath(cleanFileName!.replace(/\..+$/, "_transcript.pdf")); // Replace extension with .pdf
          const transcriptRef = ref(storage, transcriptPath);

          const validUrl = await getDownloadURL(transcriptRef);
          //console.log("Transcript exists at:", validUrl);

          setTranscriptUrl(validUrl);
          setShowTextArea(false);
          setShowTranscript(true);
          hasProcessedFileRef.current = currentFile.url; // Mark as processed
          return; // Exit early if transcript exists
        } catch (error) {
          console.warn("Transcript URL fetch failed. Proceeding to transcription...");
        }
      }

      //console.log("Starting transcription for:", currentFile.url);
      isTranscribingRef.current = true;
      setTranscriptUrl(''); // Clear previous transcript URL
      setShowTranscript(false);
      setShowTextArea(true);

      try {
        await handleTranscribeVideo(currentFile.youtubeUrl, currentFile.url);
      } catch (transcriptionError) {
        console.error("Error during transcription or script submission:", transcriptionError);
      }

      const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract file name from URL
      if (!fileName) throw new Error("Invalid file name extracted from URL.");

      const cleanFileName = fileName.split('%2F').pop(); // Remove any folder paths encoded in the name
      const uniqueFileName = `${cleanFileName!.replace(/\..+$/, "")}_transcript.pdf`; // Replace extension with .pdf
      const newTranscriptPath = getVideoTranscriptRefPath(uniqueFileName);
      const newTranscriptRef = ref(storage, newTranscriptPath);

      currentFile.transcriptUrl = newTranscriptPath; // Update file metadata

      try {
        const validUrl = await getDownloadURL(newTranscriptRef);
       // console.log("New transcript validated at:", validUrl);

        setTranscriptUrl(validUrl);
        setShowTextArea(false);
        setShowTranscript(true);
        hasProcessedFileRef.current = currentFile.url; // Mark as processed

      } catch (validationError) {
        console.error("Failed to validate new transcript URL:", validationError);
      }

    } catch (error) {
      console.error("Error during transcription process:", error);
    } finally {
      isTranscribingRef.current = false; // Reset transcription flag
    }
  };

  if (currentFile) {
    processVideoAndSubmitScript();
  }
}, [currentFile, handleTranscribeVideo, setTranscriptUrl]);


const getAudioTranscriptRefPath = (fileName: string): string => {
  return `recordingTranscript/${fileName}`;
};


useEffect(() => {
  const processRecordingAndSubmitScript = async () => {
    if (!currentFile) {
      console.warn("No current file selected. Aborting transcription process.");
      return;
    }

    if (!currentFile.url) {
      console.warn("Current file does not have a valid URL. Aborting transcription process.");
      return;
    }

    if (currentFile.documentType !== 'Recording') {
      console.log("Current file is not a recording. Skipping transcription.");
      return;
    }

    if (hasProcessedFileRef.current === currentFile.url) {
      console.log("This file has already been processed. Skipping transcription...");
      return;
    }

    if (isTranscribingRef.current) {
      console.log("Transcription already in progress. Skipping...");
      return;
    }

    try {
      console.log("Checking transcript URL:", currentFile.transcriptUrl || "No transcript URL");

      // Check if the transcript URL exists
      if (currentFile.transcriptUrl) {
        try {
          const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract the file name without folder
          if (!fileName) throw new Error("Invalid file name extracted from URL.");

          const cleanFileName = fileName.split('%2F').pop(); // Remove any folder paths encoded in the name
          const transcriptPath = getAudioTranscriptRefPath(cleanFileName!.replace(/\..+$/, "_transcript.pdf")); // Replace extension with .pdf
          const transcriptRef = ref(storage, transcriptPath);

          const validUrl = await getDownloadURL(transcriptRef);
          //console.log("Transcript exists at:", validUrl);

          setTranscriptUrl(validUrl);
          setShowTextArea(false);
          setShowTranscript(true);
          hasProcessedFileRef.current = currentFile.url; // Mark as processed
          return; // Exit early if transcript exists
        } catch (error) {
          console.warn("Transcript URL fetch failed. Proceeding to transcription...");
        }
      }

      //console.log("Starting transcription for:", currentFile.url);
      isTranscribingRef.current = true;

      setTranscriptUrl(''); // Clear previous transcript URL
      setShowTranscript(false);
      setShowTextArea(true);

      // Perform transcription
      await handleTranscribeAudio(currentFile.url);

      const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract file name from URL
      if (!fileName) throw new Error("Invalid file name extracted from URL.");

      const cleanFileName = fileName.split('%2F').pop(); // Remove any folder paths encoded in the name
      const uniqueFileName = `${cleanFileName!.replace(/\..+$/, "")}_transcript.pdf`; // Replace extension with .pdf
      const newTranscriptPath = getAudioTranscriptRefPath(uniqueFileName);
      const newTranscriptRef = ref(storage, newTranscriptPath);

      currentFile.transcriptUrl = newTranscriptPath; // Update file metadata

      try {
        const validUrl = await getDownloadURL(newTranscriptRef);
       // console.log("New transcript validated at:", validUrl);

        setTranscriptUrl(validUrl);
        setShowTextArea(false);
        setShowTranscript(true);
        hasProcessedFileRef.current = currentFile.url; // Mark as processed
      } catch (validationError) {
        console.error("Failed to validate new transcript URL:", validationError);
      }
    } catch (error) {
      console.error("Error during transcription process:", error);
    } finally {
      isTranscribingRef.current = false; // Reset transcription flag
    }
  };

  if (currentFile) {
    processRecordingAndSubmitScript();
  }
}, [currentFile, handleTranscribeAudio, setTranscriptUrl]);


// Functions for motion audio player
const fetchAudioFromProxy = async (firebaseAudioUrl: string) => {
  try {
    const proxyUrl = `/api/proxy-audio?url=${encodeURIComponent(firebaseAudioUrl)}`;
    const response = await fetch(proxyUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch audio from proxy');
    }
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error fetching audio:', error);
    return null;
  }
};

const getAudioUrl = async (url: string,fileRefPath:string) => {
    // Fetch metadata for duration
    const storageRef = ref(storage, fileRefPath);
    const metadata = await getMetadata(storageRef);
    const duration = metadata.customMetadata?.Duration;
    console.log('fileRefPath',fileRefPath)
    console.log('duration',duration)
    if (duration) {
      setMetadataDuration(parseFloat(duration));
    } else {
      setMetadataDuration(null);
    }
  return fetchAudioFromProxy(url);
};


useEffect(() => {
  if (!audioMotionAnalyzerRef.current) {
    const container = document.getElementById('audio-motion-container');
    if (container) {
      audioMotionAnalyzerRef.current = new AudioMotionAnalyzer(container, {
        mode: 1, // Replace with the correct numeric value for 'waveform'
        gradient: 'rainbow',
      });
    }
  }

  return () => {
    // Cleanup AudioMotionAnalyzer
    audioMotionAnalyzerRef.current?.disconnectInput();
    audioMotionAnalyzerRef.current?.destroy();
    audioMotionAnalyzerRef.current = null;
  };
}, []);


  if (!isOpen || !pdfUrl) return null;

  return (

    <div className="modal-overlay">
      <div className={`pdf-viewer-model ${isChatOpen||isQnaOpen||isSummaryOpen ? 'expanded' : 'shrink'}`} style={{ cursor: isPanning ? 'grab' : 'default' }}>
        <div className="pdf-toolbar">
          <div className="zoom-buttons-container" style={{ display: 'flex', gap: '10px' }}>

          {activeViewer === 'main' && (
              <>
                <MainZoomInButton key="main-zoom-in" />
                <MainZoomOutButton key="main-zoom-out" />
              </>
            )}
            {activeViewer === 'summary' && (
              <>
                <SummaryZoomInButton key="summary-zoom-in" />
                <SummaryZoomOutButton key="summary-zoom-out" />
              </>
            )}

            <button 
              onClick={handlePanClick} 
              style={{ background: 'none', border: 'none', cursor: 'pointer', color: isPanning ? 'blue' : 'white' }}
            >
              <FaHandPaper size={18} title="Pan" />
            </button>

            <button onClick={handleFullPageClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              <FaExpand size={18} title="One Full Page" />
            </button>
          </div>


          {currentFile && currentFile.documentType === 'Note' && (
            <div className="generation-buttons-container" style={{ display: 'flex', gap: '10px' }}>
              {/* Generate Questions Button */}
              <button
                onClick={() => onGenerateQuestions(pdfUrl!)}
                title="Generate Questions"
                className="qna-button"
                style={{ color: qnaStatus[pdfUrl!] ? 'green' : 'red', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}
              >
                Q
              </button>

              {/* Generate Summary Button */}
              <button
                onClick={() => onGenerateSummaries(pdfUrl!)}
                title="Generate Summary"
                className="summary-button"
                style={{ color: summaryStatus[pdfUrl!] ? 'green' : 'red', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}
              >
                S
              </button>
            </div>
          )}

          {currentFile && (currentFile.documentType === 'YouTube Video' || currentFile.documentType === 'Recording')  && (
            <div className="generation-buttons-container" style={{ display: 'flex', gap: '10px' }}>
              {/* Generate Questions Button */}
              <button
                onClick={() => onGenerateQuestions(transcriptUrl!)}
                title="Generate Questions"
                className="qna-button"
                style={{ color: qnaStatus[pdfUrl!] ? 'green' : 'red', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}
              >
                Q
              </button>

              {/* Generate Summary Button */}
              <button
                onClick={() => onGenerateSummaries(transcriptUrl!)}
                title="Generate Summary"
                className="summary-button"
                style={{ color: summaryStatus[pdfUrl!] ? 'green' : 'red', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}
              >
                S
              </button>
            </div>
          )}

          <div className="action-buttons-container" style={{ display: 'flex', gap: '10px' }}>
          <button
            className="chat-button"
            onClick={() => handleQnaButtonClick()} 
            style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '10px' }}
            title="Q & A"
          >
           Q & A
          </button>

          <button
            className="chat-button"
            onClick={() => {handleSummaryButtonClick();}} 
            style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '10px' }}
            title="Summary"
          >

          <FaClipboard size={18} />
          </button>

          {/* Chat Button */}
          {(plan === 'Chat'|| plan === 'PremiumChat') && (
          <button
            className="chat-button"
            onClick={() => handleChatButtonClick()} 
            style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '10px' }}
            title="Chat"
            disabled={isAssistantLoading} // Disable the button while loading
          >
            {isAssistantLoading ? "Loading..." : <FaCommentDots size={18} />}
          </button>
          )}

          {leaving && <div className="leaving-overlay">Leaving...</div>}
        </div>

          <button
            onClick={() => handleClose(false)}
            className="close-viewer-button"
            >
            Close
        </button>

        </div>

        <div className={`modal-container ${isChatOpen ? 'expanded' : 'shrink'}`}>

          <div
            className="pdf-viewer-container"
            ref={mainPdfViewerRef}
            onMouseDown={handleMouseDown}
            onClick={() => setActiveViewer('main')}
            style={{ width: `${pdfViewerWidth}%` }}
          >

          {currentFile && (
            <>
              {currentFile.documentType === 'Note' && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer 
                    fileUrl={pdfUrl} 
                    plugins={[mainZoomPlugin, toolbarPluginInstance]} 
                    onDocumentLoad={handleDocumentLoad} 
                  />
                </Worker>
              )}

          {currentFile.documentType === 'Recording' && (
            <div>
              {/* Pull-down menu for selecting audio player */}
              <div className="audio-player-selector">
                <select
                  value={showSimpleAudioPlayer ? 'simpleAudioPlayer' : 'motionAudioPlayer'}
                  onChange={(e) => {
                    const selectedPlayer = e.target.value;
                    if (selectedPlayer === 'simpleAudioPlayer') {
                      setShowSimpleAudioPlayer(true);
                      setShowMotionAudioPlayer(false);
                    } else {
                      setShowSimpleAudioPlayer(false);
                      setShowMotionAudioPlayer(true);
                    }
                  }}
                >
                  <option value="simpleAudioPlayer">Simple Audio Player</option>
                 {/*  <option value="motionAudioPlayer">Motion Audio Player</option>*/}
                </select>
              </div>

              {/* Render Simple Audio Player */}
              {showSimpleAudioPlayer && (
                <div className="audio-play-bar">
                  <div className="audio-controls">
                    <button onClick={handleRewind}><FaBackward size={18} /></button>
                    <button
                      onClick={() => {
                        handlePlayAudio(currentFile.url, currentFile.refPath);
                      }}
                    >
                      {isPlaying ? <FaPause size={18} /> : <FaPlay size={18} />}
                    </button>
                    <button onClick={handleFastForward}><FaForward size={18} /></button>
                    <button onClick={handleSpeedChange}>
                      {`${playbackSpeed}x`}
                    </button>
                  </div>
                  <div className="audio-progress-container">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={isNaN(audioProgress) ? 0 : audioProgress}
                      onChange={handleAudioSeek}
                      className="audio-progress-bar"
                    />
                  </div>

                  <div style={{ textAlign: 'center', fontSize: '14px', color: '#555', marginTop: '10px' }}>
                    <span style={{ fontWeight: 'bold' }}>Elapsed:</span>{' '}
                    {new Date((audioRef.current?.currentTime ?? 0) * 1000).toISOString().substr(11, 8)}{' '}
                    <span style={{ fontWeight: 'bold', margin: '0 5px' }}>/</span>{' '}
                    <span style={{ fontWeight: 'bold' }}>Total:</span>{' '}
                    {new Date((metadataDuration ?? 0) * 1000).toISOString().substr(11, 8)}
                  </div>
                </div>
              )}

              {/* Render Motion Audio Player */}
              {showMotionAudioPlayer && (
                <div>
                  <CustomAudioMotionAnalyzer
                    fetchAudioBlob={() => getAudioUrl(currentFile.url, currentFile.refPath)}
                    onAudioContextReady={(analyzerInstance: AudioMotionAnalyzer) => {
                      audioMotionAnalyzerRef.current = analyzerInstance;
                    }}
                    metadataDuration={metadataDuration}
                  />
                </div>
              )}

              <h3 style={{ color: 'white' }}>Transcript</h3>

              {showTextArea && (
              <div className="textarea-container" style={{ position: 'relative' }}>
                <textarea
                  value={textareaContent}
                  onChange={(e) => setTextareaContent(e.target.value)}
                  placeholder="Paste Information Here"
                  className="textarea-input"
                  readOnly={isConverting}
                  ref={textareaRef} // Attach ref to textarea
                />
              </div>
              )}
              {showTranscript && (
              <div style={{ width: '100%', height: '400px', overflow: 'hidden', position: 'relative' }}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer 
                    fileUrl={transcriptUrl} 
                    plugins={[mainZoomPlugin, toolbarPluginInstance]} 
                    onDocumentLoad={handleDocumentLoad} 
                  />
                </Worker>
              </div>

              )}

            </div>
          )}

            {currentFile.documentType === 'YouTube Video' && (
              <div>
                <div className="video-container">
                  <ReactPlayer
                    ref={playerRef}
                    url={currentFile.youtubeUrl || undefined} // Use undefined if no URL
                    playing={Boolean(currentFile.youtubeUrl)}
                    controls
                    className="react-player"
                    width="100%" // Make the player take the full width of the parent
                    height="100%" // Optional: Adjust height as needed
                  />
                </div>
                <h3 style={{ color: 'white' }}>Transcript</h3>

               {showTextArea && (
                <div className="textarea-container" style={{ position: 'relative' }}>
                  <textarea
                    value={textareaContent}
                    onChange={(e) => setTextareaContent(e.target.value)}
                    placeholder="Paste Information Here"
                    className="textarea-input"
                    readOnly={isConverting}
                    ref={textareaRef} // Attach ref to textarea
                  />
                </div>
               )}
               {showTranscript && (
                <div style={{ width: '100%', height: '200px', overflow: 'hidden', position: 'relative' }}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer 
                      fileUrl={transcriptUrl} 
                      plugins={[mainZoomPlugin, toolbarPluginInstance]} 
                      onDocumentLoad={handleDocumentLoad} 
                    />
                  </Worker>
                </div>

               )}


              </div>
            )}

            {currentFile.documentType === 'Subject' && (
              <div className="library-items" style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }}>
                {libraryItems.map((item, index) => (
                  <div key={index} className="library-item" style={{ marginBottom: '10px' }}>
                    <div
                      className="library-card"
                      style={{ cursor: 'pointer', padding: '10px', borderRadius: '5px'}}
                    >
                      <div className="front">
                        <p style={{ color: 'white' }}><strong>Question:</strong> {item.question}</p>
                        {item.options && (
                          <ul style={{ paddingLeft: '20px' }}>
                            {Object.entries(item.options).map(([key, value]) => (
                              <li key={key} style={{ color: 'white' }}>
                                <strong>{key}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        )}
                        {showAnswerIndexes.includes(index) && (
                          <p style={{ marginTop: '10px', color: 'white' }}>
                            <strong>Answer:</strong> {item.answer}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* Button container */}
                    <div style={{ display: 'flex', gap: '10px', marginTop: '10px', marginLeft: '100px' }}>
                      <button 
                        title={showAnswerIndexes.includes(index) ? "Hide Answer" : "See Answer"} 
                        onClick={() => handleToggleAnswer(index)} 
                        className="icon-button"
                      >
                        {showAnswerIndexes.includes(index) ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                      </button>
                      <button title="Delete" onClick={() => handleDeleteItem(index)} className="icon-button">
                        <FaEraser size={20} /> 
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}


        </div>

          <div 
            className="resize-bar" 
            onMouseDown={handleMouseDownOnResizeBar} 
          />

          {isQnaOpen && (
              <div className="chat-window">
                  <div className="chat-header">
                      <h3 style={{ color: 'white' }}>Q&A</h3>                
                      <button
                          className="cancel-chat-button"
                          onClick={() => handleQnaClose()}
                          style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'red' }}
                      >
                          Cancel
                      </button>
                  </div>
                  
                  {/* Integrate Qna component here */}
                  <Qna 
                   currentFile={currentFile}
                   />
              </div>
          )}

          {isSummaryOpen && (
            <div className="chat-window">
            <div className="chat-header">
            <h3 style={{ color: 'white' }}>Summary</h3>

            <button
                className="cancel-chat-button"
                onClick={() => handleSummaryClose()}
                style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'red' }}
              >
                Cancel
            </button>

            </div>

             <div 
              className="pdf-viewer-container" 
              onClick={() => setActiveViewer('summary')}
              ref={summaryPdfViewerRef}
              onMouseDown={handleMouseDown}
              style={{ width: `98%` }} 
              >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                {currentFile?.summaryUrl ? (
                  <Viewer fileUrl={currentFile.summaryUrl} plugins={[summaryZoomPlugin, toolbarPluginInstance]} onDocumentLoad={handleDocumentLoad} />
                ) : (
                  <p style={{ color: 'white', textAlign: 'left', fontSize: '14px' }}>No summmary available. Generate a summary with the "S" button first. Green "S" indicates a summary is available; Red "S" indicates not avaialble.</p>
                )}
              </Worker>
            </div>

            </div>
          )}

          {isChatOpen && (
            <div className="chat-window">
            <div className="chat-header">
            <h3 style={{ color: 'white' }}>Chat</h3>
            <button
                className="cancel-chat-button"
                onClick={() => handleClose(true)}
                style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'red' }}
              >
                Cancel
              </button>
            </div>

            <div className="chat-content">
            {/* Preloaded message */}
            {showPreloadedMessage && (
                <p style={{ color: 'white', textAlign: 'left', fontSize: '14px' }}>
                    You can ask any questions related to the document. You can also select specific texts from the PDF to ask a question, explain the text or provide a summary.
                </p>
            )}

            {assistant?.chat.map((chatMsg, index) => (
                <div 
                key={index} 
                onMouseEnter={() => setHoveredIndex(index)} 
                onMouseLeave={() => setHoveredIndex(null)} 
                style={{ position: 'relative' }} // Position relative for absolute button positioning
                >
                <p
                    style={{
                    color: chatMsg.from === 'user' ? 'white' : 'white',
                    textAlign: chatMsg.from === 'user' ? 'right' : 'left',
                    fontSize: '14px',
                    }}
                >
                    <strong>
                    {chatMsg.from === 'user' 
                        ? firstName || 'You' 
                        : 'Assistant'}:
                    </strong>

                    <div className="assistant-response-container">
                    {formatContent(chatMsg.message)}
                    {chatMsg.from === 'assistant' && hoveredIndex === index && (
                        <div className="pdf-response-buttons" style={{ position: 'absolute', right: '0', bottom: '-30px' }}>
                        <button 
                            onClick={() => readAloud(chatMsg.message)} 
                            title="Read Aloud" 
                            style={{ cursor: 'pointer', marginRight: '5px',color: isPlaying ? 'green' : 'inherit'  }}
                        >
                            {isPlaying ? <FaVolumeUp style={{ color: 'green' }} size={16} /> : <FaVolumeUp size={16} />}
                        </button>
                        <button 
                            onClick={() => handleCopyResponse(index, chatMsg.message)} 
                            title="Copy Response"
                            style={{ cursor: 'pointer' }}
                        >
                            {copiedResponseIndex === index ? <FaClipboardCheck size={16}/> : <FaClipboard size={16} />}
                        </button>
                        </div>
                    )}
                    </div>
                </p>
                </div>

            ))}
                {/* Show thinking indicator after the last user message */}
                  {isAssistantThinking && (
                    <div className="thinking-indicator">
                      <div className={`chat-message assistant`}>
                      <strong>
                         Assistant:
                      </strong>
                      </div>
                      <div className="dot"></div>
                    </div>
                  )}
            {/* Dummy div to ensure scrolling to the bottom */}
            <div ref={dummyDivRef} />
            </div>

              <div className="chat-input-container">
                <label 
                  title="Upload" 
                  htmlFor="file-upload" 
                  style={{ cursor: 'pointer', alignSelf: 'flex-end', color: 'white' }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                  </svg>
                </label>
                <input id="file-upload" type="file" multiple onChange={handleFileUpload} style={{ display: 'none' }} />
                <textarea
                    value={newMessage}
                    onChange={(e) => {
                        setNewMessage(e.target.value);

                        // Reset height to 30px if the input is empty
                        if (e.target.value.trim() === '') {
                            e.target.style.height = '30px';
                        } else {
                            // Dynamically adjust the height based on content
                            e.target.style.height = 'auto'; // Reset the height to calculate the scrollHeight properly
                            e.target.style.height = `${Math.min(e.target.scrollHeight, 200)}px`; // Cap the height at 200px
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                        }
                    }}
                    placeholder="Type a message"
                    className="chat-input"
                    rows={1}
                    disabled={isSendDisabled} // Disable sending until assistant is ready
                    style={{ height: newMessage ? 'auto' : '30px' }} // Reset the height to 30px when empty
                />

                <button
                  className="chat-send-button"
                  onClick={() => (loading ? setLoading(false) : handleSendMessage())}
                  aria-label={loading ? 'Stop streaming' : 'Send message'}
                  disabled={isSendDisabled} // Disable sending until assistant is ready
                >
                  {loading ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                      <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" fill="none" />
                      <rect x="6" y="6" width="4" height="4" fill="currentColor" />
                    </svg>
                  ) : (
                    <FaPaperPlane size={20} />
                  )}
                </button>
              </div>


            </div>
          )}
          {/* Show action buttons near the selected text */}
          {selectedText && selectionWithinPDF && selectionPosition && isChatOpen && (
            <div
              style={{
                position: 'absolute',
                top: `${selectionPosition.top}px`,
                left: `${selectionPosition.left}px`,
                zIndex: 1000,
                backgroundColor: 'transparent',
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              <button
                className="pdf-text-button"
                onClick={() => handleAction('question')}
              >
                Question
              </button>
              <button
                className="pdf-text-button"
                onClick={() => handleAction('explain')}
              >
                Explain
              </button>
              <button
                className="pdf-text-button"
                onClick={() => handleAction('summary')}
              >
                Summary
              </button>
            </div>
          )}
            <audio
            ref={audioRef} // Reference to control the audio element
            onTimeUpdate={handleAudioProgress} // Update the progress bar as the audio plays
            onLoadedMetadata={handleMetadataLoaded} // Reset progress bar when metadata (like duration) is loaded
            onEnded={() => setPlayingAudio(null)} // Reset when audio finishes
            />

        </div>
      </div>
    </div>
  );
};

export default PdfViewerModal;
