import React, { useState, useEffect,useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { signOut, onAuthStateChanged} from 'firebase/auth';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import { FaUserPlus, FaUserMinus, FaUser, FaPaperPlane, FaCopy, FaCheck, FaPaintBrush,FaVolumeUp, FaClipboardCheck, FaClipboard} from 'react-icons/fa'; // Import icons
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import './Base.css';
import OpenAI from "openai";
import { EventEmitter } from 'events';
import { getDoc,doc, updateDoc, arrayUnion, getFirestore} from 'firebase/firestore';
import ProfessionalCreationWindow from './ProfessionalCreationWindow';
import { handleRequiresAction } from './ProfessionalCreationWindow';
//import CaddMain from './caddDesigner/caddMain'; 
import EngineerMain from './bridgeEngineer/engineerMain'; 


// OpenAI API setup
const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
  })

  interface ChatMessage {
    message: string;
    from: 'user' | 'assistant';
    type?: 'text' | 'image'; // Optional property for different message types
  }
  

interface Assistant {
    name: string;
    professional:string;
    id: string;
    chat: ChatMessage[];
    virtualId: string;
    instructions?: string;
    model:string,
    tool:string;
    vectorStore:string; 
    threadID: string; 
  }

type AssistantTool =
  | { type: 'code_interpreter'; languages?: string[] }  // Making 'languages' optional
  | { type: 'file_search' }
  | { type: 'function'; function: FunctionDefinition };

  type FunctionParameters = {
    type: "object";
    properties: Record<string, { type: string; description: string; enum?: string[] }>;
    required: string[];
  };

  interface FunctionDefinition {
    name: string;
    description: string;
    parameters: FunctionParameters;
  }

  interface Event {
    event: string;
    data: any; // Adjust 'any' to a more specific type if you know the structure of 'data'
  }
  
  interface ToolCall {
    id: string;
    function: {
      name: string;
      parameters?: {
        type: string; // Adjust the type as needed
        properties: {
          location: {
            type: string;
            description: string;
          };
          // Add other parameters as needed
        };
        required?: string[];
      };
    };
  }
  
  interface RequiresActionData {
    required_action: {
      submit_tool_outputs: {
        tool_calls: ToolCall[];
      };
    };
  }
  
  class EventHandler extends EventEmitter {
    client: OpenAI;
    setAssistants: React.Dispatch<React.SetStateAction<Assistant[]>>;
    currentAssistantIndex: number;

    constructor(client: OpenAI, setAssistants: React.Dispatch<React.SetStateAction<Assistant[]>>, currentAssistantIndex: number) {
        super();
        this.client = client;
        this.setAssistants = setAssistants;
        this.currentAssistantIndex = currentAssistantIndex;
    }

    async onEvent(event: Event) {
        try {
            // Handle events that require action
            if (event.event === "thread.run.requires_action") {
                await handleRequiresAction(
                    event.data as RequiresActionData,
                    event.data.id,
                    event.data.thread_id,
                    this.submitToolOutputs.bind(this),
                    this.setAssistants,
                    this.currentAssistantIndex
                );
            }
        } catch (error) {
            console.error("Error handling event:", error);
        }
    }

    async submitToolOutputs(toolOutputs: any[], runId: string, threadId: string) {
        try {
            const stream = this.client.beta.threads.runs.submitToolOutputsStream(
                threadId,
                runId,
                { tool_outputs: toolOutputs },
            );
            for await (const event of stream) {
                this.emit("event", event);
            }
        } catch (error) {
            console.error("Error submitting tool outputs:", error);
        }
    }
}
  

const Chat: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [currentAssistantIndex, setCurrentAssistantIndex] = useState<number | null>(null);
  const [newMessage, setNewMessage] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // Track hover state
  const [showAssistantWindow, setShowAssistantWindow] = useState(false); // To toggle assistant creation window
  const [assistantName, setAssistantName] = useState(''); // Assistant name
  const [assistantInstructions, setAssistantInstructions] = useState(''); // Assistant instructions
  const [assistantTool] = useState('file_search');
  const [showUpdateAssistantWindow, setShowUpdateAssistantWindow] = useState(false);
  const [isRemoveAssistantOpen, setIsRemoveAssistantOpen] = useState(false); // New state for remove assistant window
  const [selectedAssistants, setSelectedAssistants] = useState<string[]>([]); // Store selected assistants for removal
  const [isFullScreen, setIsFullScreen] = useState(false); // Track full-screen mode
  const [copiedKey, setCopiedKey] = useState<number | null>(null); // Track which code snippet is copied
  const [imagePreviewUrls, setImagePreviewUrls] = useState<string[]>([]); 
  const [loading, setLoading] = useState(false);
  const [showAddAssistantOptionWindow, setShowAddAssistantOptionWindow] = useState(false); 
  const [selectedProfessional, setSelectedProfessional] = useState(''); 
  const [showProfessionalCreationWindow, setShowProfessionalCreationWindow] = useState(false); 
  const [showProfessionalSelectionWindow, setShowProfessionalSelectionWindow] = useState(false); 
  const [plan, setPlan] = useState<string | null>(null); 
  const [isCanvasVisible, setIsCanvasVisible] = useState(false); 
  const selectedAssistant = currentAssistantIndex !== null ? assistants[currentAssistantIndex] : null;
  const isProfessionalAssistant = selectedAssistant && 
      (selectedAssistant.name === 'CADD Designer' || selectedAssistant.name === 'Bridge Engineer');
  const [isPlaying, setIsPlaying] = useState(false); // Track if audio is playing
  const [firstName, setFirstName] = useState<string | null>(null); 
  const [copiedResponseIndex, setCopiedResponseIndex] = useState<number | null>(null); // Track copied response index
  const [isAssistantThinking, setIsAssistantThinking] = useState(false); 
  const [audioInstance, setAudioInstance] = useState<SpeechSynthesisUtterance | null>(null); 
  const user = auth.currentUser;

  // Reference for the chat messages container
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  // Scroll to the bottom of the chat container
  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    // Automatically scroll to the bottom when chat updates
    scrollToBottom();
  }, [assistants, currentAssistantIndex]);

  const handleCopy = (key: number) => {
    setCopiedKey(key);
    setTimeout(() => {
      setCopiedKey(null); // Reset after 2 seconds
    }, 2000);
  };
  
  const db = getFirestore();

  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const fetchUserPlan = useCallback(async (uid: string) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subscriptionStatus = userData?.subscriptionStatus;
        const chatServiceOrdered = userData?.chatServiceOrdered;
        const civilProOrdered = userData?.civilProOrdered;

        setFirstName(userData?.firstName || null); // Set firstName for personalized responses

        // Determine user plan based on Firestore data
        let planType = 'Starter';
        if (subscriptionStatus && !chatServiceOrdered) planType = 'Premium';
        else if (!subscriptionStatus && chatServiceOrdered) planType = 'Chat';
        else if (subscriptionStatus && chatServiceOrdered && !civilProOrdered) planType = 'PremiumChat';
        else if (subscriptionStatus && civilProOrdered) planType = 'civilProChat';

        setPlan(planType); // Set the user plan
      } else {
        console.warn("User document does not exist.");
      }
    } catch (error) {
      console.error("Error fetching user plan:", error);
    }
  }, [db]);

  const fetchAssistantsFromFirestore = useCallback(async (uid: string) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        if (userData.assistants) {
          const fetchedAssistants = userData.assistants.map((assistant: any) => ({
            name: assistant.name,
            id: assistant.id,
            chat: assistant.lastChatMessages || [], // Load last chat messages
            virtualId: assistant.name.charAt(0).toUpperCase(),
            instructions: assistant.instructions,
            tool: assistant.tool,
            model: assistant.model,
            vectorStore: assistant.vectorStoreId,
            threadID: assistant.threadID,
          }));

          setAssistants(fetchedAssistants);
          console.log('Fetched assistants:', fetchedAssistants);
        }

        if (userData.currentAssistantIndex !== undefined) {
          setCurrentAssistantIndex(userData.currentAssistantIndex);
          console.log('Fetched currentAssistantIndex:', userData.currentAssistantIndex);
        }
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching assistants:", error);
    }
  }, [db]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        fetchUserPlan(user.uid); // Fetch user plan when authenticated
        fetchAssistantsFromFirestore(user.uid); // Fetch assistants when authenticated
      } else {
        console.warn("No user authenticated, data fetching skipped.");
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [fetchUserPlan,fetchAssistantsFromFirestore]);


  useEffect(() => {
    const fetchUserPlan = async () => {
      if (user && !user.isAnonymous) {
        const userPlan = await getUserPlan();
        setPlan(userPlan);
      }
    };

    fetchUserPlan();
  }, [user]);

  const getUserPlan = async (): Promise<string> => {
    const currentUser = auth.currentUser;
    if (!currentUser) return 'Starter'; 
  
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);
    const userDoc = await getDoc(userDocRef);
  
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const subscriptionStatus = userData?.subscriptionStatus;
      const chatServiceOrdered = userData?.chatServiceOrdered;
      const civilProOrdered = userData?.civilProOrdered;
      setFirstName(userData?.firstName || null);
      if (subscriptionStatus === true && chatServiceOrdered !== true) {
        return 'Premium';
      }
      if (subscriptionStatus !== true && chatServiceOrdered === true) {
        return 'Chat';
      }
      if (subscriptionStatus === true && chatServiceOrdered === true && civilProOrdered !== true) {
        return 'PremiumChat';
      }
      if (subscriptionStatus === true && civilProOrdered === true) {
        return 'civilProChat';
      }      
    }
    return 'Starter';
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  
    // Fetch assistants and currentAssistantIndex from Firestore when the component loads
    const fetchAssistantsFromFirestore = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
  
          // Fetch assistants
          if (userData.assistants) {
            const fetchedAssistants = userData.assistants.map((assistant: any) => ({
              name: assistant.name,
              id: assistant.id,
              chat: assistant.lastChatMessages || [], // Fetch the last set of chat messages
              virtualId: assistant.name.charAt(0).toUpperCase(),
              instructions: assistant.instructions,
              tool: assistant.tool,
              model: assistant.model,
              vectorStore: assistant.vectorStoreId,
              threadID: assistant.threadID,
            }));
  
            setAssistants(fetchedAssistants);
            console.log('Fetched assistants:', fetchedAssistants);
          }
  
          // Fetch currentAssistantIndex
          if (userData.currentAssistantIndex !== undefined) {
            setCurrentAssistantIndex(userData.currentAssistantIndex);
            console.log('Fetched currentAssistantIndex:', userData.currentAssistantIndex);
          } else {
            console.log('No currentAssistantIndex found.');
          }
        } else {
          console.error('No such document!');
        }
      }
    };
  
    fetchAssistantsFromFirestore();
  
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [db]);
  
  

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadBio: string) => {
    setUserImage(loadedImageUrl);
  };

  const handleAddAssistant = () => {
    setShowAddAssistantOptionWindow(true); // Open add assistant options window
  };

  const handleSelectProfessional = (professional: string) => {
    setSelectedProfessional(professional); // Set selected professional
  };

  const handleProfessionalAssistantAdd = () => {
    setAssistantName(selectedProfessional); // Set the professional assistant's name
    setShowProfessionalCreationWindow(true); // Open professional creation window
  };

  const closeAddAssistantOptionWindow = () => {
    setShowAddAssistantOptionWindow(false); // Close the add assistant options window
  };

  const closeProfessionalSelectionWindow = () => {
    setShowProfessionalSelectionWindow(false); // Close professional creation window
  };

  const newChat = async () => {
    if (currentAssistantIndex === null) {
      alert("No assistant selected to start a new chat.");
      return;
    }
  
    const assistant = assistants[currentAssistantIndex];
  
    try {
      // Reset the chat in the state
      const updatedAssistants = assistants.map((asst, index) => {
        if (index === currentAssistantIndex) {
          return { ...asst, chat: [] };
        }
        return asst;
      });
  
      setAssistants(updatedAssistants);
  
      // Clear lastChatMessages in Firestore for the selected assistant
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const assistantsData = userData.assistants;
  
          const updatedAssistantsData = assistantsData.map((asst: any) => {
            if (asst.id === assistant.id) {
              return { ...asst, lastChatMessages: [] }; // Clear chat messages in Firestore
            }
            return asst;
          });
  
          await updateDoc(userDocRef, { assistants: updatedAssistantsData });
          console.log("Chat messages cleared in Firestore for assistant ID:", assistant.id);
        }
      }
    } catch (error) {
      console.error("Error clearing chat:", error);
      alert("Failed to clear the chat. Please try again.");
    }
  };
  
  const handleCreateAssistant = async () => {
    // Ensure assistantName is always a valid string
    const safeAssistantName = assistantName?.trim() || `Assistant ${assistants.length + 1}`;
  
    if (!safeAssistantName || !assistantInstructions) {
      alert('Please fill in all required fields.');
      return;
    }
  
    try {
      console.log('Creating Assistant with:', { assistantName: safeAssistantName, assistantInstructions });
  
      let tools: AssistantTool[];
      if (assistantTool === 'function') {
        tools = [{
          type: "function",
          function: {
            name: "exampleFunction",
            description: "Describe the function here",
            parameters: {
              type: "object",
              properties: {
                exampleParam: {
                  type: "string",
                  description: "An example parameter",
                },
              },
              required: ["exampleParam"],
            },
          },
        }];
      } else if (assistantTool === 'code_interpreter') {
        tools = [{ type: "code_interpreter" }];
      } else {
        tools = [{ type: "file_search" }];
      }
  
      // Call OpenAI API to create the assistant
      const response = await openai.beta.assistants.create({
        name: safeAssistantName,
        instructions: assistantInstructions,
        model: "gpt-4o-mini",
        tools: [{ type: "file_search" }],
        metadata: { username: 'your-username' },
      });


      console.log('Assistant created:', response);
  
      let vector_store = '';  
      if (tools[0].type === 'file_search') {
        const vectorStore = await openai.beta.vectorStores.create({
          name: safeAssistantName + '_vectorStore',
        });
  
        console.log('Vector store created:', vectorStore);
  
        await openai.beta.assistants.update(response.id, {
          tool_resources: { file_search: { vector_store_ids: [vectorStore.id] } },
        });
  
        vector_store = vectorStore.id;
      }
  
      // No longer saving virtualId to Firestore, we calculate it from the first letter of the name
      const virtualId = safeAssistantName.charAt(0).toUpperCase();

        // Create a new thread
        const threadResponse = await openai.beta.threads.create();
        // threadResponse will contain the ID of the created thread
        const threadID = threadResponse.id;

      // Add the new assistant to the state
      const newAssistant: Assistant = {
        name: safeAssistantName,
        id: response.id, // Use numeric value for id
        chat: [],
        virtualId, // Assign virtualId based on the first letter of the name
        tool:'file_search',
        model:'gpt-4o-mini',
        professional:selectedProfessional,
        vectorStore:vector_store,
        threadID: threadID, 
      };
  
      // Save the assistant information to Firestore under the current user profile
      const user = auth.currentUser;
      const db = getFirestore();
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
  
        // Create the assistant information JSON object
        const assistantData = {
          name: safeAssistantName,
          id: newAssistant.id,
          instructions: assistantInstructions,
          model: 'gpt-4o-mini',
          vectorStoreId: vector_store,
          tool: tools[0].type,
          threadID: threadID, 
        };
  
        // Add the new assistant data to the "assistants" array in Firestore
        await updateDoc(userDocRef, {
          assistants: arrayUnion(assistantData),
        });
  
        console.log('Assistant information saved to Firestore.');
      } else {
        console.error('No authenticated user found.');
      }
  
      setAssistants([...assistants, newAssistant]);
      setShowAssistantWindow(false); // Close the assistant creation window
      setAssistantName(''); // Reset input fields
      setAssistantInstructions('');
  
      alert('Assistant created and saved successfully.');
    } catch (error) {
      console.error('Failed to create assistant:', error);
      alert('Failed to create the assistant. Please try again.');
    }
  };
  
  const closeAssistantWindow = () => {
    setShowAssistantWindow(false); // Close the window without creating an assistant
  };

  const handleUpdateAssistant = async () => {
    if (currentAssistantIndex === null) {
      alert("No assistant selected for updating.");
      return;
    }
  
    // Ensure assistantName is always a valid string
    const safeAssistantName = assistantName?.trim() || `Assistant ${assistants[currentAssistantIndex]?.name}`;
  
    if (!safeAssistantName || !assistantInstructions) {
      alert('Please fill in all required fields.');
      return;
    }
  
    try {
      console.log('Updating Assistant with:', { assistantName: safeAssistantName, assistantInstructions });
  
      let tools: AssistantTool[];
      if (assistantTool === 'function') {
        tools = [{
          type: "function",
          function: {
            name: "exampleFunction",
            description: "Describe the function here",
            parameters: {
              type: "object",
              properties: {
                exampleParam: {
                  type: "string",
                  description: "An example parameter",
                },
              },
              required: ["exampleParam"],
            },
          },
        }];
      } else if (assistantTool === 'code_interpreter') {
        tools = [{ type: "code_interpreter" }];
      } else {
        tools = [{ type: "file_search" }];
      }
  
      const currentAssistantId = assistants[currentAssistantIndex]?.id.toString(); // Convert number to string
  
      // Call OpenAI API to update the assistant
      const updatedAssistant = await openai.beta.assistants.update(currentAssistantId, {
        name: safeAssistantName,
        instructions: assistantInstructions,
        model: 'gpt-4o-mini', // Specify the model (adjust as per requirements)
        tools,
      });
  
      console.log('Assistant updated:', updatedAssistant);
  
      // Calculate the new virtualId based on the updated name
      const newVirtualId = safeAssistantName.charAt(0).toUpperCase();
  
      // Save the updated assistant information to Firestore
      const user = auth.currentUser;
      const db = getFirestore();
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        let updatedAssistants = [];
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const existingAssistants = userData.assistants || [];
  
          // Check if an assistant with the same id already exists
          const assistantExists = existingAssistants.some((assistant: any) => assistant.id === currentAssistantId);
  
          if (assistantExists) {
            // Replace the existing assistant's information
            updatedAssistants = existingAssistants.map((assistant: any) =>
              assistant.id === currentAssistantId
                ? {
                    ...assistant,
                    name: safeAssistantName,
                    instructions: assistantInstructions,
                    model: 'gpt-4o',
                    tool: tools[0].type,
                  }
                : assistant
            );
          } else {
            // Add the new assistant data to the list
            updatedAssistants = [...existingAssistants, {
              name: safeAssistantName,
              id: currentAssistantId,
              instructions: assistantInstructions,
              model: 'gpt-4o',
              tool: tools[0].type,
            }];
          }
        }
  
        // Update the assistants array in Firestore
        await updateDoc(userDocRef, {
          assistants: updatedAssistants,
        });
  
        console.log('Assistant information updated in Firestore.');
  
        // Update the assistant in the state
        setAssistants(prevAssistants =>
          prevAssistants.map((assistant, index) =>
            index === currentAssistantIndex
              ? { ...assistant, name: safeAssistantName, instructions: assistantInstructions, virtualId: newVirtualId }
              : assistant
          )
        );
  
        setShowUpdateAssistantWindow(false); // Close the update window
        setAssistantName(''); // Reset input fields
        setAssistantInstructions('');
  
        alert('Assistant updated successfully.');
      } else {
        console.error('No authenticated user found.');
      }
    } catch (error) {
      console.error('Failed to update assistant:', error);
      alert('Failed to update the assistant. Please try again.');
    }
  };

// Handle the logic to remove selected assistants
const handleRemoveSelectedAssistants = async () => {
    if (selectedAssistants.length === 0) return;
  
    // Show a warning message before proceeding with removal
    const confirmation = window.confirm(
      `Are you sure you want to remove the selected assistants? This action cannot be undone.`
    );
  
    if (!confirmation) {
      // If the user cancels, exit the function
      console.log('User canceled the removal.');
      return;
    }
  
    try {
      for (const assistantId of selectedAssistants) {
        const assistant = assistants.find(asst => asst.id === assistantId);
        if (!assistant) continue;
  
        // Delete assistant from OpenAI
        await openai.beta.assistants.del(assistantId);
        console.log(`Deleted assistant ${assistant.name} from OpenAI`);
  
        // If the assistant has a file search tool, delete associated vector store files
        if (assistant.tool === 'file_search') {
          const deletedVectorStore = await openai.beta.vectorStores.del(assistant.vectorStore);
          console.log(`Deleted vector store for assistant ${assistant.name}`, deletedVectorStore);
        }
  
        // Remove assistant from Firestore
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const updatedAssistants = userData.assistants.filter((asst: any) => asst.id !== assistantId);
            await updateDoc(userDocRef, { assistants: updatedAssistants });
            console.log(`Removed assistant ${assistant.name} from Firestore`);
          }
        }
      }
  
      // Update state to remove assistants from UI
      setAssistants(prevAssistants => prevAssistants.filter(asst => !selectedAssistants.includes(asst.id)));
      setSelectedAssistants([]); // Clear selection
      setIsRemoveAssistantOpen(false); // Close the modal
  
      alert('Selected assistants removed successfully.');
    } catch (error) {
      console.error('Failed to remove assistants:', error);
      alert('Failed to remove assistants. Please try again.');
    }
  };
  
  // Handle toggling assistant selection
  const handleToggleAssistantSelection = (assistantId: string) => {
    setSelectedAssistants(prevSelected =>
      prevSelected.includes(assistantId)
        ? prevSelected.filter(id => id !== assistantId) // Unselect assistant
        : [...prevSelected, assistantId] // Select assistant
    );
  };

  const selectAssistant = async (index: number) => {
    setCurrentAssistantIndex(index); // Set the selected assistant index in state
  
    try {
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          // Overwrite the currentAssistantIndex in Firestore
          await updateDoc(userDocRef, { currentAssistantIndex: index });
          console.log('currentAssistantIndex updated in Firestore:', index);
        } else {
          console.error('User document does not exist.');
        }
      } else {
        console.error('No authenticated user found.');
      }
    } catch (error) {
      console.error('Error updating currentAssistantIndex in Firestore:', error);
    }
  };
  
  const handleAssistantClick = (index: number) => {
    let clickTimeout: NodeJS.Timeout;
  
    const singleClick = () => {
      selectAssistant(index); // Handle single click by selecting the assistant
    };
  
    const doubleClick = () => {
      selectAssistant(index);
      const selectedAssistant = assistants[index];
      if (selectedAssistant) {
        setAssistantName(selectedAssistant.name);
        setAssistantInstructions(selectedAssistant.instructions || ''); // Correctly set instructions as default text
        setShowUpdateAssistantWindow(true); // Show the update window
      }
    };
  
    return (e: React.MouseEvent) => {
      if (e.detail === 1) {
        clickTimeout = setTimeout(singleClick, 200); // Single click handler after a delay
      } else if (e.detail === 2) {
        clearTimeout(clickTimeout); // Cancel single-click action on double-click
        doubleClick(); // Handle double-click
      }
    };
  };
  
  const stopStreaming = () => {
    setLoading(false); // Stop streaming
  };

  const uploadFileToOpenAI = async (file: File) => {
    const response = await openai.files.create({
        file,
        purpose: 'assistants',
      });
    return response.id;
  };

// Function to add a message and multiple images to a thread
  const  addAssistantImageMessage = async (threadId: string, message: string, fileIds: string[]): Promise<any> => {
        console.log('Adding a new message and images to thread: ' + threadId);
  
        // Construct the content array with the message text and image files
        const content = [
          { type: "text", text: message } as const,
          ...fileIds.map(fileId => ({
            type: "image_file",
            image_file: { file_id: fileId }
          } as const))
        ];
  
        // Create the message in the thread
        const response = await openai.beta.threads.messages.create(
          threadId,
          {
            role: "user",
            content: content
          }
        );
  
        return response;
      }
  
  const processAssistantResponse = async (assistant: Assistant, updatedChat: ChatMessage[]) => {
    try {
      if (!assistant.threadID) {
        console.error('No thread ID for this assistant.');
        return;
      }
  
      const client = new OpenAI({ 
        apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
        dangerouslyAllowBrowser: true,
      });
      const threadId = assistant.threadID;
        // Pass the necessary parameters to the EventHandler
        const eventHandler = new EventHandler(client, setAssistants, currentAssistantIndex!); // Make sure to use the non-null assertion operator if currentAssistantIndex could be null

        eventHandler.on("event", eventHandler.onEvent.bind(eventHandler));

        try {
          // Start the stream
          const stream = await openai.beta.threads.runs.stream(threadId, {
            assistant_id: assistant.id,
          });
  
          let partialMessage = ""; // To build the message incrementally
          const newUpdatedChat = updatedChat;

          let lastMessageIndex = -1; // Keep track of the last message index
          
          // Listen for events from the stream
          for await (const event of stream) {
            eventHandler.emit("event", event); // Emit each event to your EventHandler instance
            //console.log('event')
            // Type assertion to access the 'delta' property
            if ((event as any).data.delta?.content?.length > 0) {
              const chunk = (event as any).data.delta.content[0].text.value;
              partialMessage += chunk; // Add the chunk to the partial message
  
              // Find the last assistant message if not already located
              if (lastMessageIndex === -1) {
                lastMessageIndex = newUpdatedChat.findIndex(
                  (msg) => msg.from === 'assistant' && !msg.message.trim()
                );
              }

              if (lastMessageIndex !== -1) {
                newUpdatedChat[lastMessageIndex].message = partialMessage;

              } else {
                newUpdatedChat.push({ message: partialMessage, from: 'assistant', type: 'text' });
                lastMessageIndex = newUpdatedChat.length - 1; // Set the new index
              }
  
              // Update the chat state with the new partial message
              setAssistants((prevAssistants) => {
                const newAssistants = [...prevAssistants];
                newAssistants[currentAssistantIndex!] = {
                  ...newAssistants[currentAssistantIndex!],
                  chat: newUpdatedChat,
                };
                return newAssistants;
              });
            }
          }
  
          // Once the streaming is complete, save the final message to Firestore
          await saveChatToFirestore(assistant.id, updatedChat);
          setLoading(false); // Set loading to false after completion
        } catch (error) {
          console.error("Error handling stream:", error);
        }
      
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
      setLoading(false); // Ensure loading is reset in case of error
    }
  };
  
  
// Helper function to save chat to Firestore (limit to 10 messages)
const saveChatToFirestore = async (assistantId: string, chat: ChatMessage[]) => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const assistantsData = userData.assistants;
  
        // Limit the chat to the last 10 messages
        const limitedChat = chat.slice(-10);
  
        // Update the specific assistant's lastChatMessages with a max of 10 messages
        const updatedAssistants = assistantsData.map((asst: any) => {
          if (asst.id === assistantId) {
            return { ...asst, lastChatMessages: limitedChat };
          }
          return asst;
        });
  
        await updateDoc(userDocRef, { assistants: updatedAssistants });
        console.log('Last 10 chat messages updated in Firestore for assistant ID:', assistantId);
      }
    }
  };
  

  async function cancelRun(threadId: string, runId: string) {
    try {
      // Call the OpenAI API to cancel the run
      const response = await openai.beta.threads.runs.cancel(threadId, runId);
  
      // If the cancel operation is successful, log the response
      console.log('Run cancelled successfully:', response);
    } catch (error) {
      // Handle errors during the cancel operation
      console.error('Error cancelling run:', error);
      throw new Error('Failed to cancel the run');
    }
  }
  

  const handleSendMessage = async () => {

   // Example usage
   cancelRun('thread_eKJrQY4x3vcmQEfna167nw5Y', 'run_SY2ryz9vcRnTjd8hoKcsB6KV').catch(console.error);
   setIsAssistantThinking(true);

    if (newMessage.trim() === '' && uploadedFiles.length === 0) return; // Ensure there's either text or an uploaded image

    const assistant = assistants[currentAssistantIndex!];
    let updatedChat: ChatMessage[] = [...assistant.chat];

    let fileQuestion = newMessage;

    // Upload all files and get their URLs and IDs
    const filePromises = uploadedFiles.map(async (file) => {
    const fileUrl = URL.createObjectURL(file);
    console.log("Uploaded file for GPT-4 Vision:", file);

    // Create image tags for the prompt
    fileQuestion = `<img src="${fileUrl}" alt="Uploaded file" style="max-width:100%; height: auto;">\n\n` + fileQuestion;

    // Upload file to OpenAI
    const uploadedFileId = await uploadFileToOpenAI(file);
    return { fileUrl, fileId: uploadedFileId };
    });   

    if (uploadedFiles && uploadedFiles.length > 0) {  
        try {
            const fileData = await Promise.all(filePromises);
            const fileIds = fileData.map(file => file.fileId);

            // Include any text message from the user
            if (fileQuestion.trim() !== '') {
              updatedChat.push({ message: fileQuestion, from: 'user', type: 'text' });
            }

            // Save user's message and uploaded files to Firestore before the assistant responds
            await saveChatToFirestore(assistant.id, updatedChat);
      
            // Update chat state with all messages
            setAssistants((prevAssistants) => {
              const updatedAssistants = [...prevAssistants];
              updatedAssistants[currentAssistantIndex!] = {
                ...updatedAssistants[currentAssistantIndex!],
                chat: updatedChat,
              };
              return updatedAssistants;
            });
      
            setLoading(true); // Set loading to true when model starts running/streaming
      
            // Add a message to the thread with the uploaded file IDs
            const messageResponse = await addAssistantImageMessage(assistant.threadID, fileQuestion, fileIds);
            console.log("Added a message to the thread", messageResponse);
  
            // Process assistant's response after user's message
            await processAssistantResponse(assistant, updatedChat);
            setIsAssistantThinking(false);
      
          } catch (error) {
            console.error('Error uploading files:', error);
            alert('File upload failed. Please try again.');
            return;
          } 

      } else {
        try {
            // Include any text message from the user
            if (newMessage.trim() !== '') {
              updatedChat.push({ message: newMessage, from: 'user', type: 'text' });
            }
      
            // Save user's message and uploaded files to Firestore before the assistant responds
            await saveChatToFirestore(assistant.id, updatedChat);
      
            // Update chat state with all messages
            setAssistants((prevAssistants) => {
              const updatedAssistants = [...prevAssistants];
              updatedAssistants[currentAssistantIndex!] = {
                ...updatedAssistants[currentAssistantIndex!],
                chat: updatedChat,
              };
              return updatedAssistants;
            });
      
            setLoading(true); // Set loading to true when model starts running/streaming

            const content: any[] = [];
            
            // Add the user's text message to the assistant's content
            if (newMessage.trim() !== '') {
                content.push({
                type: 'text',
                text: newMessage,
                });
            }
        
            // Check if the assistant has a thread ID
            if (!assistant.threadID) {
                console.error('No thread ID for this assistant.');
                return;
            }

            const threadId = assistant.threadID;
        
            // Send the message and uploaded images to the assistant
            await openai.beta.threads.messages.create(threadId, {
                role: 'user',
                content: content,
            });
        
            // Process assistant's response after user's message
            await processAssistantResponse(assistant, updatedChat);
            setIsAssistantThinking(false);
      
          } catch (error) {
            console.error('Error uploading files:', error);
            alert('File upload failed. Please try again.');
            setLoading(false); // Ensure loading is reset in case of error
            setIsAssistantThinking(false); 
         }
   }
   setNewMessage(''); // Clear the input after sending
   setUploadedFiles([]); 
   setImagePreviewUrls([]); 
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const filesArray = Array.from(e.target.files); // Convert FileList to an array
      const newFiles = filesArray.map((file) => URL.createObjectURL(file)); // Create URLs for each file
  
      setUploadedFiles((prevFiles) => [...prevFiles, ...filesArray]); // Store multiple files
      setImagePreviewUrls((prevUrls) => [...prevUrls, ...newFiles]);  // Store multiple image URLs for preview
    }
  };


// Codes for formatting outputs
  const splitIntoSegments = (text: string): string[] => {
    return text.split(/\n\n/).map(segment => segment.trim()).filter(segment => segment);
  };
  
  const formatCodeSnippet = (
    segment: string, 
    key: number, 
    copiedKey: number | null, 
    handleCopy: (key: number) => void
  ): JSX.Element => {
    const [, lang = '', code = ''] = segment.match(/^```(.*?)\n([\s\S]*?)```$/) || [];
    
    const header = (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#001f3f', padding: '5px 10px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
        <span>{lang.trim()}</span>
        <button 
          onClick={() => handleCopy(key)} 
          style={{ backgroundColor: 'transparent', color: 'white', border: 'none', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          {copiedKey === key ? <FaCheck /> : <FaCopy />}
          <span style={{ marginLeft: '5px' }}>
            {copiedKey === key ? 'Copied' : 'Copy'}
          </span>
        </button>
      </div>
    );
  
    return (
      <div key={`code-container-${key}`} style={{ backgroundColor: '#002366', padding: '10px', borderRadius: '5px', width: '90%', margin: '10px auto', color: 'white', wordBreak: 'break-word', overflowX: 'auto' }}>
        {header}
        <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{code.trim()}</pre>
      </div>
    );
  };
  
  
  const isCodeSnippet = (segment: string): boolean => {
    return segment.startsWith('```') || segment.endsWith('```');
  };
  
  const isImage = (segment: string): boolean => {
    return segment.startsWith('<img src="') && segment.endsWith('">');
  };
  
  const isAudio = (segment: string): boolean => {
    return segment.startsWith('<audio') && segment.endsWith('</audio>');
  };
  
  const extractListNumber = (segment: string | undefined): number | undefined => {
    if (!segment) {
      return undefined;
    }
  
    // Extracts the starting number from a segment that starts with a number followed by a period (e.g., "1. ", "2. ")
    const match = segment.match(/^(\d+)\.\s+/);
    return match ? parseInt(match[1], 10) : undefined;
  };
  
  const isListInSegment = (segment: string | undefined): boolean => {
    if (!segment) {
      return false;
    }
  
    // Match segments that contain multiple numbered list items (e.g., "1. First\n2. Second")
    const matches = segment.match(/(\d+\.\s+)/g);
    return matches !== null && matches.length > 1;
  };

  const formatList = (segments: string[], key: number): JSX.Element => {
    // Custom function to split on "- " only outside of \[ ... \]
    const splitOutsideBrackets = (text: string) => {
      const regex = /- (?![^[]*])/g; // Match "- " only if not within [ ... ]
      const parts = [];
      let lastIndex = 0;
      let match;
    
      while ((match = regex.exec(text)) !== null) {
        parts.push(text.slice(lastIndex, match.index)); // Add text before "- "
        lastIndex = match.index + 2; // Move past "- "
      }
      parts.push(text.slice(lastIndex)); // Add remaining text
    
      return parts;
    };
    
    // Format the collected list segments as a list
    const listItems = segments.map((segment, idx) => {
      // Use custom split function to create sub-list items
      const subListItems = splitOutsideBrackets(segment).map((subItem, subIdx) => {
           //console.log('subItem', subItem);
  
           // Custom function to process text for emphasis and additional formatting
           const renderEmphasizedText = (text: string) => {
            const parts = [];
            let lastIndex = 0;
            const regex = /\*\*(.*?)\*\*:?/g;
            let match;
            
            //console.log('inside text',text)

            while ((match = regex.exec(text)) !== null) {
              const start = match.index;
              const end = regex.lastIndex;
    
              // Add text before the **...**
              if (lastIndex < start) {
                const segment = text.slice(lastIndex, start);
                //console.log('segment',segment)
                const hasMathOrVariable = /\\\(|\\\[|\\text|_|\^|\\frac|\\sqrt|\\int|\\sum|\\prod|\\theta|\\pi/.test(segment);
                parts.push(
                  <span key={`span-${lastIndex}`}>
                      {hasMathOrVariable ? formatVariablesAndEquations(segment) : segment}
                  </span>
                );
              }
              const segment1 = match[1];
              const includesColon = text[end - 1] === ':'; // Check if the match is followed by a colon
              
              //console.log('segment 1', segment1);
              const hasMathOrVariable1 = /\\\(|\\\[|\\text|_|\^|\\frac|\\sqrt|\\int|\\sum|\\prod|\\theta|\\pi/.test(segment1);
              
              // Add the emphasized part, appending the colon if it was matched
              parts.push(
                <strong key={`strong-${start}`} style={{ display: 'inline' }}>
                  {hasMathOrVariable1 ? formatVariablesAndEquations(segment1) : segment1}
                  {includesColon && ':'} {/* Append colon if included in match */}
                </strong>
              );
    
              lastIndex = end;
            }
    
            // Add any remaining text after the last **
            if (lastIndex < text.length) {
              const segment2 = text.slice(lastIndex);
              //console.log('segment 2',segment2)

              const hasMathOrVariable2 = /\\\(|\\\[|\\text|_|\^|\\frac|\\sqrt|\\int|\\sum|\\prod|\\theta|\\pi/.test(segment2);
              parts.push(
                <span key={`span-${lastIndex}`}>
                    {hasMathOrVariable2 ? formatVariablesAndEquations(segment2) : segment2}
                </span>
              );
            }
  
            return parts;
          };


        return (
          <li
            key={`sub-list-item-${key}-${idx}-${subIdx}`}
            style={{
              listStyleType: 'none',
              marginLeft: '20px',
              textIndent: '-20px', // Outdent the first line
              paddingLeft: '40px', // Indent the rest of the lines
              fontSize: '14px',
            }}
          >
            {/* Render the emphasized content inline without splitting */}
            {renderEmphasizedText(subItem)}
          </li>
        );
      });
  
      // If there are multiple sub-items, wrap them in a nested <ul>
      return subListItems.length > 1 ? (
        <li
          key={`list-item-${key}-${idx}`}
          style={{
            listStyleType: 'none',
            marginLeft: '-10px',
            textIndent: '-20px', // Outdent the first line
            paddingLeft: '40px', // Indent the rest of the lines
          }}
        >
          <ul style={{ paddingLeft: '0' }}>{subListItems}</ul>
        </li>
      ) : (
        subListItems[0] // If only one item, return it as is
      );
    });
  
    return (
      <ul key={`list-${key}`} style={{ paddingLeft: '0' }}>
        {listItems}
      </ul>
    );
  };
  
  
  const extractListItems = (segment: string): string[] => {
    // Extracts list items from a segment with sequential numbers (e.g., "1. First\n2. Second")
    return segment.split(/(?=\d+\.\s+)/);
  };
  
  const formatVariablesAndEquations = (text: string): React.ReactNode => {
    //console.log('text in equation', text);
    const formattedContent = [];
    const blockRegex = /\\\[([^\]]+?)\\\]/g; // Match \[...\] for blocks
    const inlineRegex = /\\\((.*?)\\\)/g; // Match \( ... \) for inline emphasis
    let lastIndex = 0;

    // Helper function to process subscripts, superscripts, and basic replacements
    const formatText = (input: string) => {
      return input
        .replace(/\\textit{([^}]+)}/g, '<em>$1</em>') // Italicize text 
        .replace(/\\bar{([^}]+)}/g, '<span class="overline">$1</span>') // Overline
        .replace(/\\leftharpoons/g, '⇄')                   // Reversible reaction
        .replace(/\\%/g, '%')                             // Percent symbol
        .replace(/\\left/g, '')                           // Remove \left
        .replace(/\\right/g, '')                          // Remove \right
        .replace(/\\quad/g, '<span class="quad-space"></span>') // Quad space
        .replace(/\\\(([^)]+)\\\)/g, '<span>$1</span>')  // Inline math
        .replace(/\\text{([^}]+)}/g, '$1')            // Plain text
        .replace(/\\cdot/g, '·')                      // Dot symbol
        .replace(/\\times/g, '×')                     // Multiplication symbol
        .replace(/\\,/g, ' ')                         // Space
        .replace(/\\approx/g, '≈')                    // Approximately equal symbol
        .replace(/_([a-zA-Z0-9]+)/g, '<sub>$1</sub>') // Single-character subscripts
        .replace(/_\{([^}]+)\}/g, '<sub>$1</sub>')    // Multi-character subscripts
        .replace(/([a-zA-Z0-9])\^\{?([+−*/])\}?/g, '$1<sup>$2</sup>') // Superscript for any letter/number followed by ^+ or ^-
        .replace(/\^([a-zA-Z0-9]+)/g, '<sup>$1</sup>') // Single-character superscripts
        .replace(/\^\{([^}]+)\}/g, '<sup>$1</sup>')   // Multi-character superscripts
        .replace(/\^\{\\circ\}/g, '<sup>°</sup>')           // Degree symbol as superscript (\^{\circ})
        .replace(/\^\\circ/g, '<sup>°</sup>')               // Degree symbol as superscript (^circ)
        .replace(/\\sqrt{([^}]+)}/g, '<span class="sqrt-wrapper">  <span class="sqrt-symbol">√ </span> <span class="sqrt-overline"></span><span class="sqrt-content">$1</span></span>') // Square root with extended top line
        .replace(/\\frac{([^{}]+|{[^}]+})}{([^{}]+|{[^}]+})}/g, 
          '<span class="fraction" style="display: inline-flex; flex-direction: column; align-items: center; position: relative; top: -0.6em;">' +
            '<span class="numerator" style="border-bottom: 1px solid currentColor; padding: 0 0.1em;">$1</span>' +
            '<span class="denominator" style="padding: 0 0.1em;">$2</span>' +
          '</span>'
        )
        .replace(/\\leq/g, '≤')                       // Less than or equal
        .replace(/\\geq/g, '≥')                       // Greater than or equal
        .replace(/\\neq/g, '≠')                       // Not equal
        .replace(/\\infty/g, '∞')                     // Infinity
        .replace(/\\pi/g, 'π')                        // Pi
        .replace(/\\alpha/g, 'α')                     // Greek letter alpha
        .replace(/\\beta/g, 'β')                      // Greek letter beta
        .replace(/\\gamma/g, 'γ')                     // Greek letter gamma
        .replace(/\\delta/g, 'δ')                     // Greek letter delta
        .replace(/\\epsilon/g, 'ε')                   // Greek letter epsilon
        .replace(/\\lambda/g, 'λ')                    // Greek letter lambda
        .replace(/\\mu/g, 'μ')                        // Greek letter mu
        .replace(/\\tau/g, 'τ')                           // Greek letter tau
        .replace(/\\theta/g, 'θ')                     // Greek letter theta
        .replace(/\\sigma/g, 'σ')                     // Greek letter sigma
        .replace(/\\Delta/g, 'Δ')                     // Uppercase Greek letter Delta
        .replace(/\\int/g, '∫')                       // Integral symbol
        .replace(/\\int_([a-zA-Z0-9]+)\\sup([a-zA-Z0-9]+)/g, '∫<sub>$1</sub><sup>$2</sup>') // Integral with limits
        .replace(/\\int_\{([^}]+)\}\^\{([^}]+)\}/g, '∫<sub>$1</sub><sup>$2</sup>') // Multi-character limits for integrals
        .replace(/\\sum/g, '∑')                       // Summation symbol
        .replace(/\\prod/g, '∏')                      // Product symbol
        .replace(/\\partial/g, '∂')                   // Partial derivative symbol
        .replace(/\\rightarrow/g, '→')                // Right arrow
        .replace(/\\leftarrow/g, '←')                 // Left arrow
        .replace(/\\Rightarrow/g, '⇒')                // Double right arrow
        .replace(/\\Leftarrow/g, '⇐')                 // Double left arrow
        .replace(/\\pm/g, '±')                        // Plus-minus symbol
        .replace(/\\mp/g, '∓')                        // Minus-plus symbol
        .replace(/\\div/g, '÷')                       // Division symbol
        .replace(/\\forall/g, '∀')                    // Universal quantifier
        .replace(/\\exists/g, '∃')                    // Existential quantifier
        .replace(/\\nabla/g, '∇')                     // Nabla (gradient) symbol
        .replace(/\\circ/g, '∘')                      // Circle operator
        .replace(/\\cup/g, '∪')                       // Union
        .replace(/\\cap/g, '∩')                       // Intersection
        .replace(/\\subset/g, '⊂')                    // Subset
        .replace(/\\supset/g, '⊃')                    // Superset
        .replace(/\\subseteq/g, '⊆')                  // Subset or equal
        .replace(/\\supseteq/g, '⊇')                  // Superset or equal
        .replace(/\\log/g, 'log')                     // Logarithm (log)
        .replace(/\\ln/g, 'ln')                       // Natural log (ln)
        .replace(/\\exp/g, 'exp')                     // Exponential (exp)
        .replace(/\\ldots/g, '…')                     // Ellipsis (…)
        .replace(/\\sin/g, 'sin')                     // Sine
        .replace(/\\cos/g, 'cos')                     // Cosine
        .replace(/\\tan/g, 'tan')                     // Tangent
        .replace(/\\csc/g, 'csc')                     // Cosecant
        .replace(/\\sec/g, 'sec')                     // Secant
        .replace(/\\cot/g, 'cot')                     // Cotangent
        .replace(/\\arcsin/g, 'arcsin')               // Inverse sine
        .replace(/\\arccos/g, 'arccos')               // Inverse cosine
        .replace(/\\arctan/g, 'arctan')               // Inverse tangent
        .replace(/\\sinh/g, 'sinh')                   // Hyperbolic sine
        .replace(/\\cosh/g, 'cosh')                   // Hyperbolic cosine
        .replace(/\\tanh/g, 'tanh');                  // Hyperbolic tangent
    };
  
    // Process block equations \[...\]
    text.replace(blockRegex, (match, blockContent, index) => {
      // Add any preceding text as regular text, formatted with formatText
      if (index > lastIndex) {
        // Format the preceding text with formatText
        const precedingText = formatText(text.slice(lastIndex, index));
      
        // Render the formatted preceding text, applying it as HTML
        formattedContent.push(
          <span
            key={`${lastIndex}-text`}
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: precedingText }}
          />
        );
      }
  
      // Process block content and add centered equation
      const formattedEquation = formatText(blockContent);
      formattedContent.push(
        <div key={index} style={{ textAlign: 'center', margin: '1em 0' }}>
          <span dangerouslySetInnerHTML={{ __html: formattedEquation }} />
        </div>
      );
  
      lastIndex = index + match.length;
      return '';
    });
  
    // Process remaining text after block matches, including inline matches
    let remainingText = text.slice(lastIndex);
    lastIndex = 0;
    remainingText.replace(inlineRegex, (match, inlineContent, index) => {
      // Add any preceding text as regular text, formatted with formatText
      if (index > lastIndex) {
        const precedingText = formatText(remainingText.slice(lastIndex, index));

        //console.log('precedingText',precedingText)
        formattedContent.push(
          <span key={`${lastIndex}-inline-text`} style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: precedingText }} />
        );
      }
  
      // Process inline content as emphasized text
      const formattedInline = formatText(inlineContent);
      formattedContent.push(
        <em key={index} style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: formattedInline }} />
      );
  
      lastIndex = index + match.length;
      return '';
    });
     
    // Add any remaining text after the last inline match, formatted with formatText
    if (lastIndex < remainingText.length) {
      const remainingFormattedText = formatText(remainingText.slice(lastIndex));
      formattedContent.push(
        <span key="remaining-inline-text" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: remainingFormattedText }} />
      );
    }
  
    return <span>{formattedContent}</span>;
  };
  

  const formatContent = (text: string): React.ReactNode => {
    const segments = splitIntoSegments(text);
    let inCodeBlock = false;
    let codeBlockContent = '';
    let listSegments: string[] = [];
    let expectedListNumber = 1;
    let result: React.ReactNode[] = [];
  
    const removeExtraNewlines = (str: string) => str.replace(/\n+/g, ' ').trim();
  
    const pushFormattedContent = (formattedSegment: string, index: number) => {
      formattedSegment = removeExtraNewlines(formattedSegment); // Remove all extra newlines
      if (formattedSegment.includes('- ')) {

        //console.log('formattedSegment',formattedSegment)

        const listItems = formattedSegment.split(/\n+/)
          .filter(item => item.trim().startsWith('- '))
          .map((item, i) => {
            const content = removeExtraNewlines(item.replace(/^- /, ''));
            //console.log('content',content)
            const parts = content.split(/\*\*(.*?)\*\*:?/g);
            //console.log('parts',parts) 
            return (
              <li key={`list-item-${index}-${i}`}>
                {parts.map((part, j) =>
                  j % 2 === 1 ? (
                    <strong key={`strong-${index}-${j}`} style={{ display: 'inline' }}>
                      {formatVariablesAndEquations(part)}
                    </strong>
                  ) : (
                    <span key={`span-${index}-${j}`} style={{ display: 'inline' }}>
                      {formatVariablesAndEquations(part)}
                    </span>
                  )
                )}
              </li>
            );
          });
  
        result.push(
          <ul key={`list-${index}`} style={{ listStyleType: 'none', paddingLeft: '20px' }}>
            {listItems}
          </ul>
        );
      } else {

        const parts = formattedSegment.split(/\*\*(.*?)\*\*/g);
       // console.log('parts',parts) 

        result.push(
          <div key={`segment-${index}`}>
            {parts.map((part, j) =>
              j % 2 === 1 ? (
                <strong key={`strong-${index}-${j}`} style={{ display: 'inline' }}>
                  {formatVariablesAndEquations(part)}
                </strong>
              ) : (
                <span key={`span-${index}-${j}`} style={{ display: 'inline' }}>
                  {formatVariablesAndEquations(removeExtraNewlines(part))}
                </span>
              )
            )}
          </div>
        );
      }
    };
  
    segments.forEach((segment, index) => {
      if (!segment) return;
  
      if (inCodeBlock) {
        codeBlockContent += '\n' + segment;
        if (segment.endsWith('```')) {
          inCodeBlock = false;
          const formattedCode = formatCodeSnippet(codeBlockContent, index, copiedKey, handleCopy);
          codeBlockContent = '';
          result.push(formattedCode);
        }
        return;
      }
  
      if (isCodeSnippet(segment)) {
        if (segment.startsWith('```') && segment.endsWith('```')) {
          result.push(formatCodeSnippet(segment, index, copiedKey, handleCopy));
          return;
        } else if (segment.startsWith('```')) {
          inCodeBlock = true;
          codeBlockContent = segment;
          return;
        }
      }
  
      let formattedSegment = segment.trim();
      
     //console.log('formatted Segment',formattedSegment)
      
      if (formattedSegment.startsWith('###')) {
        const firstNewlineIndex = formattedSegment.indexOf('\n');
        let headingText = formattedSegment;
        let remainingText = '';
  
        if (firstNewlineIndex !== -1) {
          headingText = formattedSegment.substring(0, firstNewlineIndex);
          remainingText = formattedSegment.substring(firstNewlineIndex + 1);
        }
        //console.log('headingtext', headingText);

        let headingContent = headingText
          .trimStart()                   // Remove any leading whitespace
          .replace(/^#{3,4}\s*/, '')     // Remove "### " or "#### " at the beginning
          .replace(/\*\*/g, '');         // Remove all ** markers
        
        result.push(
          headingText.startsWith('####') ? (
            <h4 key={`heading-${index}`}>
              {formatVariablesAndEquations(headingContent)}
            </h4>
          ) : (
            <h3 key={`heading-${index}`}>
              {formatVariablesAndEquations(headingContent)}
            </h3>
          )
        );
        
        formattedSegment = remainingText ? remainingText : '';
      }
      //console.log('formatted Segment',formattedSegment)

      const currentListNumber = extractListNumber(formattedSegment);
      if (currentListNumber === expectedListNumber || isListInSegment(formattedSegment)) {
        const extractedListSegments = extractListItems(formattedSegment);
        listSegments = listSegments.concat(extractedListSegments);
        //console.log('list segments',listSegments)

        const lastListSegment = extractListNumber(listSegments[listSegments.length - 1]);
        expectedListNumber = lastListSegment !== undefined ? lastListSegment + 1 : 1;
  
        const nextSegment = segments[index + 1];
        const nextListNumber = nextSegment ? extractListNumber(nextSegment) : undefined;
  
        if (!nextSegment || nextListNumber !== expectedListNumber) {
          const formattedList = formatList(listSegments, index);
          listSegments = [];
          expectedListNumber = 1;
          result.push(formattedList);
        }
        return;
      }
  
      if (isImage(formattedSegment)) {
        result.push(<div key={`image-${index}`} dangerouslySetInnerHTML={{ __html: formattedSegment }} />);
        return;
      } else if (isAudio(formattedSegment)) {
        result.push(<div key={`audio-${index}`} dangerouslySetInnerHTML={{ __html: formattedSegment }} />);
        return;
      }
  
      pushFormattedContent(formattedSegment, index);
    });
  
    return result.length ? result : undefined;
  };
  
  // Function to read aloud the assistant's response
  const readAloud = (text: string) => {
    if (isPlaying && audioInstance) {
      // Stop playing if already playing
      speechSynthesis.cancel(); // Stop any ongoing speech
      setIsPlaying(false); // Reset playing state
      setAudioInstance(null); // Clear audio instance
      return; // Exit if we are stopping
    }

    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = 'en-US'; // Set the language as needed
    speech.onstart = () => {
      setIsPlaying(true); // Set playing state
      setAudioInstance(speech); // Store the audio instance
    }; 
    speech.onend = () => {
      setIsPlaying(false); // Reset playing state when done
      setAudioInstance(null); // Clear audio instance
    };

    speechSynthesis.speak(speech);
  };

  const handleCopyResponse = (index: number, response: string) => {
    navigator.clipboard.writeText(response).then(() => {
      setCopiedResponseIndex(index);
      setTimeout(() => setCopiedResponseIndex(null), 2000); // Reset after 2 seconds
    }).catch(err => console.error('Failed to copy:', err));
  };

// Function to toggle full-screen mode
  const toggleFullScreen = () => {
    setIsFullScreen(prevState => !prevState);
  };
  const handleCanvasToggle = () => {
    setIsCanvasVisible(prev => !prev);
  };


  return (
<div className={`container ${isFullScreen ? 'fullscreen-mode' : ''}`}>
  {!isFullScreen && <NavBar userImage={userImage} onProfileLoad={handleProfileLoad} />}
  <div className={`main-content ${isFullScreen ? 'fullscreen-content' : ''}`}>
       {!isFullScreen && 
        <Sidebar
        className="chat-sidebar" 
        onLogout={handleLogout}
        onHomeClick={async () => {
            navigate('/home');
        }}
        />
       }

    <div className={`chat-content-inner ${isFullScreen ? 'fullscreen-chat-container' : ''}`}>

    {isCanvasVisible && (
      <div className="canvas-container">
          {/* Dynamically render CaddMain or EngineerMain based on selectedProfessional */}
          {selectedAssistant?.professional === "CADD Designer" ? (
              <EngineerMain id="my-canvas" />
          ) : selectedAssistant?.professional === "Bridge Engineer" ? (
              <EngineerMain id="my-canvas" />
          ) : null}
      </div>
      )}

      <div
          className={`chat-container ${isFullScreen ? 'fullscreen-chat-container' : ''}`}
          style={{
            width: isCanvasVisible ? '20%' : '100%',
            display: 'flex',

            transition: 'width 0.3s',
            margin: isCanvasVisible ? '0' : '0 auto',
          }}
          onDoubleClick={toggleFullScreen}
        >
       {!isFullScreen && 
            <div className="chat-container-icons">
              <ul className="chat-container-ul">
                 <li className="chat-container-li">
                  <button title='New Chat' onClick={newChat}>
                  <svg width="30" height="30" viewBox="0 0 24 24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" ><path d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z" fill="currentColor" />
                  </svg>
                  </button>
                </li>

                <li className="chat-container-li">
                  <button title='Add Assistant' onClick={handleAddAssistant}>
                    <FaUserPlus size={28} />
                  </button>
                </li>

                {assistants.map((assistant, index) => (
                <li className="chat-container-li" key={assistant.id} onClick={handleAssistantClick(index)} onDoubleClick={handleAssistantClick(index)} >
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                    <button
                        style={{
                        position: 'relative',
                        background: 'transparent',
                        border: 'none',
                        color: currentAssistantIndex === index ? '#007bff' : '#8055f5', // Blue if selected
                        }}
                        onMouseEnter={() => setHoveredIndex(index)} // Set hover state on mouse enter
                        onMouseLeave={() => setHoveredIndex(null)}  // Remove hover state on mouse leave
                        title={assistant.name} // Show the assistant's name in a tooltip
                    >
                        <FaUser size={24} style={{ color: currentAssistantIndex === index ? '#007bff' : 'inherit' }} /> {/* Retain original color or blue if selected */}
                        <span
                        style={{
                            position: 'absolute',
                            top: '30%', // Fine-tune the position to merge better
                            left: '90%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '10px', // Make the number smaller to integrate better
                            fontWeight: 'bold',
                            color: hoveredIndex === index || currentAssistantIndex === index ? '#007bff' : '#8055f5', // Blue on hover or if selected, original color otherwise
                            textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)', // Add shadow for depth
                            pointerEvents: 'none', // Prevent interaction with the virtualId text
                        }}
                        >
                        {assistant.virtualId}
                        </span>
                    </button>
                    </div>
                </li>
                ))}

                <li className="chat-container-li">
                  <button title='Remove Assistant' onClick={() => setIsRemoveAssistantOpen(true)}>
                    <FaUserMinus size={28} />
                  </button>
                </li>

                {isProfessionalAssistant && (
                    <li className="chat-container-li">
                        <button
                            title='Canvas'
                            onClick={handleCanvasToggle}
                            style={{ color: isCanvasVisible ? '#007bff' : '#8055f5' }} // Change color based on visibility
                        >
                            <FaPaintBrush size={28} />
                        </button>
                    </li>
                )}

              </ul>
            </div>
          }

            <div className="chat-area">
              {currentAssistantIndex !== null ? (
                <>

                <div className="chat-messages">
                {assistants[currentAssistantIndex]?.chat.map((chat, index) => (
                  <div key={index} className={`chat-message ${chat.from}`}>
                    <strong>
                      {chat.from === 'user' 
                        ? firstName || 'You' 
                        : assistants[currentAssistantIndex]?.name}:
                    </strong>

                    <div 
                        className="assistant-response-container"
                        onMouseEnter={() => setHoveredIndex(index)} // Set hover state on mouse enter
                        onMouseLeave={() => setHoveredIndex(null)}  // Remove hover state on mouse leave
                      >
                      {formatContent(chat.message)}
                      {chat.from === 'assistant' && hoveredIndex === index && (
                        <div className="response-buttons" onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)}>
                          <button 
                            onClick={() => readAloud(chat.message)} 
                            title="Read Aloud" 
                            style={{ cursor: 'pointer', marginRight: '5px', color: isPlaying ? 'green' : 'inherit' }}
                          >
                            {isPlaying ? <FaVolumeUp style={{ color: 'green' }} size={16} /> : <FaVolumeUp size={16} />}
                          </button>
                          <button 
                            onClick={() => handleCopyResponse(index, chat.message)} 
                            title="Copy Response"
                            style={{ cursor: 'pointer' }}
                          >
                            {copiedResponseIndex === index ? <FaClipboardCheck size={16}/> : <FaClipboard size={16} />}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {/* Show thinking indicator after the last user message */}
                  {isAssistantThinking && (
                    <div className="thinking-indicator">
                      <div className={`chat-message assistant`}>
                      <strong>
                        {assistants[currentAssistantIndex]?.name}:
                      </strong>
                      </div>
                      <div className="dot"></div>
                    </div>
                  )}
                  {/* Dummy div to scroll to the bottom */}
                  <div ref={chatEndRef} />
                </div>

                {/* Prompt Container */}

                <div className="prompt-container">

                {imagePreviewUrls.length > 0 && (
                <div className="image-preview">
                    {imagePreviewUrls.map((url, index) => (
                    <img key={index} src={url} alt={`Preview ${index + 1}`} style={{ width: '50px', marginBottom: '10px' }} />
                    ))}
                </div>
                )}

                <div className="input-row">
                    <label 
                    title="Upload" 
                    htmlFor="file-upload" 
                    style={{ cursor: 'pointer', alignSelf: 'flex-end' }} 
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                    </svg>
                    </label>
                    <input id="file-upload" type="file" multiple onChange={handleFileUpload} style={{ display: 'none' }} />

                    <textarea
                    value={newMessage}
                    onChange={(e) => {
                        setNewMessage(e.target.value);

                        // Always reset the height if the input is empty
                        if (e.target.value === '') {
                        e.target.style.height = '24px';
                        } else {
                        e.target.style.height = 'auto'; // Reset height to calculate new height based on content
                        e.target.style.height = `${Math.min(e.target.scrollHeight, 300)}px`; // Restrict height to 10 lines (about 300px)
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault(); // Prevent default behavior (adding a new line)
                        handleSendMessage(); // Trigger the send message function
                        setNewMessage(''); // Clear the textarea field after sending
                        
                        // Reset the height of the textarea when user input is empty
                        e.currentTarget.style.height = '24px';
                        }
                    }}
                    placeholder="Type a message"
                    className="message-input"
                    rows={2}
                    />

                <button
                  className="chat-send-button"
                  onClick={() => (loading ? stopStreaming() : handleSendMessage())}
                  aria-label={loading ? 'Stop streaming' : 'Send message'}
                >
                  {loading ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                      <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" fill="none" />
                      <rect x="6" y="6" width="4" height="4" fill="currentColor" />
                    </svg>
                  ) : (
                    <FaPaperPlane size={20} />
                  )}
                </button>

                </div>
                </div>

                </>
              ) : (
                <p>Select an assistant to start chatting.</p>
              )
              
              
              }
            </div>

            {/* Add Assistant Options Window */}
            {showAddAssistantOptionWindow && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <h3 className="modal-title">Add Assistant</h3>
                  <div className="buttonContainer">
                    <div style={{ marginBottom: '10px' }}>
                      <button
                        className="save-button"
                        onClick={() => {
                          setShowAddAssistantOptionWindow(false); // Close the options window
                          setShowAssistantWindow(true); // Open assistant creation window
                        }}
                      >
                        Add a Personal Assistant
                      </button>
                    </div>
                    {(plan === 'civilProChat') && (
                    <div>
                      <button
                        className="save-button"
                        onClick={() => {
                          setShowAddAssistantOptionWindow(false); // Close the options window
                          setShowProfessionalSelectionWindow(true); // Open professional selection window
                        }}
                      >
                        Add a Professional
                      </button>
                    </div>
                    )}

                  </div>
                  <button className="cancel-button" onClick={closeAddAssistantOptionWindow}>
                    Cancel
                  </button>
                </div>
              </div>
            )}

            {/* Professional Selection Window */}
            {showProfessionalSelectionWindow && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <h3 className="modal-title">Select a Professional Assistant</h3>
                  <ul className="assistant-selection">
                    <li>
                      <button
                        className={`professional-button ${selectedProfessional === 'CADD Designer' ? 'selected' : ''}`}
                        style={{
                          backgroundColor: selectedProfessional === 'CADD Designer' ? 'darkblue' : 'inherit', // Change to dark blue if selected
                          color: selectedProfessional === 'CADD Designer' ? 'white' : 'inherit'
                        }}
                        onClick={() => handleSelectProfessional('CADD Designer')}
                      >
                        CADD Designer
                      </button>
                    </li>
                    <li>
                      <button
                        className={`professional-button ${selectedProfessional === 'Bridge Engineer' ? 'selected' : ''}`}
                        style={{
                          backgroundColor: selectedProfessional === 'Bridge Engineer' ? 'darkblue' : 'inherit', // Change to dark blue if selected
                          color: selectedProfessional === 'Bridge Engineer' ? 'white' : 'inherit'
                        }}
                        onClick={() => handleSelectProfessional('Bridge Engineer')}
                      >
                        Bridge Engineer
                      </button>
                    </li>
                  </ul>
                  <div className="buttonContainer">
                    <button
                      className="save-button"
                      style={{ marginRight: '10px' }} // Add margin to create a gap between the buttons
                      onClick={handleProfessionalAssistantAdd}
                    >
                      OK
                    </button>
                    <button className="cancel-button" onClick={closeProfessionalSelectionWindow}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Assistant Creation Window */}
            {showAssistantWindow && (
            <div className="modal-overlay">
                <div className="modal-content">
                <h3 className="modal-title">New Assistant Information</h3>

                <div className="inputContainer">
                    <label htmlFor="assistantName" className="label">Assistant Name</label>
                    <input
                    id="assistantName"
                    type="text"
                    name="assistantName"
                    value={assistantName}
                    onChange={(e) => setAssistantName(e.target.value)}
                    placeholder="Enter Assistant Name"
                    required
                    maxLength={50}
                    className="asswindow-input" // Use this class to set width to 60%
                    />
                </div>

                <div className="inputContainer">
                    <label htmlFor="assistantInstructions" className="label">Assistant Instructions</label>
                    <textarea
                    id="assistantInstructions"
                    name="assistantInstructions"
                    value={assistantInstructions}
                    onChange={(e) => setAssistantInstructions(e.target.value)}
                    placeholder="Enter Instructions"
                    required
                    rows={4}
                    className="asswindow-textarea" // Use this class for consistent styling
                    />
                </div>

                <div className="buttonContainer">
                    <button className="cancel-button" onClick={closeAssistantWindow}>
                    Cancel
                    </button>
                    <button className="save-button" onClick={handleCreateAssistant}>Save</button>
                </div>
                </div>
            </div>    
            )}
            
            {/* Professional Creation Window */}
            {
              showProfessionalCreationWindow && (
                <ProfessionalCreationWindow
                  assistants={assistants}
                  setAssistants={setAssistants}                  
                  selectedProfessional={selectedProfessional}
                  assistantName={assistantName}
                  setAssistantName={setAssistantName}
                  assistantInstructions={assistantInstructions}
                  setAssistantInstructions={setAssistantInstructions}
                  handleClose={() => setShowProfessionalCreationWindow(false)} // Close the window without saving
                />
              )
            }

            {showUpdateAssistantWindow && (
            <div className="modal-overlay">
                <div className="modal-content">
                <h3 className="modal-title">Update Assistant Information</h3>

                <div className="inputContainer">
                    <label htmlFor="assistantName" className="label">Assistant Name</label>
                    <input
                    id="assistantName"
                    type="text"
                    name="assistantName"
                    value={assistantName}
                    onChange={(e) => setAssistantName(e.target.value)}
                    placeholder="Enter Assistant Name"
                    required
                    maxLength={50}
                    className="asswindow-input"
                    />
                </div>

                <div className="inputContainer">
                    <label htmlFor="assistantInstructions" className="label">Assistant Instructions</label>
                    <textarea
                    id="assistantInstructions"
                    name="assistantInstructions"
                    value={assistantInstructions} // Ensure this is set correctly
                    onChange={(e) => setAssistantInstructions(e.target.value)}
                    placeholder="Enter Instructions"
                    required
                    rows={4}
                    className="asswindow-textarea"
                    />
                </div>

                <div className="buttonContainer">
                    <button className="cancel-button" onClick={() => setShowUpdateAssistantWindow(false)}>
                    Cancel
                    </button>
                    <button className="save-button" onClick={handleUpdateAssistant}>
                    Save
                    </button>
                </div>
                </div>
            </div>
            )}

            {/* Assistant Removal Modal */}
            {isRemoveAssistantOpen && (
              <div className="modal-overlay">
                <div className="modal-content" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  <h3 className="modal-title">Remove Assistants</h3>
                  <div className="assistant-selection">
                    <ul className="assistant-remove-list">
                      {assistants.map(assistant => (
                        <li key={assistant.id} className="assistant-item">
                          <input
                            type="checkbox"
                            checked={selectedAssistants.includes(assistant.id)}
                            onChange={() => handleToggleAssistantSelection(assistant.id)}
                            className="assistant-checkbox"
                          />
                          <label className="assistant-checkbox-label">{assistant.name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="actions">
                    <button className="cancel-button" onClick={() => setIsRemoveAssistantOpen(false)}>Cancel</button>
                    <button className="remove-button" onClick={handleRemoveSelectedAssistants}>Remove Selected</button>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
