import React, { useState, useEffect, useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, storage } from '../firebaseConfig';
import { listAll, getDownloadURL, ref, deleteObject, uploadBytes,updateMetadata,getMetadata } from 'firebase/storage';
import { signOut, onAuthStateChanged} from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, collection, addDoc, query, where,getDocs} from 'firebase/firestore';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import PublicProfile from './PublicProfile';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import './Base.css';
import { FirebaseError } from 'firebase/app';
import OpenAI from "openai";
import Logo from '../assets/logo.png'; // Assuming your logo is here
import { FaPaperPlane, FaShareSquare,FaEdit,FaTrashAlt,FaCommentDots,FaSearch, FaCompass, FaUpload, FaFolderOpen,FaDownload} from 'react-icons/fa';
import { PDFDocument, rgb} from 'pdf-lib';
import * as fontkit from 'fontkit'; 
import PdfViewerModal from './PdfViewerModal'; 
import CustomConfirmModal from './CustomConfirmModal';
import { formatDistanceToNow } from 'date-fns';
import audioFile from "../assets/userInterfaceIcons/audioFile.png";
import pdfFile from "../assets/userInterfaceIcons/pdfFile.png";
import qnaFile from "../assets/userInterfaceIcons/qnaFile.png";
import youTubeFile from "../assets/userInterfaceIcons/youTubeFile.png";
import defaultUser from "../assets/default-user.png";

// OpenAI API setup
const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
  })
  interface ChatMessage {
    message: string;
    from: 'user' | 'assistant';
    type?: 'text' | 'image'; // Optional property for different message types
  }
  
interface Assistant {
    name: string;
    id: string;
    chat: ChatMessage[];
    virtualId: string;
    instructions?: string;
    model:string,
    tool:string;
    vectorStore:string; 
    threadID: string; 
  }

  interface FileData {
    name: string;
    userId: string;
    url: string;
    youtubeUrl: string;
    thumbnailUrl:string;
    refPath: string;
    createdAt: Date; 
    viewCount: number;
    documentType: string; 
    shareWithPublic: string; 
    userImageUrl: string; 
    qnaUrl:string;
    summaryUrl:string;   
    transcriptUrl:string;
  }
  

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [files, setFiles] = useState<FileData[]>([]);
  const [fileName, setFileName] = useState<string>('');  
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newFileName, setNewFileName] = useState('');
  const [currentFileRefPath, setCurrentFileRefPath] = useState('');
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const [isQnaModalOpen, setIsQnaModalOpen] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);  
  const [numQuestions, setNumQuestions] = useState(10);
  const [questionFormat, setQuestionFormat] = useState('Multiple choice');
  const [summaryFormat, setSummaryFormat] = useState('Long and Detailed'); 
  const [difficultyLevel, setDifficultyLevel] = useState('Medium'); // New state for difficulty level
  const [animationOption, setAnimationOption] = useState('Yes'); // New state for animation
  const [progressText, setProgressText] = useState<string>(''); 
  const [showProgress, setShowProgress] = useState<boolean>(false); 
  const [qnaStatus, setQnaStatus] = useState<Record<string, boolean>>({});
  const [summaryStatus, setSummaryStatus] = useState<Record<string, boolean>>({});
  const fetchAttemptedRef = useRef<Record<string, boolean>>({});
  const fetchAttemptedRef1 = useRef<Record<string, boolean>>({});
  const firestoreFetchAttempted = useRef(false); // Ref to track Firestore fetch attempts
  const filesFetchAttempted = useRef(false);
  const [qnaFileUrl, setQnaFileUrl] = useState<string | null>(null);
  const [summaryFileUrl, setSummaryFileUrl] = useState<string | null>(null);  
  const [isAnimationRunning, setIsAnimationRunning] = useState(false); // New state to control animation visibility
  const [shareModalOpen, setShareModalOpen] = useState(false); // State for Share Modal
  const [shareEmail, setShareEmail] = useState(''); // Email input state
  const [shareMessage, setShareMessage] = useState(''); // Message input state
  const [shareFileUrl, setShareFileUrl] = useState<string | null>(null); // The file URL to be shared
  const [shareFileName, setShareFileName] = useState<string | null>(null); 
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [showAnimationOptions] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null); // Ref for dropdown menu
  const [userUID, setUserUID] = useState<string>('');
  const user = auth.currentUser;
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState<Assistant | null>(null);
  const [assistantFiles, setAssistantFiles] = useState<{ id: string; name: string }[]>([]);
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [selectedChatFile, setSelectedChatFile] = useState<string | null>(null);
  const [thumbnails, setThumbnails] = useState<{ [key: string]: string }>({});
  const [showShareConfirmModal, setShowShareConfirmModal] = useState<boolean>(false);  
  const [shareWithPublic, setShareWithPublic] = useState<boolean>(true);
  const toolBarRef = useRef<HTMLDivElement | null>(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(true);
  const [currentFile, setCurrentFile] = useState<FileData | null>(null);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [filteredFiles, setFilteredFiles] = useState(files); 
  const processedFilesRef = useRef(new Set<string>());
  const [showSidebar, setShowSidebar] = useState(false);  
  const [showPublic, setShowPublic] = useState(true);
  const [showYourSpace, setShowYourSpace] = useState(false);  
  const [showAll, setShowAll] = useState(true);  
  const [showPDF, setShowPDF] = useState(false);  
  const [showAudio, setShowAudio] = useState(false); 
  const [showVideo, setShowVideo] = useState(false);   
  const [showQna, setShowQna] = useState(false);  
  const [showProfile, setShowProfile] = useState(false);
  const [profileUID, setProfileUID] = useState<string | null>(null);
  const [transcriptUrl, setTranscriptUrl] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<FileData | null>(null);
  //const [showAssistants, setShowAssistants] = useState(false);   
  const [subjectLabels, setSubjectLabels] = useState<{ [key: string]: string }>({
    subject1: "Math",
    subject2: "Science",
    subject3: "History",
    subject4: "English",
    subject5: "Art",
    subject6: "Music",
    subject7: "PE",
    subject8: "Coding",
  });
  


  const fetchAllUserFiles = useCallback(async (userUID: string) => {
    if (filesFetchAttempted.current) return;

    try {
        const folderRefs = [
            ref(storage, 'uploads/'),
            ref(storage, 'videos/'),
            ref(storage, 'recordings/'),
           // ref(storage, 'library/'),
        ];

        const profileImagesRef = ref(storage, 'profileImages/');

        // Fetch all profile images to map user_id to userImageUrl
        const fetchProfileImages = async () => {
            const profileImagesSnapshot = await listAll(profileImagesRef);
            const userImageMap: Record<string, string> = {};

            for (const item of profileImagesSnapshot.items) {
                const user_id = item.name.split('.')[0]; // Assuming the user_id is the file name without extension
                const imageUrl = await getDownloadURL(item);
                userImageMap[user_id] = imageUrl;
            }

            return userImageMap;
        };

        const userImageMap = await fetchProfileImages();

        const userFilesPromises = folderRefs.map(async (folderRef) => {
            const res = await listAll(folderRef);

            return res.items.map(async (item) => {
                const url = await getDownloadURL(item);
                let createdAt = new Date();
                let documentType = '';
                let shareWithPublic = 'No';
                let youtubeUrl = ''; // Default value
                let thumbnailUrl = ''; // Default value
                let qnaUrl = ''; 
                let summaryUrl = ''; 
                let transcriptUrl = '';                  
                let userImageUrl = '';
                let viewCount = 0; // Default value
                let name = '';
                let user_id = '';

                try {
                    const metadata = await getMetadata(item);
                    createdAt = metadata.timeCreated ? new Date(metadata.timeCreated) : createdAt;
                    documentType = metadata.customMetadata?.DocumentType || '';
                    shareWithPublic = metadata.customMetadata?.ShareWithPublic || 'No';
                    viewCount = metadata.customMetadata?.viewCount
                        ? parseInt(metadata.customMetadata.viewCount, 10)
                        : 0;
                    thumbnailUrl = metadata.customMetadata?.ThumbnailUrl || '';
                    qnaUrl = metadata.customMetadata?.QnaUrl || '';  
                    summaryUrl = metadata.customMetadata?.SummaryUrl || '';   
                    transcriptUrl = metadata.customMetadata?.TranscriptUrl || '';                                                            
                    user_id = metadata.customMetadata?.UserID || '';

                    userImageUrl = userImageMap[user_id] || ''; // Get user image URL from the map

                    if (documentType === 'YouTube Video') {
                        name = metadata.customMetadata?.YoutubeTitle || '';
                        const response = await fetch(url);
                        youtubeUrl = await response.text();
                        const videoId = youtubeUrl.split('v=')[1]?.split('&')[0] || '';
                        thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
                    } else {
                        name = item.name.replace(`${user_id}_`, '');
                    }
                } catch (metadataError) {
                    console.warn("Error fetching metadata:", metadataError);
                }

                return {
                    name,
                    userId:user_id,
                    url,
                    refPath: item.fullPath,
                    youtubeUrl,
                    thumbnailUrl,
                    createdAt,
                    viewCount,
                    documentType,
                    shareWithPublic,
                    userImageUrl,
                    qnaUrl,
                    summaryUrl,
                    transcriptUrl,                    
                };
            });
        });

        const allFiles = await Promise.all(userFilesPromises);
        const filesWithUrls = await Promise.all(allFiles.flat());

        // Fetch public files
        const publicFilesPromises = folderRefs.map(async (folderRef) => {
            const res = await listAll(folderRef);

            return res.items.map(async (item) => {
                const url = await getDownloadURL(item);
                let createdAt = new Date();
                let documentType = '';
                let shareWithPublic = 'No';
                let youtubeUrl = ''; // Default value
                let thumbnailUrl = ''; // Default value
                let qnaUrl = ''; 
                let summaryUrl = '';    
                let transcriptUrl = '';                    
                let userImageUrl = '';
                let viewCount = 0; // Default value
                let name = '';
                let user_id = '';

                try {
                    const metadata = await getMetadata(item);
                    createdAt = metadata.timeCreated ? new Date(metadata.timeCreated) : createdAt;
                    documentType = metadata.customMetadata?.DocumentType || '';
                    shareWithPublic = metadata.customMetadata?.ShareWithPublic || 'No';
                    user_id = metadata.customMetadata?.UserID || '';
                    qnaUrl = metadata.customMetadata?.QnaUrl || '';  
                    summaryUrl = metadata.customMetadata?.SummaryUrl || '';  
                    transcriptUrl = metadata.customMetadata?.TranscriptUrl || '';                      
                    userImageUrl = userImageMap[user_id] || ''; // Get user image URL from the map

                    if (shareWithPublic === 'Yes') {
                        name = item.name.replace(`${user_id}_`, '');
                    }
                } catch (metadataError) {
                    console.warn("Error fetching metadata:", metadataError);
                }

                return {
                    name,
                    userId: user_id,
                    url,
                    refPath: item.fullPath,
                    youtubeUrl,
                    thumbnailUrl,
                    createdAt,
                    viewCount,
                    documentType,
                    shareWithPublic,
                    userImageUrl,
                    qnaUrl,
                    summaryUrl,
                    transcriptUrl,                    
                };
            });
        });

        const publicFiles = await Promise.all(publicFilesPromises);
        const allPublicFiles = await Promise.all(publicFiles.flat());
        const filteredPublicFiles = allPublicFiles.filter(file => file.shareWithPublic === 'Yes');

        // Combine user files and public files, ensuring no duplicates
        const combinedFiles = [
            ...filesWithUrls,
            ...filteredPublicFiles.filter(publicFile => !filesWithUrls.some(file => file.url === publicFile.url)),
        ];

        setFiles(combinedFiles); // No type errors now

        filesFetchAttempted.current = true;
    } catch (error) {
        console.error("Error fetching files:", error);
    }
}, []);

  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchAllUserFiles(user.uid); 
        firestoreFetchAttempted.current = false; 
        filesFetchAttempted.current = false; 
      } else {
        console.warn('No user is authenticated.');
        setFiles([]); 
      }
    });
  
    return () => unsubscribe();
  }, [fetchAllUserFiles]);


  useEffect(() => {
    const filtered = files.filter((file) => {
        // Only include files shared with the public when 'Public' is active
        if (showPublic && file.shareWithPublic !== 'Yes') {
            return false;
        }
        
        if (user) {
          if (showYourSpace && !file.url.includes(user.uid)) {
            return false;
          }
        } else {
          console.warn('No user is authenticated.');
        }

        // Apply additional filters based on the selected type
        if (showAll) return true;

        const matchPDF = showPDF && file.documentType === 'Note';
        const matchAudio = showAudio && file.documentType === 'Recording';
        const matchVideo = showVideo && file.documentType === 'YouTube Video';
        const matchQna = showQna && file.documentType === 'Question';

        return matchPDF || matchAudio || matchVideo || matchQna;
    });

    setFilteredFiles(filtered);
}, [showAll, showPDF, showAudio, showVideo, showQna, showPublic, showYourSpace,user,files]);


  // Handler for toggling the menu
  const toggleMenu = (index: number) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownElement = document.querySelector('.menu-content');
      const isClickInside = dropdownElement?.contains(event.target as Node);
      
      // If the click is outside the dropdown, close it
      if (!isClickInside) {
        setOpenMenuIndex(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const checkQnaFileExists = useCallback(async (fileUrl: string, qnaUrl: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser || fetchAttemptedRef.current[fileUrl]) {
      return;
    }
  
    fetchAttemptedRef.current[fileUrl] = true;
  
    if (!qnaUrl) {
      setQnaStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: false }));
      return;
    }
  
    try {
      // Create a StorageReference using the qnaUrl
      const qnaFileRef = ref(storage, qnaUrl);
  
      // Attempt to fetch the download URL
      await getDownloadURL(qnaFileRef);
      setQnaStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: true }));
    } catch (error) {
      setQnaStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: false }));
    }
  }, []);

  const checkSummaryFileExists = useCallback(async (fileUrl: string, summaryUrl: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser || fetchAttemptedRef1.current[fileUrl]) {
      return;
    }

    fetchAttemptedRef1.current[fileUrl] = true;
  
    if (!summaryUrl) {
      setSummaryStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: false }));
      return;
    }
    try {
      // Create a StorageReference using the summaryUrl
      const summaryFileRef = ref(storage, summaryUrl);
      // Attempt to fetch the download URL
      await getDownloadURL(summaryFileRef);
      setSummaryStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: true }));
    } catch (error) {
      setSummaryStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: false }));
    }
  }, []);
  

  useEffect(() => {
    files.forEach((file) => {
      checkQnaFileExists(file.url, file.qnaUrl);
      checkSummaryFileExists(file.url, file.summaryUrl);
    });
  }, [files, checkQnaFileExists, checkSummaryFileExists]);


  useEffect(() => {
    files.forEach((file) => {
      if (!processedFilesRef.current.has(file.url)) {
        processedFilesRef.current.add(file.url); // Mark as processed
  
        if (file.thumbnailUrl) {
          // Use the existing thumbnail URL if available
          setThumbnails((prev) => ({ ...prev, [file.url]: file.thumbnailUrl }));
        } 
      }
    });
  }, [files]);
  

  const saveSelectedFileToDatabase = async (uid: string, fileUrl: string) => {
    if (!uid) return;

    const firestore = getFirestore();
    try {
      const userDocRef = doc(firestore, 'users', uid);
      await setDoc(userDocRef, { selectedFileUrl: fileUrl }, { merge: true });
    } catch (error) {
      if (error instanceof FirebaseError && error.code !== 'permission-denied') {
        console.error("Error saving selected file to Firestore:", error);
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadBio: string) => {
    setUserImage(loadedImageUrl);
  };

  // Handler for closing the Share Modal
  const handleCancelShare = () => {
    setShareModalOpen(false);
  };

  const handleShareFile = (fileUrl: string, fileName: string) => {
    setShareFileUrl(fileUrl); // Set the file URL to be shared
    setShareFileName(fileName); // Set the file name to be shared
    setShareModalOpen(true); // Open the modal
  };


  // Function to get the user by email from Firestore
  const getUserByEmail = async (email: string) => {
    const firestore = getFirestore();
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef, where('email', '==', email));    
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      return { uid: userDoc.id, ...userDoc.data() }; // Return UID and user data

    }
    return null; // No user found with the provided email
  };
  
  const handleSendShare = async () => {
    const firestore = getFirestore();
  
    try {
      // Fetch the receiver's UID based on the email provided in the modal
      const receiverUser = await getUserByEmail(shareEmail);

      if (!receiverUser) {
        alert('User with this email does not exist.');
        return;
      }

      const receiverUID = receiverUser.uid; // Get the receiver's UID
  
      // Add a notification to the receiver's Firestore collection
      await addDoc(collection(firestore, 'notifications'), {
        receiverId: receiverUID,
        senderId: auth.currentUser?.uid,
        fileUrl: shareFileUrl,
        fileName: shareFileName, // Send the file name with the notification
        fileType: 'note',
        message: `${auth.currentUser?.email} is sharing the file "${shareFileName}" with you.`,
        status: 'pending', // Initially, the status is pending
        createdAt: new Date(),
        isRead: false, // Ensure that new notifications are marked as unread
      });
  
      alert(`Sharing file "${shareFileName}" message sent to ${shareEmail}`);
      setShareModalOpen(false); // Close the modal
    } catch (error) {
      console.error('Error sharing file:', error);
      alert('Failed to share the file. Please try again.');
    }
  };
  
  const handleSelectFile = async (url: string) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      // Reset savedLibraryUrl in Firestore to empty string
      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      await setDoc(userDocRef, { savedLibraryUrl: '' }, { merge: true });

      // Save the selected file URL to the database
      saveSelectedFileToDatabase(currentUser.uid, url);
    }
  };


  const handleDeleteFile = async (fileRefPath: string) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this file?");
    if (confirmDelete) {
        const fileRef = ref(storage, fileRefPath);

        try {
            // Attempt to delete the main file
            await deleteObject(fileRef);
        } catch (error) {
            if (error instanceof FirebaseError && error.code === 'storage/object-not-found') {
                console.log('Main file not found in storage, removing from file list.');
            } else {
                console.error('Error deleting file:', error);
                alert('Failed to delete the file. Please try again.');
                return;
            }
        }

        // Extract the file name from the fileRefPath
        const fileName = fileRefPath.split('/').pop(); // Get the last part of the path as the file name

        if (fileName) {
            const currentUser = auth.currentUser;
            if (currentUser) {
                // Construct the path to the corresponding JSON file in the Q&A folder
                const qnaFileRefPath = `Q&A/${fileName}_qna.json`;
                const qnaFileRef = ref(storage, qnaFileRefPath);

                // Attempt to delete the corresponding JSON file
                try {
                    await deleteObject(qnaFileRef);
                    console.log('Q&A JSON file deleted successfully.');
                } catch (qnaError) {
                    if (qnaError instanceof FirebaseError && qnaError.code === 'storage/object-not-found') {
                        console.log('No corresponding Q&A JSON file found.');
                    } else {
                        console.error('Error deleting Q&A JSON file:', qnaError);
                    }
                }
            }
        }

        // Update the state to remove the file from the list and close the PDF viewer if open
        setFiles(files.filter(file => file.refPath !== fileRefPath));
        setPdfUrl(null);

        alert('File deleted successfully.');
    }
};


    const handleFileShareWithPublic = async (fileUrl: string) => {
      setShareFileUrl(fileUrl); 
      setShowShareConfirmModal(true); 
    };

    const confirmFileShareWithPublic = async () => {
      if (shareFileUrl) { 
        setShareWithPublic(true);
        await handleShareChange(shareFileUrl); 
      } else {
        console.error('No file selected for change.'); 
      }
      setShowShareConfirmModal(false); // Close the modal
    };

    const cancelFileShareWithPublic = async () => {
      if (shareFileUrl) { 
        setShareWithPublic(false);
        await handleShareChange(shareFileUrl); 
      } else {
        console.error('No file selected for change.'); 
      }
      setShowShareConfirmModal(false); // Close the modal
    };

    const handleShareChange = async (fileRefPath: string) => {
      const fileRef = ref(storage, fileRefPath); // Reference to the file
      try {
        // Fetch the current metadata of the file
        const currentMetadata = await getMetadata(fileRef);

        // Prepare the new metadata object by merging with the current metadata
        const updatedMetadata = {
          customMetadata: {
            ...currentMetadata.customMetadata, // Preserve existing custom metadata fields
            ShareWithPublic: shareWithPublic ? 'Yes' : 'No' // Update only ShareWithPublic field
          }
        };

        // Update the file's metadata in Firebase Storage
        await updateMetadata(fileRef, updatedMetadata);
        setShareWithPublic(false);
        setShareFileUrl(''); 

        // Success message
        alert(`You have updated the file sharing with the public succesfully.`);
      } catch (error) {
        console.error('Error updating metadata:', error);
        alert('Failed to update the file. Please try again.');
      }
    };

    const handleChatChange = async (fileRefPath: string) => {
      const currentUser = auth.currentUser;
    
      if (!currentUser) {
        alert("You need to be logged in to proceed.");
        return;
      }
    
      setSelectedChatFile(fileRefPath);
    
      // Clear assistant files before opening the modal
      setAssistantFiles([]);
    
      // Open chat modal for assistant selection
      openChatModal();
    };
    
    
    // Move useEffect outside the function
    useEffect(() => {
      if (isChatModalOpen && auth.currentUser) {
        const fetchAssistants = async () => {
          const currentUser = auth.currentUser;
    
          // Check if the currentUser exists and has a UID
          if (!currentUser || !currentUser.uid) {
            console.error('No authenticated user or UID is missing.');
            return;
          }
    
          try {
            // Get Firestore reference
            const firestore = getFirestore();
            
            // Get a reference to the user document in Firestore
            const userDocRef = doc(firestore, 'users', currentUser.uid);
            
            // Fetch the user document
            const userDoc = await getDoc(userDocRef);
            
            if (userDoc.exists()) {
              const userData = userDoc.data();
              
              // Process and map the fetched assistants to match the expected structure
              if (userData.assistants) {
                const fetchedAssistants = userData.assistants.map((assistant: any) => ({
                  name: assistant.name,
                  id: assistant.id,
                  chat: assistant.lastChatMessages || [], // Fetch the last set of chat messages
                  virtualId: assistant.name.charAt(0).toUpperCase(),
                  instructions: assistant.instructions,
                  tool: assistant.tool,
                  model: assistant.model,
                  vectorStore: assistant.vectorStoreId,
                  threadID: assistant.threadID,
                }));
    
                // Set assistants to state
                setAssistants(fetchedAssistants);
                console.log('Fetched assistants:', fetchedAssistants);
              } else {
                console.warn('No assistants found for the user.');
              }
            } else {
              console.warn('User document does not exist.');
            }
          } catch (error) {
            console.error('Error fetching assistants:', error);
          }
        };
    
        fetchAssistants();
      }
    }, [isChatModalOpen]);
    
    const fetchAssistantFiles = async (assistant: Assistant) => {
      try {
        // Fetch the list of files from the assistant's vector store
        const filesList = await openai.beta.vectorStores.files.list(assistant.vectorStore);
        console.log('vector store files',filesList)
        // Fetch file names based on file IDs by fetching metadata for each file
        const fileNames = await Promise.all(
          filesList.data.map(async (file) => {
            try {
              // Fetch the metadata of each file using the file ID
              const fileMetadata = await openai.files.retrieve(file.id);
              return { id: file.id, name: fileMetadata.filename || 'Unknown File' };
            } catch (error) {
              console.error(`Error fetching metadata for file ID: ${file.id}`, error);
              return { id: file.id, name: 'Unknown File' }; // Default to 'Unknown File' in case of an error
            }
          })
        );
    
        // Set the file names to state, mapping the file IDs to their respective names
        setAssistantFiles(fileNames);
      } catch (error) {
        console.error("Error fetching assistant's files:", error);
      }
    };
  
  const handleAssistantSelection = (assistant: Assistant) => {
    setSelectedAssistant(assistant);
    fetchAssistantFiles(assistant);
  };


  const handleAddFile = async () => {
    // Ensure that `selectedAssistant` and `selectedChatFile` are available from state
    if (!selectedAssistant || !selectedChatFile) {
      alert('No file or assistant selected');
      return;
    }
  
    try {
      // Get the file reference from Firebase Storage
      const fileRef = ref(storage, selectedChatFile);
  
      // Get the download URL for the file
      const fileUrl = await getDownloadURL(fileRef);

      // Fetch the file as a Blob
      const fileResponse = await fetch(fileUrl);
      const fileBlob = await fileResponse.blob();
  
      // Get the file name (e.g., Note23.pdf) from the selectedChatFile URL
      const fileName = decodeURIComponent(selectedChatFile)
        .replace('uploads/', '')
        .split('_')
        .slice(1)
        .join('_') || 'file.pdf';
  
      // Convert the Blob into a File object
      const file = new File([fileBlob], fileName, { type: fileBlob.type });

      // Upload the file to OpenAI's API to get the file_id
      const fileUploadResponse = await openai.files.create({
        file: file,
        purpose: "assistants",
      });
  
      const fileID = fileUploadResponse.id;

      // Now, add the uploaded file to the selected assistant's vector store
      await openai.beta.vectorStores.files.create(selectedAssistant.vectorStore, {
        file_id: fileID,
      });

      await openai.beta.assistants.update(selectedAssistant.id, {
        tool_resources: { file_search: { vector_store_ids: [selectedAssistant.vectorStore] } },
      });

      // Refresh the assistant's file list after adding
      await fetchAssistantFiles(selectedAssistant);
  
      alert(`File "${fileName}" added successfully to assistant "${selectedAssistant.name}"`);
  
    } catch (error) {
      console.error('Error adding file to assistant:', error);
      alert('Failed to add file to assistant.');
    }
  };
  

  const handleRemoveFile = async (file_id: string) => {
    if (!selectedAssistant) return;
  
    try {
      // Display a warning message before deleting the file
      const confirmDelete = window.confirm("Are you sure you want to delete this file? This action cannot be undone.");
  
      // If the user cancels the action, return early
      if (!confirmDelete) return;
  
      // If file is not found, return
      if (!file_id) return;
  
      // Remove the file from the assistant's vector store using the file's id
      await openai.beta.vectorStores.files.del(selectedAssistant.vectorStore, file_id);
  
      // Remove the file from OpenAI's storage using the file's id
      await openai.files.del(file_id);
  
      // Add a short delay to allow for the file to be fully removed
      await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay
  
      // Refresh the assistant's file list after deletion
      await fetchAssistantFiles(selectedAssistant);
  
      alert('File removed successfully.');
    } catch (error) {
      console.error('Error removing file from assistant:', error);
      alert('Failed to remove file from assistant.');
    }
  };
  
  
  const openChatModal = () => setIsChatModalOpen(true);
  const closeChatModal = () => setIsChatModalOpen(false);


  const handleRenameFile = async (newName: string) => {
    if (currentFileRefPath && newName) {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userUID = currentUser.uid;
        const newFileRefPath = `uploads/${userUID}_${newName}`;
        const fileRef = ref(storage, currentFileRefPath);
        const newFileRef = ref(storage, newFileRefPath);
  
        try {
          // Fetch the file from storage
          const fileUrl = await getDownloadURL(fileRef);
          const response = await fetch(fileUrl);
          const blob = await response.blob();
          
          // Upload the file with the new name
          await uploadBytes(newFileRef, blob);
  
          // Fetch the new file URL after renaming
          const newFileUrl = await getDownloadURL(newFileRef);
  
          // Delete the original file
          await deleteObject(fileRef);
  
          // Attempt to rename the corresponding Q&A JSON file
          const oldQnaFileRefPath = `Q&A/${currentFileRefPath.split('/').pop()}_qna.json`;
          const newQnaFileRefPath = `Q&A/${userUID}_${newName}_qna.json`;

          const oldQnaFileRef = ref(storage, oldQnaFileRefPath);
          const newQnaFileRef = ref(storage, newQnaFileRefPath);
  
          try {
            // Check if the old Q&A JSON file exists
            const qnaFileUrl = await getDownloadURL(oldQnaFileRef);
            const qnaResponse = await fetch(qnaFileUrl);
            const qnaBlob = await qnaResponse.blob();
  
            // Upload the Q&A JSON file with the new name
            await uploadBytes(newQnaFileRef, qnaBlob);
  
            // Delete the old Q&A JSON file
            await deleteObject(oldQnaFileRef);
  
            console.log('Q&A JSON file renamed successfully.');
          } catch (qnaError) {
            if (qnaError instanceof Error) {
              if (qnaError.message.includes('storage/object-not-found')) {
                console.log('No corresponding Q&A JSON file found, skipping rename.');
              } else {
                console.error('Error renaming Q&A JSON file:', qnaError);
              }
            } else {
              console.error('Unexpected error renaming Q&A JSON file:', qnaError);
            }
          }
  
          // Update the state to reflect the renamed file with the new URL
          setFiles(
            files.map(file =>
              file.refPath === currentFileRefPath ? { ...file, name: newName, refPath: newFileRefPath, url: newFileUrl } : file
            )
          );
          alert('File renamed successfully.');
        } catch (error) {
          console.error('Error renaming file:', error);
          alert('Failed to rename the file. Please try again.');
        } finally {
          setIsRenameModalOpen(false);
        }
      }
    }
  };
  
  const handleSummaryButtonClick = (fileUrl: string) => {
    if(!showPublic){
      if(currentFile?.documentType === 'YouTube Video' || currentFile?.documentType === 'Recording'){
        setSummaryFileUrl(transcriptUrl);
      }else{
        setSummaryFileUrl(fileUrl);
      }    
      setProgressText('');
      setShowProgress(false);
      setIsSummaryModalOpen(true);
    } else{
      alert("Switch to Your Space to generate summaries for your files!");
      return;
    }
  };

  const handleQnaButtonClick = (fileUrl: string) => {
    if(!showPublic){
      if(currentFile?.documentType === 'YouTube Video' || currentFile?.documentType === 'Recording'){
        setQnaFileUrl(transcriptUrl);
      }else{
        setQnaFileUrl(fileUrl);
      }
      setProgressText('');
      setShowProgress(false);
      setIsQnaModalOpen(true);  
    } else{
      alert("Switch to Your Space to generate questions for your files");
      return;
    }
  };

  const handleQnaModalClose = () => {
    setIsQnaModalOpen(false);
  };
  const handleSummaryModalClose = () => {
    setIsSummaryModalOpen(false);
  };

  const updateProgress = (message: string) => {
    setProgressText(prev => `${message}\n${prev}`);
  };

  const handleQuestionGeneration = async () => {
    if(currentFile?.shareWithPublic === "Yes"){
      setShareWithPublic(true);
    } else{
      setShareWithPublic(false);
    }
    await handleQnaOkClick() ;
  };

  const handleSummaryGeneration = async () => {
    if(currentFile?.shareWithPublic === "Yes"){
      setShareWithPublic(true);
    } else{
      setShareWithPublic(false);
    }
    await handleSummaryOkClick() ;
  };

  const handleQnaOkClick = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
        alert("You need to be logged in to generate Q&A.");
        return;
    }

    setShowProgress(true);
    updateProgress("Starting Q&A generation...");

    if (animationOption === 'Yes') {
        setIsQnaModalOpen(false); // Close the Q&A modal
        setIsAnimationRunning(true); // Start the animation

        // Start with the initial scale-up and rotation
        const logo = document.querySelector('.animation-logo') as HTMLElement | null;
        if (logo) {
            logo.style.animation = 'rotateAndScaleUpLogo 5s ease-in-out forwards';

            // After the scale-up is complete, start continuous rotation without stopping
            setTimeout(() => {
                logo.style.animation = 'continuousRotate 2s linear infinite';
            }, 5000); // Match this timeout with the duration of the rotateAndScaleUpLogo animation
        }
    }

    try {
        const fileUrl = qnaFileUrl;
        console.log('qna File Url',qnaFileUrl)
        if (!fileUrl) {
            alert("No file selected. Please select a file to generate Q&A.");
            return;
        }

        updateProgress(`Selected file URL: ${fileUrl}`);

        const fileResponse = await fetch(fileUrl);
        const fileBlob = await fileResponse.blob();

        let fileName = '';

        if (currentFile?.documentType === 'YouTube Video' || currentFile?.documentType === 'Recording') {
            // Extract the filename by splitting on '/' and taking the last part, then remove query parameters
            const fileUrlParts = decodeURIComponent(fileUrl).split('/');
            const fullFileName = fileUrlParts[fileUrlParts.length - 1]; // Get the last segment of the path
            const cleanFileName = fullFileName.split('?')[0]; // Remove query parameters if any
        
            // Remove the UID prefix (assumes UID and file name are separated by an underscore '_')
            const fileNameParts = cleanFileName.split('_');
            fileName = fileNameParts.length > 1 ? fileNameParts.slice(1).join('_') : cleanFileName;
        } else {
            fileName = files.find(file => file.url === fileUrl)?.name || "file.pdf";
        }
        
        console.log('fileName',fileName)

        updateProgress("Fetched file blob.");
        updateProgress(`Determined file name: ${fileName}`);

        const file = new File([fileBlob], fileName, { type: fileBlob.type });
        updateProgress("Converted Blob to File.");

        const assistantResponse = await openai.beta.assistants.create({
            name: "Q&A Assistant",
            instructions: "Generate Q&A based on the uploaded file.",
            model: "gpt-4o-mini",
            tools: [{ type: "file_search" }],
            metadata: { user: currentUser.uid }
        });

        const assistantID = assistantResponse.id;
        updateProgress("Created AI Assistant.");

        const threadResponse = await openai.beta.threads.create();
        const threadID = threadResponse.id;
        updateProgress("Created thread.");

        const fileUploadResponse = await openai.files.create({
            file: file,
            purpose: "assistants",
        });
        console.log('fileUploadRespons',fileUploadResponse)

        const fileID = fileUploadResponse.id;
        updateProgress("Uploaded file.");

        const vectorStoreResponse = await openai.beta.vectorStores.create({
            name: "Q&A Vector Store",
        });

        const vectorStoreID = vectorStoreResponse.id;
        updateProgress("Created Vector Store.");

        const myVectorStoreFile = await openai.beta.vectorStores.files.create(vectorStoreID, {
            file_id: fileID,
        });
        console.log('myvectorestorefile',myVectorStoreFile);
        updateProgress("File added to vector store.");

        await openai.beta.assistants.update(assistantID, {
            tool_resources: { file_search: { vector_store_ids: [vectorStoreID] } },
        });
        updateProgress("Updated assistant with vector store ID.");

        let prompt = '';
        let adjustedDifficultyLevel = difficultyLevel;
        if (difficultyLevel === 'Easy') {
          adjustedDifficultyLevel = 'Medium';
        } else if (difficultyLevel === 'Medium') {
          adjustedDifficultyLevel = 'Hard';
        } else if (difficultyLevel === 'Hard') {
          adjustedDifficultyLevel = 'very very hard and impossible to answer';
        }
        
        // Construct the prompt based on the question format and the adjusted difficulty level
        if (questionFormat === 'Direct question') {
          prompt = `Can you create ${numQuestions} ${adjustedDifficultyLevel} questions based on the content of the file and provide corresponding answers. 
          Show the questions and answers in JSON format in an array.`;
        }
        
        if (questionFormat === 'Multiple choice') {
          prompt = `Can you create ${numQuestions} ${adjustedDifficultyLevel} questions based on the content of the file and provide corresponding answers. 
          Provide the question with multiple choices for user to select, choice A, choice B, choice C, and choice D. There is only 
          one correct answer. Provide a letter among A, B, C, and D. Show the multiple choice questions and answers in JSON format in an array.
          For the JSON format, use "question" as the key for the question, "options" as the key for choices of A, B, C, and D, and "answer" as the key for the correct answer.`;
        }
        updateProgress("Prompt for Q&A generation sent.");
        console.log('prompt', prompt);

        const messageResponse = await openai.beta.threads.messages.create(threadID, {
            content: prompt,
            role: 'user',
        });
        updateProgress("Added a message to the thread.");
        console.log(messageResponse);

        let runAttempts = 0;
        let resultData: string | null = null;
        let isComplete = false;
        const maxAttempts = 10;

        while (runAttempts < maxAttempts && !isComplete) {
            runAttempts += 1;

            updateProgress(`Attempt ${runAttempts} to generate Q&A`);

            const runResponse = await openai.beta.threads.runs.create(threadID, {
                assistant_id: assistantID,
            });

            updateProgress(`Created run...`);

            let runStatus: OpenAI.Beta.Threads.Runs.Run | null = null;
            while (!isComplete) {
                runStatus = await openai.beta.threads.runs.retrieve(threadID, runResponse.id);
                updateProgress(`Run status: ${runStatus?.status}`);

                if (runStatus && runStatus.status === "completed") {
                    const messages = await openai.beta.threads.messages.list(threadID);
                    if (messages.data.length > 0 && messages.data[0].content.length > 0 && messages.data[0].content[0].type === 'text') {
                        resultData = messages.data[0].content[0].text.value;

                        const jsonMatch = resultData.match(/```json([\s\S]*?)```/);

                        if (jsonMatch && jsonMatch[1]) {
                          try {
                            const jsonData = JSON.parse(jsonMatch[1].trim());
                        
                            const cleanedResultData = JSON.stringify(jsonData, null, 2);
                        
                            const qnaFileRef = ref(storage, `Q&A/${currentUser.uid}_${fileName}_qna.json`);
                            const qnaBlob = new Blob([cleanedResultData], { type: "application/json" });
                        
                            // Set custom metadata for the Q&A file
                            const customMetadata = {
                                customMetadata: {
                                    ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
                                    UserID: userUID,
                                    DocumentType: 'Question',
                                },
                            };
                        
                            await uploadBytes(qnaFileRef, qnaBlob, customMetadata);
                        
                            // Get the URL of the uploaded Q&A file
                            const qnaUrl = await getDownloadURL(qnaFileRef);
                        
                            // Update the custom metadata of the current file with the QnaUrl
                            if (currentFile) {
                                const fileRef = ref(storage, currentFile.refPath);
                                const currentMetadata = await getMetadata(fileRef);
                        
                                const updatedMetadata = {
                                    customMetadata: {
                                        ...currentMetadata.customMetadata, // Preserve existing custom metadata fields
                                        QnaUrl: qnaUrl, // Add the QnaUrl field
                                    },
                                };
                        
                                await updateMetadata(fileRef, updatedMetadata);

                                setCurrentFile({
                                  ...currentFile, 
                                  qnaUrl: qnaUrl, 
                                });

                                console.log("Updated file metadata with QnaUrl:", qnaUrl);
                            }
                        
                            updateProgress("Saved Q&A to Cloud.");
                            alert("Q&A generated and saved successfully!");
                            setShareWithPublic(false);
                        
                            if (currentFile?.url) {
                              setQnaStatus((prevStatus) => ({
                                ...prevStatus,
                                [currentFile.url]: true, // Ensure `currentFile.url` is used only if defined
                              }));
                            } else {
                              console.error("Error: currentFile.url is undefined");
                            }
                            
                            isComplete = true;
                            break;
                        } catch (error) {
                            console.error("Failed to parse JSON data or update metadata:", error);
                        }
                        
                        }
                    }
                    if (!isComplete) {
                        updateProgress("Run completed but no valid JSON data found.");
                        break;
                    }
                } else {
                    await new Promise(resolve => setTimeout(resolve, 2000));
                }
            }
            if (isComplete) break;
        }

        if (!isComplete) {
            updateProgress("No valid JSON data found in the resultData.");
            alert("Failed to generate Q&A data. Try again!");
            await openai.files.del(fileID);
            updateProgress("Deleted file.");

            await openai.beta.threads.del(threadID);
            updateProgress("Deleted thread.");

            await openai.beta.vectorStores.del(vectorStoreID);
            updateProgress("Deleted vector store.");

            await openai.beta.assistants.del(assistantID);
            updateProgress("Deleted assistant.");
        } else {
            await openai.files.del(fileID);
            updateProgress("Deleted file.");

            await openai.beta.threads.del(threadID);
            updateProgress("Deleted thread.");

            await openai.beta.vectorStores.del(vectorStoreID);
            updateProgress("Deleted vector store.");

            await openai.beta.assistants.del(assistantID);
            updateProgress("Deleted assistant.");
        }

    } catch (error) {
        console.error("Error during Q&A generation:", error);
        alert("Failed to generate Q&A. Please try again.");
    } finally {
        if (animationOption === 'Yes') {
            handlePopUpMessage(); // Start the post-animation after completion
        } else {
            setIsQnaModalOpen(false);
            setIsAnimationRunning(false); // Stop the animation
            navigate('/home'); // Navigate back to Notes component
        }
    }
};

const handleSummaryOkClick = async () => {
  console.log('start generating a summary');
  const currentUser = auth.currentUser;
  if (!currentUser) {
      alert("You need to be logged in to generate Summary.");
      return;
  }

  setShowProgress(true);
  updateProgress("Starting summary generation...");

  if (animationOption === 'Yes') {
      setIsSummaryModalOpen(false); // Close the Summary modal
      setIsAnimationRunning(true); // Start the animation

      const logo = document.querySelector('.animation-logo') as HTMLElement | null;
      if (logo) {
          logo.style.animation = 'rotateAndScaleUpLogo 5s ease-in-out forwards';

          setTimeout(() => {
              logo.style.animation = 'continuousRotate 2s linear infinite';
          }, 5000);
      }
  }

  try {
      const fileUrl = summaryFileUrl;
      if (!fileUrl) {
          alert("No file selected. Please select a file to generate Summary.");
          return;
      }

      updateProgress(`Selected file URL: ${fileUrl}`);

      const fileResponse = await fetch(fileUrl);

      const fileBlob = await fileResponse.blob();

      let fileName = '';

      if (currentFile?.documentType === 'YouTube Video' || currentFile?.documentType === 'Recording') {
          // Extract the filename by splitting on '/' and taking the last part, then remove query parameters
          const fileUrlParts = decodeURIComponent(fileUrl).split('/');
          const fullFileName = fileUrlParts[fileUrlParts.length - 1]; // Get the last segment of the path
          const cleanFileName = fullFileName.split('?')[0]; // Remove query parameters if any
      
          // Remove the UID prefix (assumes UID and file name are separated by an underscore '_')
          const fileNameParts = cleanFileName.split('_');
          fileName = fileNameParts.length > 1 ? fileNameParts.slice(1).join('_') : cleanFileName;
      } else {
          fileName = files.find(file => file.url === fileUrl)?.name || "file.pdf";
      }

      updateProgress("Fetched file blob.");
      updateProgress(`Determined file name: ${fileName}`);

      const file = new File([fileBlob], fileName, { type: fileBlob.type });
      updateProgress("Converted Blob to File.");

      const assistantResponse = await openai.beta.assistants.create({
          name: "Summary Assistant",
          instructions: "Generate reformatted text based on the uploaded file.",
          model: "gpt-4o-mini",
          tools: [{ type: "file_search" }],
          metadata: { user: currentUser.uid }
      });

      const assistantID = assistantResponse.id;
      console.log('Assistant ID', assistantID);

      updateProgress("Created AI Assistant.");

      const threadResponse = await openai.beta.threads.create();
      const threadID = threadResponse.id;
      console.log('thread ID', threadID);

      updateProgress("Created thread.");

      const fileUploadResponse = await openai.files.create({
          file: file,
          purpose: "assistants",
      });
      console.log('fileUploadResponse',fileUploadResponse)

      const fileID = fileUploadResponse.id;
      updateProgress("Uploaded file.");

      const vectorStoreResponse = await openai.beta.vectorStores.create({
          name: "Summary Vector Store",
      });

      const vectorStoreID = vectorStoreResponse.id;
      updateProgress("Created Vector Store.");
      console.log('vectorstore ID', vectorStoreID);
      const myVectorStoreFile = await openai.beta.vectorStores.files.create(vectorStoreID, {
        file_id: fileID,
      });
      console.log('myvector store file',myVectorStoreFile)
      updateProgress("File successfully added to vector store.");

      await openai.beta.assistants.update(assistantID, {
          tool_resources: { file_search: { vector_store_ids: [vectorStoreID] } },
      });
      updateProgress("Updated assistant with vector store ID.");

      let prompt = '';

      if (summaryFormat === 'Long and Detailed') {
        prompt = `Can you create a detailed reformatted text based on the file provided? Please format the text as follows:
      - Each key point should be presented as a bullet point.
      - After each bullet point, provide a clear and concise four-sentence explanation that describes the importance or context of the key point.
      - Ensure proper sentence structure, punctuation, and readability in the explanation.
      - The reformatted text should not ignore any important content, and it should be coherent and well-organized.
      - The response should be provided in a JSON format with the key as "ReformattedText" and the value containing the reformatted text. Do not use arrays in the JSON response.
      - The JSON response should be valid and must not include any syntax errors, comments, or non-JSON elements.`
      }

      if (summaryFormat === 'Short and Brief') {
        prompt = `Can you create a brief reformatted text based on the file provided? Please format the text as follows:
      - Present the key information from the file as bullet points.
      - The reformatted text can exclude non-essential or less important content, focusing only on the key points.
      - Provide the response in a single-pair JSON format, with the key as "ReformattedText" and the value containing the text in bullet point format.
      - The JSON response should not contain arrays, comments, or any non-JSON elements, and should be valid and properly formatted.`
      }

      updateProgress("Prompt for summary generation sent.");

      const messageResponse = await openai.beta.threads.messages.create(threadID, {
          content: prompt,
          role: 'user',
      });

      updateProgress("Added a message to the thread.");
      console.log(messageResponse )
      let runAttempts = 0;
      let resultData: string | null = null;
      let isComplete = false;
      const maxAttempts = 10;

      while (runAttempts < maxAttempts && !isComplete) {
        runAttempts += 1;
        updateProgress(`Attempt ${runAttempts} to generate summary`);
        console.log(`Attempt ${runAttempts} to generate summary`);
    
        const runResponse = await openai.beta.threads.runs.create(threadID, {
            assistant_id: assistantID,
        });
    
        updateProgress(`Created run...`);
        console.log(`Created run...`);
    
        let runStatus: OpenAI.Beta.Threads.Runs.Run | null = null;
        while (!isComplete) {
            runStatus = await openai.beta.threads.runs.retrieve(threadID, runResponse.id);
            updateProgress(`Run status: ${runStatus?.status}`);
            console.log(`Run status: ${runStatus?.status}`);
    
            if (runStatus && runStatus.status === "completed") {
                const messages = await openai.beta.threads.messages.list(threadID);
                console.log('openai messages', messages);
    
                if (messages.data.length > 0 && messages.data[0].content.length > 0 && messages.data[0].content[0].type === 'text') {
                    resultData = messages.data[0].content[0].text.value;
                    //console.log('resultData', resultData);
    
                    let jsonData;
    
                    try {
                        // Try parsing the resultData directly if it is valid JSON
                        jsonData = JSON.parse(resultData.trim());
                    } catch (parseError) {
                        // If parsing fails, try matching using the backticks pattern
                        const jsonMatch = resultData.match(/```json([\s\S]*?)```/);
                        if (jsonMatch && jsonMatch[1]) {
                            try {
                                jsonData = JSON.parse(jsonMatch[1].trim());
                            } catch (error) {
                                console.error("Failed to parse JSON data:", error);
                                updateProgress("Error parsing response. Moving to next attempt.");
                                continue;
                            }
                        }
                    }
    
                    // Ensure the data structure matches the expected format with "ReformattedText"
                    if (jsonData && jsonData.ReformattedText) {
                        let reformattedText = "";
    
                        // Handle both array and string formats for ReformattedText
                        if (typeof jsonData.ReformattedText === 'object' && !Array.isArray(jsonData.ReformattedText)) {
                            // If it's a nested object, handle it appropriately
                            reformattedText = Object.entries(jsonData.ReformattedText)
                                .map(([key, value]) => {
                                    if (typeof value === 'object') {
                                        return `${key}: ${JSON.stringify(value, null, 2)}`;
                                    }
                                    return `${key}: ${value}`;
                                })
                                .join("\n");
                        } else if (Array.isArray(jsonData.ReformattedText)) {
                            reformattedText = jsonData.ReformattedText.join("\n");
                        } else if (typeof jsonData.ReformattedText === "string") {
                            reformattedText = jsonData.ReformattedText;
                        }
    
                        const baseFileName = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
    
                        // Save the reformatted text to Firebase Storage as a PDF
                        const filename = `summaries/${currentUser.uid}_${baseFileName}_summary.pdf`;
   
                        textToPDF(reformattedText, filename);
    
                        updateProgress("Saved summary to Cloud.");
                        alert("Summary generated and saved successfully!");
                        if (currentFile?.url) {
                          setSummaryStatus((prevStatus) => ({
                            ...prevStatus,
                            [currentFile.url]: true, 
                          }));
                        } else {
                          console.error("Error: currentFile.url is undefined");
                        }
                        
                        isComplete = true;
                        break; // Break from the inner loop if successful
                    } else {
                        updateProgress("No valid ReformattedText found. Moving to next attempt.");
                    }
                }
    
                if (!isComplete) {
                    updateProgress("Run completed but no valid JSON data found.");
                    break;
                }
            } else {
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
        }
        if (isComplete) break;
    }

      if (!isComplete) {
          updateProgress("No valid JSON data found in the resultData.");
          alert("Failed to generate Summary data. Try again!");
          await openai.files.del(fileID);
          updateProgress("Deleted file.");
          await openai.beta.threads.del(threadID);
          await openai.beta.vectorStores.del(vectorStoreID);
          await openai.beta.assistants.del(assistantID);
      } else {
          await openai.files.del(fileID);
          updateProgress("Deleted file.");
          await openai.beta.threads.del(threadID);
          await openai.beta.vectorStores.del(vectorStoreID);
          await openai.beta.assistants.del(assistantID);
      }

  } catch (error) {
      console.error("Error during Summary generation:", error);
      alert("Failed to generate Summary. Please try again.");
  } finally {
      if (animationOption === 'Yes') {
          handlePopUpMessageSummary();
      } else {
          setIsSummaryModalOpen(false);
          setIsAnimationRunning(false);
      }
  }
};

const textToPDF = async (text: string, filename: string) => {
  const pdfDoc = await PDFDocument.create();
  pdfDoc.registerFontkit(fontkit as any);

  // Load custom fonts
  const fontUrl = process.env.PUBLIC_URL + '/assets/fonts/Roboto-Regular.ttf';
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const customFont = await pdfDoc.embedFont(fontBytes);

  const boldFontUrl = process.env.PUBLIC_URL + '/assets/fonts/Roboto-Bold.ttf';
  const boldFontBytes = await fetch(boldFontUrl).then(res => res.arrayBuffer());
  const customBoldFont = await pdfDoc.embedFont(boldFontBytes);

  const pageWidth = 8.5 * 72;
  const pageHeight = 11 * 72;

  let page = pdfDoc.addPage([pageWidth, pageHeight]);
  const { height } = page.getSize();
  const margin = 50;
  let y = height - margin;

  const fontSizeRegular = 12;
  const listIndent = margin + 20; // Indent for list items
  const lineHeight = fontSizeRegular + 6;

  // Function to draw text on the page with word wrapping
  const drawWrappedText = (text: string, font: any, fontSize: number, y: number, x: number = margin) => {
    const words = text.split(' ');
    let currentLine = '';
    
    // Loop over words to create lines that fit within the page width
    for (let i = 0; i < words.length; i++) {
      const testLine = currentLine + words[i] + ' ';
      const testLineWidth = font.widthOfTextAtSize(testLine, fontSize);

      // If line exceeds the available width, draw it and start a new line
      if (x + testLineWidth > pageWidth - margin) {
        y = drawTextLine(currentLine, font, fontSize, y, x);
        currentLine = words[i] + ' ';
      } else {
        currentLine = testLine;
      }
    }
    
    // Draw the last remaining line
    if (currentLine) {
      y = drawTextLine(currentLine, font, fontSize, y, x);
    }
    
    return y;
  };

  // Helper function to draw a single line of text
  const drawTextLine = (line: string, font: any, size: number, y: number, x: number) => {
    if (y - lineHeight < margin) {
      page = pdfDoc.addPage([pageWidth, pageHeight]);
      y = height - margin;
    }
    page.drawText(line.trim(), { x, y, size, font, color: rgb(0, 0, 0) });
    return y - lineHeight; // Move y-coordinate down for the next line
  };

  // Helper function to remove JSON-like format
  const processText = (text: string) => {
    return text
      .replace(/[{}[\]"]/g, '') // Remove curly braces, square brackets, and quotes
      .replace(/:\s*/g, ': '); // Ensure consistent spacing after colons
  };

  const sanitizedText = filterUnsupportedCharacters(text);
  const paragraphs = sanitizedText.split('\n');

  // Function to process and print lines with optional bold formatting
  const processLine = (line: string, isBold: boolean = false, isListItem: boolean = false) => {
    const font = isBold ? customBoldFont : customFont;
    const x = isListItem ? listIndent : margin;
    y = drawWrappedText(line, font, fontSizeRegular, y, x);
  };

  // Process paragraphs and handle lists, bold sections, and regular text
  paragraphs.forEach(paragraph => {
    const trimmedParagraph = paragraph.trim();

    if (trimmedParagraph.startsWith('-')) {
      // Handle list item
      processLine(trimmedParagraph.slice(1).trim(), false, true);
    } else if (trimmedParagraph.match(/^([A-Za-z\s]+):/)) {
      // Handle bold heading like "Purpose:", "Policy:"
      const boldText = processText(trimmedParagraph.split(':')[0].trim());
      processLine(boldText, true); // Bold title
      const restOfText = trimmedParagraph.split(':').slice(1).join(':').trim();
      if (restOfText) processLine(restOfText); // Regular text after title
    } else {
      // Handle regular paragraph text
      processLine(trimmedParagraph);
    }

    y -= lineHeight; // Add extra space after each paragraph
  });

  // Save the PDF and upload to Firebase
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const storageRef = ref(storage, `${filename}`);

  try {
    const userFilesRef = ref(storage, `summaries/`);
    const fileList = await listAll(userFilesRef);
    const currentUser = auth.currentUser;

    if (currentUser) {
      setUserUID(currentUser.uid);
      console.log('isAnaymous',currentUser.isAnonymous)

      if (currentUser.isAnonymous) {
        const existingFiles = fileList.items.filter(item => item.name.startsWith(currentUser.uid));
        if (existingFiles.length >= 2) {
            alert('Anonymous users can only upload up to two summary files.');
            return;
        }
    
        const customMetadata = {
            customMetadata: {
                ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
                UserID: currentUser.uid,
                DocumentType: 'Summary',
            },
        };
    
        await uploadBytes(storageRef, blob, customMetadata);
    
        // Get the URL of the uploaded summary file
        const summaryUrl = await getDownloadURL(storageRef);
    
        // Update currentFile's custom metadata with the SummaryUrl
        if (currentFile) {
            const fileRef = ref(storage, currentFile.refPath);
            const currentMetadata = await getMetadata(fileRef);
    
            const updatedMetadata = {
                customMetadata: {
                    ...currentMetadata.customMetadata, // Preserve existing metadata
                    SummaryUrl: summaryUrl, // Add the SummaryUrl
                },
            };
    
            await updateMetadata(fileRef, updatedMetadata);

            setCurrentFile({
              ...currentFile, 
              summaryUrl: summaryUrl, 
            });

            //console.log("Updated currentFile metadata with SummaryUrl:", summaryUrl);
        }
    
        console.log('Summary created successfully!');
        setShareWithPublic(false);
    
    } else {
        const firestore = getFirestore();
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
    
        const isStarterUser = userData?.subscriptionStatus !== true;
        console.log('isStarterUser',isStarterUser)

        if (isStarterUser) {
            const existingFiles = fileList.items.filter(item => item.name.startsWith(currentUser.uid));
            if (existingFiles.length >= 2) {
                alert('Starter users can only upload up to two files.');
                return;
            }
        }
    
        const customMetadata = {
            customMetadata: {
                ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
                UserID: currentUser.uid,
                DocumentType: 'Summary',
            },
        };
    
        await uploadBytes(storageRef, blob, customMetadata);
    
        // Get the URL of the uploaded summary file
        const summaryUrl = await getDownloadURL(storageRef);
    
        // Update currentFile's custom metadata with the SummaryUrl
        if (currentFile) {
            const fileRef = ref(storage, currentFile.refPath);
            const currentMetadata = await getMetadata(fileRef);
    
            const updatedMetadata = {
                customMetadata: {
                    ...currentMetadata.customMetadata, // Preserve existing metadata
                    SummaryUrl: summaryUrl, // Add the SummaryUrl
                },
            };
    
            await updateMetadata(fileRef, updatedMetadata);

            setCurrentFile({
              ...currentFile, 
              summaryUrl: summaryUrl, 
            });

            console.log("Updated currentFile metadata with SummaryUrl:", summaryUrl);
        }
    
        console.log('Summary created successfully!');
        setShareWithPublic(false);
    }
    
    }

  } catch (error) {
    console.error('Upload error:', error);
  }
};

// Helper function to filter unsupported characters
const filterUnsupportedCharacters = (text: string): string => {
  return text.replace(/[{}[\]"]/g, ''); // Remove curly braces, square brackets, and quotes
};

const handlePopUpMessage = () => {
    // After the user clicks OK, start scaling down the logo while continuing to rotate
    const logo = document.querySelector('.animation-logo') as HTMLElement | null;
    if (logo) {
        // Switch to the scale down animation with rotation
        logo.style.animation = 'rotateAndScaleDownLogo 3s ease-in-out forwards';
    }

    // Fade out the overlay after the logo scales down
    setTimeout(() => {
        const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
        if (overlay) {
            overlay.classList.add('fade-out');
        }
    }, 3000); // Adjust the timing to match the logo scale-down duration

    // Remove the overlay from the DOM after the fade-out is complete
    setTimeout(() => {
        const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
        if (overlay) {
            overlay.style.display = 'none';
        }
        setIsAnimationRunning(false);
        navigate('/home'); // Navigate back to Notes component
    }, 4000); // Ensure this matches the total duration of scale-down + fade-out
};

const handlePopUpMessageSummary = () => {
  // After the user clicks OK, start scaling down the logo while continuing to rotate
  const logo = document.querySelector('.animation-logo') as HTMLElement | null;
  if (logo) {
      // Switch to the scale down animation with rotation
      logo.style.animation = 'rotateAndScaleDownLogo 3s ease-in-out forwards';
  }

  // Fade out the overlay after the logo scales down
  setTimeout(() => {
      const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
      if (overlay) {
          overlay.classList.add('fade-out');
      }
  }, 3000); // Adjust the timing to match the logo scale-down duration

  // Remove the overlay from the DOM after the fade-out is complete
  setTimeout(() => {
      const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
      if (overlay) {
          overlay.style.display = 'none';
      }
      setIsAnimationRunning(false);
  }, 4000); // Ensure this matches the total duration of scale-down + fade-out
};


useEffect(() => {
  if (selectedFile) {
    setPdfUrl(selectedFile.url);
    setCurrentFile(selectedFile);
    setIsPdfModalOpen(true);
  }
}, [selectedFile]);


const handleViewFile = async (url: string) => {
  const fileToView = filteredFiles.find((file) => file.url === url);

  if (!fileToView) {
    console.warn("File not found in filtered files.");
    return;
  }

  setSelectedFile(fileToView); // Triggers useEffect to update pdfUrl, currentFile, and open the modal

  try {
    const fileRef = ref(storage, fileToView.refPath);

    // Fetch current metadata to get the existing viewCount and qnaUrl
    const metadata = await getMetadata(fileRef);
    let viewCount = parseInt(metadata.customMetadata?.viewCount || "0", 10);
    const qnaUrl = metadata.customMetadata?.QnaUrl || '';
    const summaryUrl = metadata.customMetadata?.SummaryUrl || '';
    const transcriptUrl = metadata.customMetadata?.TranscriptUrl || '';

    // Increment view count
    viewCount += 1;

    // Update the metadata with the incremented view count
    await updateMetadata(fileRef, {
      customMetadata: {
        ...metadata.customMetadata,
        viewCount: viewCount.toString(),
      },
    });

    // Update the `files` state with the new view count and qnaUrl
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.url === url ? { ...file, viewCount, qnaUrl,summaryUrl,transcriptUrl } : file
      )
    );

    // Update `currentFile` with the updated qnaUrl
    setSelectedFile((prevFile) =>
      prevFile ? { ...prevFile, qnaUrl,summaryUrl,transcriptUrl } : null
    );

    console.log(`View count and QnaUrl updated for file: ${url}`);
  } catch (error) {
    console.error("Error updating view count or QnaUrl:", error);
    alert("Failed to update view count or QnaUrl. Please try again.");
  }
};


 // Function to handle left scroll
 const handleScrollLeft = () => {
  if (toolBarRef.current) {
    toolBarRef.current.scrollBy({ left: -100, behavior: 'smooth' });
  }
};

// Function to handle right scroll
const handleScrollRight = () => {
  if (toolBarRef.current) {
    toolBarRef.current.scrollBy({ left: 100, behavior: 'smooth' });
  }
};

// Update scroll button visibility based on scroll position
const updateScrollButtonVisibility = useCallback(() => {
  if (toolBarRef.current) {
    const { scrollLeft, scrollWidth, clientWidth } = toolBarRef.current;

    // Hide left button if scrolled to the left end, otherwise show it
    setShowLeftScroll(scrollLeft > 0);

    // Hide right button if scrolled to the right end, otherwise show it
    setShowRightScroll(scrollLeft + clientWidth < scrollWidth);
  }
}, []);

useEffect(() => {
  const toolbar = toolBarRef.current;
  if (toolbar) {
    // Set initial button visibility
    updateScrollButtonVisibility();

    // Add scroll event listener to update visibility as the user scrolls
    toolbar.addEventListener('scroll', updateScrollButtonVisibility);

    // Cleanup on component unmount
    return () => {
      toolbar.removeEventListener('scroll', updateScrollButtonVisibility);
    };
  }
}, [updateScrollButtonVisibility]);


  // Handle search input changes
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterFiles(query); // Apply filtering based on both search query and document type
  };

  const handleCollectFile = async (fileRefPath: string, fileName: string, documentType: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const currentUserUID = currentUser.uid;

    // Fetch the user's profile from Firestore to check the subscription status
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUserUID);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();
    const isSubscriber = userData?.subscriptionStatus === true; // Check subscription status

    // Determine the folder based on the document type
    const folderMap: { [key: string]: string } = {
        'Note': 'uploads',
        'Question': 'Q&A',
       // 'Subject': 'library',
        'Summary': 'summaries',
    };

    const folder = folderMap[documentType];
    if (!folder) {
        console.error('Invalid document type');
        return;
    }

    try {
        const listRef = ref(storage, `${folder}/`);
        const res = await listAll(listRef);

        // Check if the user already has more than 2 files in the folder (non-subscriber restriction)
        const existingFiles = res.items.filter((item) => item.name.startsWith(`${currentUserUID}_`));
        if (!isSubscriber && existingFiles.length >= 2) {
            alert('Non-subscribers can only collect up to two files in this folder. Consider subscribing to collect unlimited files.');
            return;
        }

        const originalFileRef = ref(storage, fileRefPath);
        const fileDownloadUrl = await getDownloadURL(originalFileRef);
        const response = await fetch(fileDownloadUrl);
        const originalFileBlob = await response.blob();

        const generateUniqueFileName = async (originalName: string): Promise<string> => {
            const listRef = ref(storage, `${folder}/`);
            const res = await listAll(listRef);
            const existingFiles = res.items
                .filter((item) => item.name.startsWith(`${currentUserUID}_`))
                .map((item) => item.name.replace(`${currentUserUID}_`, ''));

            let newFileName = originalName;
            let counter = 1;
            while (existingFiles.includes(newFileName)) {
                const fileNameWithoutExt = originalName.substring(0, originalName.lastIndexOf('.'));
                const fileExt = originalName.substring(originalName.lastIndexOf('.'));
                newFileName = `${fileNameWithoutExt}(${counter})${fileExt}`;
                counter++;
            }
            return newFileName;
        };

        const uniqueFileName = await generateUniqueFileName(fileName);
        const newFilePath = `${folder}/${currentUserUID}_${uniqueFileName}`;

        const newFileRef = ref(storage, newFilePath);
        await uploadBytes(newFileRef, originalFileBlob);

        // Ask user if they want to share the file
        const shareWithPublic = window.confirm('Do you want to share this file with the public?');

        // Update metadata for the new file
        const customMetadata = {
            customMetadata: {
                UserID: currentUserUID,
                DocumentType: documentType,
                ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
            },
        };

        await updateMetadata(newFileRef, customMetadata);

        alert(`File has been copied to your ${folder} folder as "${uniqueFileName}".`);
    } catch (error) {
        console.error('Error collecting the file:', error);
        alert('Failed to collect the file. Please try again.');
    }
};


const handleDownloadFile = async (fileUrl: string) => {
  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);

    // Extract the filename from the URL and clean it up
    let fileName = fileUrl.split('/').pop() || 'downloaded-file';
    
    // Decode the filename from URL encoding (e.g., %20 becomes a space)
    fileName = decodeURIComponent(fileName);
    
    // Remove the UID prefix if it exists (everything before the first underscore)
    const underscoreIndex = fileName.indexOf('_');
    if (underscoreIndex !== -1) {
      fileName = fileName.substring(underscoreIndex + 1); // Keep everything after the first underscore
    }

    // Remove any query parameters (everything after the file extension)
    const extensionIndex = fileName.indexOf('.');
    if (extensionIndex !== -1) {
      // This regex will match the extension and everything after it (query params)
      const queryStart = fileName.indexOf('?');
      if (queryStart !== -1) {
        fileName = fileName.substring(0, queryStart); // Remove everything after the first `?`
      }
    }

    downloadLink.download = fileName;

    // Append the link to the body, trigger a click event, and then remove the link
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Clean up the object URL
    URL.revokeObjectURL(downloadLink.href);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};


  // Filter files based on search query and document type
  const filterFiles = (query: string) => {
    const filtered = files.filter((file) => {
      const matchesSearch = file.name.toLowerCase().includes(query);
      return matchesSearch;
    });
    setFilteredFiles(filtered);
  };

  const handlePublicProfileClick = (userId:string) => {
      setProfileUID(userId);
      setShowProfile(true);
  };

  const handleSubjectClick = async (subjectKey: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert('You need to be logged in to manage subjects.');
      return;
    }
  
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);
  
    const newLabel = window.prompt(`Enter a name for ${subjectKey}:`, subjectLabels[subjectKey] || subjectKey);
    if (!newLabel || newLabel.trim() === '') return;
  
    try {
      await setDoc(
        userDocRef,
        { subjectLabels: { [subjectKey]: newLabel } },
        { merge: true }
      );
  
      setSubjectLabels((prev) => ({
        ...prev,
        [subjectKey]: newLabel,
      }));
  
      alert(`${subjectKey} renamed to "${newLabel}".`);
    } catch (error) {
      console.error('Error updating subject label:', error);
      alert('Failed to update subject label. Please try again.');
    }
  };
  

  const handleSubjectSearchClick = async (subjectKey: string) => {
    const subjectLabel = subjectLabels[subjectKey];
    if (!subjectLabel) return;
  
    const filtered = files.filter((file) =>
      file.name.toLowerCase().includes(subjectLabel.toLowerCase())
    );
  
    setFilteredFiles(filtered);
  };
  

  const fetchSubjectLabels = useCallback(async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    try {
      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', currentUser.uid);
  
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        if (data.subjectLabels) {
          const updatedLabels = {
            ...subjectLabels, // Default values
            ...data.subjectLabels, // Firestore data
          };
          setSubjectLabels(updatedLabels);
  
          // Optionally save the merged labels back to Firestore
          await setDoc(userDocRef, { subjectLabels: updatedLabels }, { merge: true });
        }
      } else {
        // Save default labels to Firestore if the document doesn't exist
        await setDoc(userDocRef, { subjectLabels }, { merge: true });
      }
    } catch (error) {
      console.error('Error fetching subject labels:', error);
    }
  }, [subjectLabels, setSubjectLabels]); 

  // Call `fetchSubjectLabels` on component mount
  useEffect(() => {
    fetchSubjectLabels();
  }, [fetchSubjectLabels]);

  const handleHomeClick = async () => {
    navigate('/home');
  };

  return (
    <div className="container">
      <NavBar 
        userImage={userImage}
        onProfileLoad={handleProfileLoad} 
      />
      {/* Search Bar */}
      <div className="search-bar-container">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
          placeholder="Search files..."
        />
        <FaSearch className="search-icon" />
      </div>

      <div className="main-content">
       {showSidebar && (
        <Sidebar onLogout={handleLogout} onHomeClick={handleHomeClick} />
       )}
        <div className="content-inner">

          {/* Toolbar container */}
          <div className="toolbar-container">
            {/* Left Scroll Button, conditionally rendered */}
            {showLeftScroll && (
              <button onClick={handleScrollLeft} className="scroll-button left-scroll">
                {'<'}
              </button>
          )}

          <div ref={toolBarRef} className="toolbar-buttons">
              {[
                  { label: "Sidebar", key: "Sidebar", icon: <FaCompass />, title: "Hide Sidebar" },
                  { label: "Uploads", key: "Uploads", icon: <FaUpload />, title: "Upload" },
                  { label: "Public", key: "Public" },
                  { label: "Your Space", key: "Your Space" },
                  { label: "All", key: "All" },
                  { label: "PDF", key: "PDF" },
                  { label: "Audio", key: "Audio" },
                  { label: "Video", key: "Video" },
                  { label: "Assistants", key: "Assistants" },
                  ...Object.entries(subjectLabels).map(([key, label]) => ({ label, key })),
              ].map(({ label, key, icon }, index) => (
                  <button
                      key={index}
                      className={`toolbar-button 
                          ${showSidebar && key === "Sidebar" ? "selected" : ""}                       
                          ${showPublic && key === "Public" ? "selected" : ""} 
                          ${showYourSpace && key === "Your Space" ? "selected" : ""} 
                          ${showAll && key === "All" ? "selected" : ""} 
                          ${showPDF && key === "PDF" ? "selected" : ""} 
                          ${showAudio && key === "Audio" ? "selected" : ""} 
                          ${showVideo && key === "Video" ? "selected" : ""} 
                          ${showQna && key === "Qna" ? "selected" : ""}`}
                      onClick={() => {
                          if (key === "Sidebar") {
                              setShowSidebar((prev) => !prev); 
                          }
                          if (key === "Uploads") {
                              navigate("/uploadInfo");
                          }
                          if (key === "Public") {
                              setShowPublic(true);
                              setShowYourSpace(false);
                          }
                          if (key === "Your Space") {
                              setShowYourSpace(true);
                              setShowPublic(false);
                          }
                          if (key.startsWith('subject')) {
                              handleSubjectSearchClick(key);
                          }
                          if (key === "All") {
                              setShowAll(true);
                              setShowPDF(false);
                              setShowAudio(false);
                              setShowVideo(false);
                              setShowQna(false);
                          } else if (["PDF", "Audio", "Video", "Qna"].includes(key)) {
                              setShowAll(false);
                              if (key === "PDF") setShowPDF(!showPDF);
                              if (key === "Audio") setShowAudio(!showAudio);
                              if (key === "Video") setShowVideo(!showVideo);
                              if (key === "Qna") setShowQna(!showQna);
                          }
                      }}
                      onDoubleClick={(e) => handleSubjectClick(key)}
                  >
                      {/* Render icon if available, otherwise show label */}
                      {icon || label}
                  </button>
              ))}
          </div>


            {/* Right Scroll Button, conditionally rendered */}
            {showRightScroll && (
              <button onClick={handleScrollRight} className="scroll-button right-scroll">
                {'>'}
              </button>
            )}
          </div>

        <div className="file-list-container">

        {filteredFiles.length === 0 ? (
            // Show this message when files is empty
            <p className="no-files-message" style={{ color: 'white' }}>No file was found. Please upload your file.</p>
          ) : (
            // Show the file list when files is not empty
            <ul className="file-list">
              {filteredFiles.map((file, index) => {
                const isLongWithoutSpaces = file.name.length > 20 && !file.name.includes(' ');
                const splitFileName = (name: string): string => {
                 const midpoint = Math.floor(name.length / 2); // Find the midpoint of the name
                  return `${name.slice(0, midpoint)}\u200B${name.slice(midpoint)}`; // Insert a zero-width space to create a word break
                };

                return (
                  <li key={index} className="file-list-item">

                <span
                  className="file-thumbnail"
                  onClick={() => {
                    handleViewFile(file.url);
                    handleSelectFile(file.url);
                    setFileName(file.name);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {file.thumbnailUrl ? (
                    <img src={thumbnails[file.url]} alt={`Thumbnail for ${file.name}`} />
                  ) : file.documentType === 'Note' ? (
                    <img src={pdfFile} alt={`PDF File Placeholder for ${file.name}`} />
                  ) : file.documentType === 'Recording' ? (
                    <img src={audioFile} alt={`Audio File Placeholder for ${file.name}`} />
                  ) : file.documentType === 'YouTube Video' ? (
                    <img src={youTubeFile} alt={`YouTube File Placeholder for ${file.name}`} />
                  ) : file.documentType === 'Question' ? (
                    <img src={qnaFile} alt={`Q&A File Placeholder for ${file.name}`} />
                  ) : (
                    <p>Loading...</p>
                  )}
                </span>

                  <div className="file-actions">

                  {showPublic && (
                    <img
                        src={file.userImageUrl && file.userImageUrl !== '' ? file.userImageUrl : defaultUser}
                        alt="User Thumbnail"
                        className="file-user-image"
                        onClick={() => handlePublicProfileClick(file.userId)} // Pass the userId to the function
                      />
                    )}

                    <span className="file-name">
                      {isLongWithoutSpaces ? splitFileName(file.name) : file.name}
                    </span>

                        {/* Menu Button with Dropdown */}
                        <div className="menu-container" ref={menuRef}>
                        <button title="Tools" className="menu-button" onClick={() => toggleMenu(index)}>
                          <span className="button-dot"></span>
                          <span className="button-dot"></span>
                          <span className="button-dot"></span>
                        </button>
                          {openMenuIndex === index && (
                            <div className="menu-content">

                            {!user?.isAnonymous && showPublic &&(   
                              <button className="menu-option" onClick={() => handleCollectFile(file.refPath, file.name, file.documentType)}>
                                <FaFolderOpen size={14} style={{ marginRight: '8px' }} />
                                Collect
                              </button>
                                )}

                            {!user?.isAnonymous &&  showPublic &&(   
                                <button className="menu-option" onClick={() => handleDownloadFile(file.url)}>
                                  <FaDownload size={14} style={{ marginRight: '8px' }} />
                                  Download</button>     
                              )}   
                
                              {showYourSpace && (
                                <div>
                                  {/* Send Button */}
                                  <button
                                    title="Send"
                                    className="menu-option"
                                    onClick={() => handleShareFile(file.url, file.name)}
                                  >
                                    <FaPaperPlane size={14} style={{ marginRight: '8px' }} />
                                    Send
                                  </button>

                                  {/* Share button with icon */}
                                  <button
                                    className="menu-option"
                                    onClick={() => {
                                      handleFileShareWithPublic(file.refPath);
                                      setOpenMenuIndex(null);
                                    }}
                                  >
                                    <FaShareSquare size={14} style={{ marginRight: '8px' }} />
                                    Share
                                  </button>

                                  {/* Chat button with icon */}
                                  <button
                                    className="menu-option"
                                    onClick={() => {
                                      handleChatChange(file.refPath);
                                      setOpenMenuIndex(null);
                                    }}
                                  >
                                    <FaCommentDots size={14} style={{ marginRight: '8px' }} />
                                    Send to Chat
                                  </button>

                                  {/* Rename button with icon */}
                                  <button
                                    className="menu-option"
                                    onClick={() => {
                                      setCurrentFileRefPath(file.refPath);
                                      setNewFileName(file.name);
                                      setIsRenameModalOpen(true);
                                      setOpenMenuIndex(null);
                                    }}
                                  >
                                    <FaEdit size={14} style={{ marginRight: '8px' }} />
                                    Rename
                                  </button>

                                  {/* Delete button with icon */}
                                  <button
                                    className="menu-option"
                                    onClick={() => {
                                      handleDeleteFile(file.refPath);
                                      setOpenMenuIndex(null);
                                    }}
                                  >
                                    <FaTrashAlt size={14} style={{ marginRight: '8px' }} />
                                    Delete
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                    </div>

                    <div className="views-date">
                      <p>
                        {showPublic && `${file.viewCount} views - `}
                        {file.createdAt
                          ? formatDistanceToNow(new Date(file.createdAt), { addSuffix: true })
                          : "Unknown"}
                      </p>
                    </div>

                  </li>
                );
              })}
            </ul>
          )}
          </div>
            <PdfViewerModal
            isOpen={isPdfModalOpen}
            pdfUrl={pdfUrl}
            fileName={fileName}
            qnaStatus={qnaStatus}
            summaryStatus={summaryStatus} 
            setSummaryStatus={setSummaryStatus}                        
            onClose={() => setIsPdfModalOpen(false)} 
            onGenerateQuestions={() => handleQnaButtonClick(pdfUrl!)} 
            onGenerateSummaries={() => handleSummaryButtonClick(pdfUrl!)} 
            currentFile={currentFile}  
            setCurrentFile={setCurrentFile}               
            transcriptUrl={transcriptUrl} 
            setTranscriptUrl={setTranscriptUrl}   
            showPublic={showPublic}         
          />

        </div>
      </div>

      {shareModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content notes-share-modal">
            <h3 className="modal-title">Share Document</h3>
            <div className="modal-row notes-share-row">
              <label className="notes-share-label">To:</label>
              <input
                type="email"
                value={shareEmail}
                onChange={(e) => setShareEmail(e.target.value)}
                placeholder="Enter recipient's email"
                className="notes-share-input"
              />
            </div>
            <div className="modal-row notes-share-row">
              <label className="notes-share-label">Message:</label>
              <textarea
                value={shareMessage}
                onChange={(e) => setShareMessage(e.target.value)}
                placeholder="Enter your message"
                className="notes-share-textarea"
              />
            </div>
            <div className="modal-actions notes-share-actions">
              <button className="cancel-button" onClick={handleCancelShare}>
                Cancel
              </button>
              <button className="notes-share-send-button" onClick={handleSendShare}>
                Send
              </button>
            </div>
          </div>
        </div>
      )}

      {isRenameModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3 className="modal-title">Rename File</h3>
            <div className="input-row" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <label style={{ marginLeft: '30px', marginRight: '10px', width: '80px', color: 'white' }}>File Name:</label>
              <input 
                type="text" 
                value={newFileName}
                onChange={(e) => setNewFileName(e.target.value)} 
                placeholder="Enter new file name" 
                style={{ width: '66%', padding: '5px' }}  // Adjusted to 2/3 width
              />
            </div>
            <div className="modal-actions">
              <button className="cancel-button" onClick={() => setIsRenameModalOpen(false)}>
                Cancel
              </button>
              <button className="save-button" onClick={() => handleRenameFile(newFileName)}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {isQnaModalOpen && (
          <div className="modal-overlay">
              <div className="modal-content qwindow-modal">
                  <h3 className="modal-title">Q&A Generation</h3>
                  
                  <div className="modal-row qwindow-row">
                      <label className="qwindow-label">
                          Number of Questions:
                          <input
                              type="number"
                              value={numQuestions}
                              onChange={(e) => setNumQuestions(parseInt(e.target.value, 10))}
                              min="1"
                              max="100"
                              className="qwindow-input"
                          />
                      </label>
                  </div>
                  
                  <div className="modal-row qwindow-row">
                      <label className="qwindow-label">
                          Question Format:
                          <select
                              value={questionFormat}
                              onChange={(e) => setQuestionFormat(e.target.value)}
                              className="qwindow-select"
                          >
                              <option value="Multiple choice">Multiple choice</option>
                              <option value="Direct question">Direct question</option>
                          </select>
                      </label>
                  </div>

                  <div className="modal-row qwindow-row">
                      <label className="qwindow-label">
                          Difficulty Level:
                          <select
                              value={difficultyLevel}
                              onChange={(e) => setDifficultyLevel(e.target.value)}
                              className="qwindow-select"
                          >
                              <option value="Easy">Easy</option>
                              <option value="Medium">Medium</option>
                              <option value="Hard">Hard</option>
                          </select>
                      </label>
                  </div>

                  {showAnimationOptions && (
                    <div className="modal-row qwindow-row">
                    <label className="qwindow-label">
                        Animation:
                        <select
                        value={animationOption}
                        onChange={(e) => setAnimationOption(e.target.value)}
                        className="qwindow-select"
                        >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        </select>
                    </label>
                    </div>
                 )}

                  {showProgress && (
                      <div className="modal-row qwindow-row">
                          <textarea
                              className="qwindow-textarea"
                              value={progressText}
                              readOnly
                          />
                      </div>
                  )}

                  <div className="modal-actions qwindow-actions">
                      <button className="cancel-button qwindow-button" onClick={handleQnaModalClose}>
                          Cancel
                      </button>
                      <button className="ok-button qwindow-button" onClick={handleQuestionGeneration}>
                          OK
                      </button>
                  </div>


              </div>
          </div>
      )}


      {isSummaryModalOpen && (
          <div className="modal-overlay">
              <div className="modal-content qwindow-modal">
                  <h3 className="modal-title">Summary Generation</h3>
                  
                  
                  <div className="modal-row qwindow-row">
                      <label className="qwindow-label">
                          Summary Format:
                          <select
                              value={summaryFormat}
                              onChange={(e) => setSummaryFormat(e.target.value)}
                              className="qwindow-select"
                          >
                              <option value="Long and Detailed">Long and Detailed</option>
                              <option value="Short and Brief">Short and Brief</option>
                          </select>
                      </label>
                  </div>

                  {showAnimationOptions && (
                    <div className="modal-row qwindow-row">
                    <label className="qwindow-label">
                        Animation:
                        <select
                        value={animationOption}
                        onChange={(e) => setAnimationOption(e.target.value)}
                        className="qwindow-select"
                        >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        </select>
                    </label>
                    </div>
                 )}

                  {showProgress && (
                      <div className="modal-row qwindow-row">
                          <textarea
                              className="qwindow-textarea"
                              value={progressText}
                              readOnly
                          />
                      </div>
                  )}

                  <div className="modal-actions qwindow-actions">
                      <button className="cancel-button qwindow-button" onClick={handleSummaryModalClose}>
                          Cancel
                      </button>
                      <button className="ok-button qwindow-button" onClick={handleSummaryGeneration}>
                          OK
                      </button>
                  </div>


              </div>
          </div>
      )}

      {isChatModalOpen && selectedChatFile && (
        <div className="modal-overlay">
          <div className="modal-content qwindow-modal">
            <h3 className="modal-title">Add File to Chat Assistant</h3>

            <label className="qwindow-label" style={{ display: 'flex', alignItems: 'center' }}>
              <strong style={{ marginLeft: '5px', marginBottom: '10px'}}>
                {decodeURIComponent(selectedChatFile).replace('uploads/', '').split('_').slice(1).join('_')}
              </strong>
            </label>

          {/* Dropdown to select an assistant */}
          <label className="qwindow-label">Select an assistant to add the file</label>
          <select
            onChange={(e) => {
              const selected = assistants.find((asst) => asst.id === e.target.value);

              if (selected) {
                handleAssistantSelection(selected); // Proceed with the selected assistant
              } else {
                setAssistantFiles([]); // Clear assistant files if no assistant is selected
              }
            }}
            style={{ padding: '8px', borderRadius: '5px', width: '100%', marginBottom: '10px' }} // Styling the dropdown
          >
            <option value="">Select an assistant</option>
            {assistants.length > 0 ? (
              assistants.map((assistant) => (
                <option key={assistant.id} value={assistant.id}>
                  {assistant.name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No assistant is available
              </option>
            )}
          </select>

            {/* Show assistant files if available */}
            {assistantFiles.length > 0 && (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {assistantFiles.map((file, index) => (
                  <li key={index} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '8px 12px',
                    borderRadius: '8px',
                    backgroundColor: '#2b2f33',
                    marginBottom: '8px',
                    color: 'white'
                  }}>
                    <span>{file.name}</span>
                    <button
                      title="Remove"
                      onClick={() => handleRemoveFile(file.id)}
                      style={{
                        background: 'none',
                        border: 'none',
                        color: 'red',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <FaTrashAlt size={16} />
                    </button>
                  </li>
                ))}
              </ul>
            )}

            {/* Action buttons */}
            <div style={{ marginTop: '20px' }}>
              <button
                onClick={handleAddFile}
                style={{
                  padding: '10px 20px',
                  borderRadius: '5px',
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  marginRight: '10px'
                }}
              >
                Add File
              </button>
              <button
                onClick={closeChatModal}
                style={{
                  padding: '10px 20px',
                  borderRadius: '5px',
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

     {showShareConfirmModal && (
                <CustomConfirmModal 
                  message="Do you want to share the file with the public?"
                  onConfirm={confirmFileShareWithPublic}
                  onCancel={cancelFileShareWithPublic}
                />
      )}


      {showProfile && profileUID && (
          <div className="modal-overlay">
              <div className="modal-content qwindow-modal">
              <PublicProfile userUID={profileUID} onClose={() => setShowProfile(false)} />
              </div>
          </div>
      )}

      {/* Animation Overlay */}
      {isAnimationRunning && (
        <div className="animation-overlay">
          <img src={Logo} alt="Loading" className="animation-logo" />
        </div>
      )}
  
    </div>
  );
};

export default Home;
